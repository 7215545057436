import { MenuItem, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import SimpleCurrencyInput from 'components/inputs/currency-input/simple-currency-input';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import ValidSelectInput from 'components/inputs/select-input/valid-select-input';
import ModalTextArea from 'components/modals/modal-text-area/modal-text-area';
import { BANK_SVG_URL } from 'config/constants';
import {
    getAccountIdentifier,
    PaymentTransactionByBatchToCreateList,
} from 'entities/payment-transaction/payment-transaction-in-series-by-batch/payment-transaction-in-series-by-batch';
import { validatePaymentTransactionsAccount } from 'entities/payment-transaction/validation/payment-transactions-account-validation';
import invalidBankSvg from 'images/bank-default.svg';
import bankNotFoundSvg from 'images/bank-not-found.svg';
import { PaymentValueAccountType } from 'model/enums/payment-value-account-type';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { valuesOfObject } from 'shared/util/object-utils';

const I18N_PREFIX = 'pages.scd.payment-transaction.new-transaction';

interface DetailPaymentTransactionInSeriesByBatchProps {
    detailPaymentTransactionByBatch: PaymentTransactionByBatchToCreateList;
    listPaymentTransactionsByBatch: PaymentTransactionByBatchToCreateList[];
    setListPaymentTransactionsByBatchList: (value: PaymentTransactionByBatchToCreateList[]) => void;
    index: number;
}

const getAccountTypeByIdentifier = (accountIdentifier: string): PaymentValueAccountType | undefined => {
    const accountType = accountIdentifier.split(':')[0];
    return valuesOfObject(PaymentValueAccountType).find(it => it === accountType);
};

const getAccountIdByIdentifier = (accountIdentifier: string): number => {
    return Number(accountIdentifier.split(':')[1]);
};

const DetailPaymentTransactionInSeriesByBatch = (props: DetailPaymentTransactionInSeriesByBatchProps) => {
    const { detailPaymentTransactionByBatch, listPaymentTransactionsByBatch, index, setListPaymentTransactionsByBatchList } = props;

    const [modalDescription, setModalDescription] = useState<boolean>(false);
    const [showDeleteIcon, setShowDeleteIcon] = useState<boolean>(false);

    const { t } = useTranslation();

    const handleDelete = (paymentValueId: number) => {
        const filteredData = listPaymentTransactionsByBatch.filter(value => value.paymentValueId !== paymentValueId);
        setListPaymentTransactionsByBatchList(filteredData);
        toast.success(t(`${I18N_PREFIX}.toast.delete`), { icon: false });
    };

    const handleChange = useCallback(
        (valueField: Partial<PaymentTransactionByBatchToCreateList>) => {
            const _filteredData = listPaymentTransactionsByBatch.map(value =>
                value.paymentValueId === detailPaymentTransactionByBatch.paymentValueId ? { ...value, ...valueField } : value
            );

            setListPaymentTransactionsByBatchList(_filteredData);
        },
        [detailPaymentTransactionByBatch.paymentValueId, listPaymentTransactionsByBatch, setListPaymentTransactionsByBatchList]
    );

    return (
        <>
            <div
                className="scd-payment-transaction-in-series-by-batch__content-new-transaction"
                onMouseEnter={() => setShowDeleteIcon(true)}
                onMouseLeave={() => setShowDeleteIcon(false)}
            >
                <span style={{ width: '30px' }}>{String(index + 1)}</span>
                <div className="scd-payment-transaction-in-series-by-batch__content-new-transaction__field-value">
                    <SimpleCurrencyInput
                        label={t(`${I18N_PREFIX}.amount`)}
                        placeholder={t(`${I18N_PREFIX}.amount`)}
                        value={detailPaymentTransactionByBatch?.amount}
                        externalUpdate
                        autoFocus
                        disabled={true}
                        readOnly
                    />
                </div>
                <div className="scd-payment-transaction-in-series-by-batch__content-new-transaction__field-favored">
                    <div className="scd-payment-transaction-in-series-by-batch__content-new-transaction__field-favored-content">
                        <SimpleSearchInput
                            value={
                                detailPaymentTransactionByBatch?.favoredIdentification && detailPaymentTransactionByBatch?.favoredName
                                    ? `${detailPaymentTransactionByBatch?.favoredIdentification} ${detailPaymentTransactionByBatch?.favoredName}`
                                    : ''
                            }
                            label={t(`${I18N_PREFIX}.favored`)}
                            placeholder={t(`${I18N_PREFIX}.favored`)}
                            disabled={true}
                            readOnly={true}
                        />
                    </div>
                </div>
                <div className="scd-payment-transaction-in-series-by-batch__content-new-transaction__field-account">
                    <section>
                        <ValidSelectInput
                            label={t(`${I18N_PREFIX}.account`)}
                            mapperFromString={_value => _value ?? ''}
                            placeholder={t(`${I18N_PREFIX}.account`)}
                            value={
                                detailPaymentTransactionByBatch?.accountIdentifier ? detailPaymentTransactionByBatch?.accountIdentifier : ''
                            }
                            onChange={accountIdentifier =>
                                handleChange({
                                    accountIdentifier,
                                    accountId: getAccountIdByIdentifier(accountIdentifier),
                                    accountType: getAccountTypeByIdentifier(accountIdentifier),
                                })
                            }
                            externalUpdate
                            disabled={detailPaymentTransactionByBatch.accounts.length <= 1}
                            showValidation={true}
                            validate={() => validatePaymentTransactionsAccount(detailPaymentTransactionByBatch)}
                        >
                            {detailPaymentTransactionByBatch.accounts?.map(
                                ({
                                    accountNumber,
                                    bankIdentification,
                                    bankName,
                                    agencyNumber,
                                    id,
                                    pixKey,
                                    agencyDigit,
                                    accountDigit,
                                    accountType,
                                }) => {
                                    return (
                                        <MenuItem value={getAccountIdentifier(id, accountType)} key={getAccountIdentifier(id, accountType)}>
                                            {pixKey ? (
                                                <>
                                                    <span> {pixKey} </span>
                                                </>
                                            ) : (
                                                <>
                                                    {bankIdentification ? (
                                                        <span style={{ marginRight: '8px' }}>
                                                            <Tooltip title={bankName}>
                                                                <img
                                                                    src={`${BANK_SVG_URL}/${bankIdentification}.svg`}
                                                                    alt={bankName ?? ''}
                                                                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                                                                        ((e.target as HTMLImageElement).src = invalidBankSvg)
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        </span>
                                                    ) : (
                                                        <span style={{ marginRight: '8px' }}>
                                                            <Tooltip title={bankName ?? t('tooltips.bankInvalid')}>
                                                                <img src={bankNotFoundSvg} alt={bankName ?? ''} />
                                                            </Tooltip>
                                                        </span>
                                                    )}

                                                    <span style={{ marginRight: '6px' }}>
                                                        {agencyDigit ? `${agencyNumber}-${agencyDigit}` : agencyNumber}{' '}
                                                    </span>
                                                    <span style={{ marginRight: '6px' }}>
                                                        {accountDigit ? `${accountNumber}-${accountDigit}` : accountNumber}{' '}
                                                    </span>
                                                </>
                                            )}
                                        </MenuItem>
                                    );
                                }
                            )}
                        </ValidSelectInput>
                    </section>
                </div>

                <div className="scd-payment-transaction-in-series-by-batch__content-new-transaction__icons ">
                    <Tooltip title={String(t('tooltips.description'))}>
                        <div
                            className={clsx(
                                'scd-payment-transaction-in-series-by-batch__content-new-transaction__icons-description scd-payment-transaction-in-series-by-batch__content-new-transaction__icons-icon',
                                {
                                    active: detailPaymentTransactionByBatch.description,
                                }
                            )}
                            onClick={() => setModalDescription(true)}
                        />
                    </Tooltip>
                    {showDeleteIcon ? (
                        <div
                            className="scd-payment-transaction-in-series-by-batch__content-new-transaction__icons-delete"
                            onClick={() => handleDelete(detailPaymentTransactionByBatch?.paymentValueId)}
                        />
                    ) : (
                        <div className="scd-payment-transaction-in-series-by-batch__content-new-transaction__icons-blank" />
                    )}
                </div>
                <ModalTextArea
                    title={t(`${I18N_PREFIX}.description.title`)}
                    label={t(`${I18N_PREFIX}.description.label`)}
                    openModal={modalDescription}
                    defaultValue={detailPaymentTransactionByBatch.description ?? ''}
                    onClose={() => setModalDescription(false)}
                    onChange={description => handleChange({ description })}
                />
            </div>
        </>
    );
};

export default DetailPaymentTransactionInSeriesByBatch;
