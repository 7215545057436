import StandardButton from 'components/buttons/standard-button/standard-button';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCessionToResumeBco, useExportRequestSummaryResultsBco } from 'reducer-bco/hooks-bco';
import BalanceLoading from 'shared/external/payment-accounts-strategy/balance/components/balance-loading/balance-loading';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-matera.table.footer.components';

enum MateraIntegrationSteps {
    UNDEFINED = 'UNDEFINED',
    SUMMARY_LOADING = 'SUMMARY_LOADING',
    SEND_CESSION_OPERATION = 'SEND_CESSION_OPERATION',
    SEND_CESSION_OPERATION_PROCESSING = 'SEND_CESSION_OPERATION_PROCESSING',
    SEND_CESSION_OPERATION_FAILURE = 'SEND_CESSION_OPERATION_FAILURE',
    CESSION_SUCCESSFUL = 'CESSION_SUCCESSFUL',
}

export const useFooterComponents = () => {
    const { t } = useTranslation();

    const [isProcessModalOpen, setIsProcessModalOpen] = useState<boolean>(false);
    const [footerType, setFooterType] = useState<MateraIntegrationSteps>(MateraIntegrationSteps.UNDEFINED);

    const { cessionToResume } = useCessionToResumeBco();
    const processModalOpen = isProcessModalOpen && cessionToResume;

    const { status: exportRequestSummaryResultsStatus, exportRequestSummaryResults } = useExportRequestSummaryResultsBco();

    const allCessionOperationProcessed =
        exportRequestSummaryResults && cessionToResume?.numberOfOperations === exportRequestSummaryResults?.successes;
    const hasAnyCessionOperationFailed = exportRequestSummaryResults && exportRequestSummaryResults?.failures > 0;
    const hasAnyCessionOperationProcessing = exportRequestSummaryResults && exportRequestSummaryResults?.processing > 0;

    const determineFooterType = () => {
        if (exportRequestSummaryResultsStatus !== HttpRequestStatus.SUCCESS) return MateraIntegrationSteps.SUMMARY_LOADING;
        if (allCessionOperationProcessed) return MateraIntegrationSteps.CESSION_SUCCESSFUL;
        if (hasAnyCessionOperationFailed) return MateraIntegrationSteps.SEND_CESSION_OPERATION_FAILURE;
        if (hasAnyCessionOperationProcessing) return MateraIntegrationSteps.SEND_CESSION_OPERATION_PROCESSING;

        return MateraIntegrationSteps.SEND_CESSION_OPERATION;
    };

    useEffect(() => {
        setFooterType(determineFooterType());
    }, [allCessionOperationProcessed, hasAnyCessionOperationFailed, hasAnyCessionOperationProcessing, exportRequestSummaryResultsStatus]);

    const generalFooterContent = (children: ReactNode): ReactNode => (
        <div className="cession-integration-footer">
            <div className="cession-integration-footer__button-box">{children}</div>
        </div>
    );

    const EmptyFooter: ReactNode = <></>;

    const SummaryLoadingFooter: ReactNode = generalFooterContent(
        <div className="cession-integration-footer__message-box">
            <BalanceLoading />
        </div>
    );

    const SendCessionOperationFailureContent: ReactNode = generalFooterContent(
        <div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.error-message`)}</div>
    );

    const CessionSuccessfulContent: ReactNode = generalFooterContent(
        <div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.success-message`)}</div>
    );

    const SendCessionOperationProcessingContent: ReactNode = generalFooterContent(
        <div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.loading`)}</div>
    );

    const SendCessionOperationContent: ReactNode = generalFooterContent(
        <StandardButton
            label={t(`${I18N_PREFIX}.send`)}
            onClick={() => setIsProcessModalOpen(true)}
            disabled={hasAnyCessionOperationProcessing || allCessionOperationProcessed}
        />
    );

    const renderFooterContent = {
        UNDEFINED: EmptyFooter,
        SUMMARY_LOADING: SummaryLoadingFooter,
        SEND_CESSION_OPERATION: SendCessionOperationContent,
        SEND_CESSION_OPERATION_PROCESSING: SendCessionOperationProcessingContent,
        SEND_CESSION_OPERATION_FAILURE: SendCessionOperationFailureContent,
        CESSION_SUCCESSFUL: CessionSuccessfulContent,
    } as Record<MateraIntegrationSteps, ReactNode>;

    const currentFooter = renderFooterContent[footerType];

    return { currentFooter, processModalOpen, setIsProcessModalOpen };
};
