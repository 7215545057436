import clsx from 'clsx';
import FormatterUtils from 'components/formatter/formatter-utils';
import Loading from 'components/loading/loading';
import BaseBatchCard from 'entities/batch/batch-closing/components/cards/base-batch-card/base-batch-card';
import BatchClosingError from 'entities/batch/batch-closing/components/retries/batch-closing-error/batch-closing-error';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { batchClosingResumeOperationsRequest, batchClosingResumeOperationsResetState } from 'reducer/batch-closing/batch-operations-resume/actions';
import { useBatchClosingResumeOperationsState, useBatchDetailWithProgramState } from 'reducer/hooks';
import {isDueDateExpired, isDueDateToday} from 'shared/util/date-utils';
import './batch-operations-card.scss';
import BatchOperationsCardList from 'entities/batch/batch-closing/components/cards/batch-operations-card/batch-operations-card-list/batch-operations-card-list';
import { batchClosingOperationsListResetState } from 'reducer/batch-closing/operations-list/actions';

const I18N_PREFIX = 'pages.scd.batch.close-batch';

interface BatchOperationsCardProps {
    batchId: number;
}

const BatchOperationsCard: FC<BatchOperationsCardProps> = ({ batchId }) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { resumeOperations, status: resumeOperationsStatus } = useBatchClosingResumeOperationsState();
    const { batchWithProgram } = useBatchDetailWithProgramState();
    const [operationListCurrentPage, setOperationListCurrentPage] = useState<number>(0);

    const operationsCount = resumeOperations?.numberOfOperations;
    const firstOperationDate = FormatterUtils.formatDateTimeCompleteUTC(resumeOperations?.firstDueDate).slice(0, 11);

    const titlePrefix = t(`${I18N_PREFIX}.card.operations.title`);
    const earliestDueDate = t(`${I18N_PREFIX}.earliest-due-date`);

    const batchStatus = batchWithProgram?.status;
    const isOpenBatch = batchStatus === 'OPEN';
    const isErrorBatch = batchStatus === 'ERROR';
    const isOpenOrErrorBatch = isOpenBatch || isErrorBatch;

    const currentDate = resumeOperations?.currentDate;
    const firstDueDate = resumeOperations?.firstDueDate;
    const hasDateExpired = isDueDateExpired(currentDate, firstDueDate);
    const hasDateInconsistency = isOpenOrErrorBatch && hasDateExpired;

    const isDateDueToday = isDueDateToday(currentDate, firstDueDate);
    const isDueToday = isOpenOrErrorBatch && isDateDueToday;

    const title = `${titlePrefix}: ${operationsCount} `;
    const subTitle = `${earliestDueDate}: ${firstOperationDate}`;

    const isLoadingResume = resumeOperationsStatus !== HttpRequestStatus.SUCCESS;

    const handleRetry = useCallback(() => {
        dispatch(batchClosingResumeOperationsResetState());
        dispatch(batchClosingResumeOperationsRequest(batchId));
    }, [dispatch, batchId]);

    useEffect(() => {
        dispatch(batchClosingResumeOperationsRequest(batchId));
        return () => {
            dispatch(batchClosingResumeOperationsResetState());
            dispatch(batchClosingOperationsListResetState());
        };
    }, [dispatch, batchId]);

    if (resumeOperationsStatus === HttpRequestStatus.ERROR) {
        return <BatchClosingError handleRetry={handleRetry} />;
    }
    return (
        <div className="batch-operations-card">
            {isLoadingResume ? (
                <Loading />
            ) : (
                <BaseBatchCard
                    title={
                        <div>
                            <div className="batch-operations-card--card-title">{title}</div>
                            <div
                                className={clsx('batch-operations-card--card-subtitle', {
                                    'batch-operations-card--card-subtitle-expired': hasDateInconsistency,
                                    'batch-operations-card--card-subtitle-today': isDueToday,
                                })}
                            >
                                {subTitle}
                            </div>
                        </div>
                    }
                    isOpenable
                >
                    <BatchOperationsCardList operationListCurrentPage={operationListCurrentPage} setOperationListCurrentPage={setOperationListCurrentPage} />
                </BaseBatchCard>
            )}
        </div>
    );
};
export default BatchOperationsCard;
