import { PaymentInstitutionAccountType } from 'model/enums/payment-institution-account-type';
import { PaymentInstitutionAccountToCreateUpdate } from 'model/payment-institution-account';
import { Validations, ValidationUtils, ValidResult } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.payment-institutions-accounts.validate';

const PAYMENT_INSTITUTION_ACCOUNT_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.name.required`
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.name.required`
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.name.maxLength`
    }
}

export const validatePaymentInstitutionAccountName = (value: string | undefined | null) => ValidationUtils.validate(value, PAYMENT_INSTITUTION_ACCOUNT_NAME_VALIDATION)

const GENERAL_PAYMENT_INSTITUTION_ACCOUNT_EXTERNAL_REFERENCE_VALIDATION: Validations<string | undefined | null> = {
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.externalReference.maxLength`
    }
}

const DIGITAL_PAYMENT_INSTITUTION_ACCOUNT_EXTERNAL_REFERENCE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.externalReference.required`
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.externalReference.required`
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.externalReference.maxLength`
    }
}

export const validatePaymentInstitutionAccountExternalReference = (value: string | undefined | null, type: PaymentInstitutionAccountType | undefined) => {
    if ((type === PaymentInstitutionAccountType.GENERAL || type === PaymentInstitutionAccountType.EXTERNAL) || !type) {
        return ValidationUtils.validate(value, GENERAL_PAYMENT_INSTITUTION_ACCOUNT_EXTERNAL_REFERENCE_VALIDATION)
    }

    return ValidationUtils.validate(value, DIGITAL_PAYMENT_INSTITUTION_ACCOUNT_EXTERNAL_REFERENCE_VALIDATION)
}

const PAYMENT_INSTITUTION_ACCOUNT_TYPE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.type.required`
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.type.required`
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.type.maxLength`
    }
}

export const validatePaymentInstitutionAccountType = (value: string | undefined | null) => ValidationUtils.validate(value, PAYMENT_INSTITUTION_ACCOUNT_TYPE_VALIDATION)

const PAYMENT_INSTITUTION_ACCOUNT_STATUS_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.status.required`
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.status.required`
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.status.maxLength`
    }
}

export const validatePaymentInstitutionAccountStatus = (value: string | undefined | null) => ValidationUtils.validate(value, PAYMENT_INSTITUTION_ACCOUNT_STATUS_VALIDATION)

const PAYMENT_INSTITUTION_ACCOUNT_TECHNICAL_SUPPLIER_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.technicalSuplier.required`
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.technicalSuplier.required`
    }
}

export const validatePaymentInstitutionAccountTechnicalSupplier = (value: string | undefined | null) => ValidationUtils.validate(value, PAYMENT_INSTITUTION_ACCOUNT_TECHNICAL_SUPPLIER_VALIDATION)

const PAYMENT_INSTITUTION_ACCOUNT_ORIGINATOR_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty,
        errorMessage: `${I18N_PREFIX}.issuer.required`
    }
}

export const validatePaymentInstitutionAccountOriginator = (value: number | undefined | null, accountType: PaymentInstitutionAccountType | undefined) => {
    if (accountType === PaymentInstitutionAccountType.EXCLUSIVE) {
        return ValidationUtils.validate(value, PAYMENT_INSTITUTION_ACCOUNT_ORIGINATOR_VALIDATION);
    }

    const validResult: ValidResult = {
        isValid: true
    }

    return validResult;
}

const PAYMENT_INSTITUTION_ACCOUNT_ISSUER_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty,
        errorMessage: `${I18N_PREFIX}.issuer.required`
    }
}


export const validatePaymentInstitutionAccountIssuer = (value: number | undefined | null, accountType: PaymentInstitutionAccountType | undefined) => {
    if (accountType === PaymentInstitutionAccountType.EXCLUSIVE) {
        return ValidationUtils.validate(value, PAYMENT_INSTITUTION_ACCOUNT_ISSUER_VALIDATION);
    }

    const validResult: ValidResult = {
        isValid: true
    }

    return validResult;
}

const PAYMENT_INSTITUTION_ACCOUNT_FINANCIAL_INSTITUTION_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.financialInstitution.required`,
    },
};
export const validateTechnicalSupplierFinancialInstitution = (value: string | undefined | null) =>
    ValidationUtils.validate(value, PAYMENT_INSTITUTION_ACCOUNT_FINANCIAL_INSTITUTION_VALIDATION);

export const validatePaymentInstitutionAccount = (value: PaymentInstitutionAccountToCreateUpdate) => {
    if (!value) {
        return false
    }

    return (
        validatePaymentInstitutionAccountName(value.name).isValid &&
        validatePaymentInstitutionAccountExternalReference(value.externalReference, value.type).isValid &&
        validatePaymentInstitutionAccountType(value.type).isValid &&
        validatePaymentInstitutionAccountStatus(value.status).isValid &&
        validatePaymentInstitutionAccountTechnicalSupplier(value.technicalSupplierName).isValid &&
        validatePaymentInstitutionAccountOriginator(value.issuerId, value.type).isValid &&
        validatePaymentInstitutionAccountIssuer(value.issuerId, value.type).isValid &&
        validateTechnicalSupplierFinancialInstitution(value.financialInstitutionName).isValid
    )
}