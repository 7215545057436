import { ScdOriginator } from 'model/originator';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.originator.validate';

const ORIGINATOR_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.name.maxLength`,
    },
};
export const validateOriginatorName = (value: string | undefined | null) => ValidationUtils.validate(value, ORIGINATOR_NAME_VALIDATION);

const ORIGINATOR_IDENTIFICATION_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.identification.required`,
    },
    format: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.identification.required`,
    },
};
export const validateOriginatorIdentification = (value: string | undefined | null) =>
    ValidationUtils.validate(value, ORIGINATOR_IDENTIFICATION_VALIDATION);

const ORIGINATOR_COMPANY_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.company.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.company.required`,
    },
};
export const validateOriginatorCompany = (value: string | undefined | null) =>
    ValidationUtils.validate(value, ORIGINATOR_COMPANY_VALIDATION);

const ORIGINATOR_ORGANIZATION_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.organization.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.organization.required`,
    },
};
export const validateOriginatorOrganization = (value: string | undefined | null) =>
    ValidationUtils.validate(value, ORIGINATOR_ORGANIZATION_VALIDATION);

const FINANCIAL_INSTITUTION_NAME_VALIDATION: Validations<ScdOriginator | undefined | null> = {
    required: {
        predicate: originator => originator?.id !== undefined || ValidationUtils.STRING.isNotBlank(originator?.financialInstitutionName),
        errorMessage: `${I18N_PREFIX}.financialInstitution.required`,
    }
};
export const validateOriginatorFinancialInstitution = (value: ScdOriginator | undefined | null) => ValidationUtils.validate(value, FINANCIAL_INSTITUTION_NAME_VALIDATION);

export const validateScdOriginator = (value: ScdOriginator | undefined): boolean => {
    if (!value) return true;
    return (
        validateOriginatorName(value.name).isValid &&
        validateOriginatorIdentification(value.identification).isValid &&
        validateOriginatorOrganization(value.organization?.name).isValid &&
        validateOriginatorCompany(value.company?.name).isValid &&
        validateOriginatorFinancialInstitution(value).isValid
    );
};
