import { PaymentInstitutionAccountStatus } from 'model/enums/payment-institution-account-status';
import { PaymentInstitutionAccountType } from 'model/enums/payment-institution-account-type';
import { PaymentInstitutionReference } from 'model/enums/payment-institution-reference';
import { TechnicalSupplierStatus } from 'model/enums/technical-supplier-status';


export interface PaymentInstitutionAccountFilterRequest {
    name?: string;
    status?: PaymentInstitutionAccountStatus;
    type?: PaymentInstitutionAccountType;
    technicalSupplierId?: number;
    technicalSupplierName?: string;
}

export interface PaymentInstitutionAccountToGetAll {
    id: number;
    name: string;
    status: PaymentInstitutionAccountStatus;
    type: PaymentInstitutionAccountType;
    technicalSupplierId: number;
    technicalSupplierName: string
    technicalSupplierReference: string
}

export const defaultPaymentInstitutionsAccountsFilterRequest: PaymentInstitutionAccountFilterRequest = {
    name: undefined,
    status: PaymentInstitutionAccountStatus.ACTIVE,
    technicalSupplierId: undefined,
    type: undefined
}

export interface PaymentInstitutionAccountToCreateUpdate {
    id?: number;
    name?: string
    externalReference?: string
    status?: PaymentInstitutionAccountStatus;
    type?: PaymentInstitutionAccountType;
    technicalSupplierId?: number;
    technicalSupplierName?: string;
    originatorId?: number;
    originatorName?: string;
    issuerId?: number;
    issuerName?: string;
    financialInstitutionId?: number;
    financialInstitutionName?: string;
}

export const defaultPaymentInstitutionAccountToCreateUpdate: PaymentInstitutionAccountToCreateUpdate = {
    name: undefined,
    externalReference: undefined,
    status: undefined,
    type: undefined,
    technicalSupplierId: undefined,
    technicalSupplierName: undefined,
    originatorId: undefined,
    originatorName: undefined,
    issuerId: undefined,
    issuerName: undefined
}

export interface PaymentInstitutionAccountSimple {
    name?: string;
    integrationReference?: string;
    technicalSupplierId?: number;
    technicalSupplierReference?: string;
}

export interface PaymentInstitutionAccountSimpleToUpdate {
    id: number
}

export interface PaymentInstitutionAccountDetail {
    id?: number;
    name?: string;
    externalReference?: string;
    status?: PaymentInstitutionAccountStatus;
    type?: PaymentInstitutionAccountType;
    technicalSupplierId?: number;
    technicalSupplierName?: string;
    technicalSupplierStatus?: TechnicalSupplierStatus;
    issuerId?: number;
    issuerName?: string;
    originatorId?: number;
    originatorName?: string;
    financialInstitutionId?: number;
    financialInstitutionName?: string;
}
export interface PaymentInstitutionToSearch {
    id?: number;
    name?: string;
    status?: PaymentInstitutionAccountStatus;
    technicalSupplierId?: number;
    technicalSupplierName?: string;
}

export interface PaymentInstitutionAccountToResume {
    id: number;
    name: string;
    status: PaymentInstitutionAccountStatus;
    type: PaymentInstitutionAccountType;
    technicalSupplierName: string;
    technicalSupplierReference: string | PaymentInstitutionReference
}

export interface PaymentInstitutionAccountToWithdraw {
    accountId: number;
    requestBody: {
        value: number;
    };
}

export interface SimplePaymentInstitutionAccountToWithdraw {
    id: number
}

export interface PaymentInstitutionToGetTransaction {
    id: number;
    name: string;
    status: TechnicalSupplierStatus;
}

export interface PaymentInstitutionAccountToGetTransaction {
    id: number;
    name: string;
    externalReference: string;
    status: PaymentInstitutionAccountStatus;
    type: PaymentInstitutionAccountType;
}

export interface OriginatorsByPaymentInstitutionToSearch {
    id: number;
    name: string;
    identification: PaymentInstitutionAccountStatus;
}

export interface PaymentInstitutionAccountConvertExclusive {
    id?: number;
    issuerId?: number;
    issuerName?: string;
    originatorId?: number;
    originatorName?: string;
    name?: string;
    technicalSupplierName?: string;
    technicalSupplierId?: number;
    type?: PaymentInstitutionAccountType
}

export const defaultPaymentInstitutionAccountConvert: PaymentInstitutionAccountConvertExclusive = {
    id: undefined,
    originatorId: undefined,
    originatorName: undefined,
    issuerId: undefined,
    issuerName: undefined,
    name: undefined,
    technicalSupplierName: undefined,
    technicalSupplierId: undefined,
    type: undefined
}

export interface PaymentInstitutionAccountConvertExclusiveRequest {
    id?: number;
    issuerId?: number;
    originatorId?: number;
}