import Loading from 'components/loading/loading';
import BaseBatchCard from 'entities/batch/batch-closing/components/cards/base-batch-card/base-batch-card';
import BatchPaymentsError from 'entities/batch/batch-closing/components/retries/batch-payments-error/batch-payments-error';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    batchClosingResumeOperationsRequest,
    batchClosingResumeOperationsResetState,
} from 'reducer/batch-closing/batch-operations-resume/actions';
import { useBatchClosingResumePaymentsState } from 'reducer/hooks';
import './batch-payments-card.scss';

const I18N_PREFIX = 'pages.scd.batch.close-batch';

interface BatchPaymentsCardProps {
    batchId: number;
}

const BatchPaymentsCard: React.FC<BatchPaymentsCardProps> = ({ batchId }) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { resumePayments, status: resumePaymentsStatus } = useBatchClosingResumePaymentsState();

    const handleRetry = useCallback(() => {
        dispatch(batchClosingResumeOperationsResetState());
        dispatch(batchClosingResumeOperationsRequest(batchId));
    }, [dispatch, batchId]);

    const numberOfOperationsWithPaymentError = resumePayments?.numberOfOperationsWithPaymentError ?? 0;

    const titlePrefix = t(`${I18N_PREFIX}.card.payments.title`);

    const title = `${titlePrefix}: ${numberOfOperationsWithPaymentError}`;

    const isLoadingResume = resumePaymentsStatus !== HttpRequestStatus.SUCCESS;

    if (resumePaymentsStatus === HttpRequestStatus.ERROR) {
        return <BatchPaymentsError handleRetry={handleRetry} />;
    }

    return (
        <div className="batch-payments-card">
            {isLoadingResume ? (
                <Loading />
            ) : (
                <BaseBatchCard
                    title={numberOfOperationsWithPaymentError > 0 ? <span className="contains-payments-text">{title}</span> : title}
                    isOpenable
                ></BaseBatchCard>
            )}
        </div>
    );
};
export default BatchPaymentsCard;
