import { BaseStoreState } from 'reducer/base-store-state';
import { CessionOutputResumeBco } from 'model/bco/batch-output';

export enum CessionOutputResumeBcoTypes {
    CESSION_OUTPUT_RESUME_BCO_REQUEST = '@@cession-output-resume-bco/CESSION_OUTPUT_RESUME_BCO_REQUEST',
    CESSION_OUTPUT_RESUME_BCO_SUCCESS = '@@cession-output-resume-bco/CESSION_OUTPUT_RESUME_BCO_SUCCESS',
    CESSION_OUTPUT_RESUME_BCO_ERROR = '@@cession-output-resume-bco/CESSION_OUTPUT_RESUME_BCO_ERROR',
    CESSION_OUTPUT_RESUME_BCO_RESET = '@@cession-output-resume-bco/CESSION_OUTPUT_RESUME_BCO_RESET',
}

export interface CessionOutputResumeBcoState extends BaseStoreState {
    readonly cessionOutputResume?: CessionOutputResumeBco;
}
