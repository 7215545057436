import { BankingCorrespondent } from 'model/banking-correspondent';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum SearchBankingCorrespondentTypes {
    SEARCH_BANKING_CORRESPONDENTS_REQUEST = '@@banking-correspondents-search/SEARCH_BANKING_CORRESPONDENTS_REQUEST',
    SEARCH_BANKING_CORRESPONDENTS_SUCCESS = '@@banking-correspondents-search/SEARCH_BANKING_CORRESPONDENTS_SUCCESS',
    SEARCH_BANKING_CORRESPONDENTS_ERROR = '@@banking-correspondents-search/SEARCH_BANKING_CORRESPONDENTS_ERROR',
    SEARCH_BANKING_CORRESPONDENTS_RESET = '@@banking-correspondents-search/SEARCH_BANKING_CORRESPONDENTS_RESET',
}

export interface SearchBankingCorrespondentsState extends BaseStoreState {
    readonly bankingCorrespondents?: Page<BankingCorrespondent>;
}
