import { AxiosResponse } from 'axios';
import { DisbursementConfirmationSimpleBco } from 'model/bco/batch-output-disbursement-confirmation';
import HttpStatus from 'model/enums/http-status';
import { disbursementConfirmationToProcessBcoError, disbursementConfirmationToProcessBcoRequest, disbursementConfirmationToProcessBcoSuccess } from 'reducer-bco/batch-output/disbursement-confirmation-to-process/action';
import { DisbursementConfirmationToProcessBcoTypes } from 'reducer-bco/batch-output/disbursement-confirmation-to-process/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchOutputBcoApi from 'services/bco/batch-outputs-bco';
import ErrorUtils from 'shared/error/error-utils';

function* handleDisbursementConfirmationToProcessBco(action: ReturnType<typeof disbursementConfirmationToProcessBcoRequest>) {
    try {
        const result: AxiosResponse<DisbursementConfirmationSimpleBco> = yield call(batchOutputBcoApi.disbursementConfirmationToProcess, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(disbursementConfirmationToProcessBcoError(mapped));
            return;
        }
        yield put(disbursementConfirmationToProcessBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(disbursementConfirmationToProcessBcoError(mapped));
    }
}

function* watchDisbursementConfirmationToProcessBcoSaga() {
    yield takeEvery(DisbursementConfirmationToProcessBcoTypes.DISBURSEMENT_CONFIRMATION_TO_PROCESS_BCO_REQUEST, handleDisbursementConfirmationToProcessBco);
}

function* disbursementConfirmationToProcessBcoSaga() {
    yield all([fork(watchDisbursementConfirmationToProcessBcoSaga)]);
}

export default disbursementConfirmationToProcessBcoSaga;
