import { AxiosResponse } from 'axios';
import {
    PaymentTransactionsByBatchErrorsList,
    PaymentTransactionsCreateByBatch,
    PaymentTransactionsImportByBatch
} from 'model/payment-transaction-by-batch';
import { api } from 'services/api/api';
import { BASE_URL_ADMIN } from 'services/page';

const paymentTransactionByBatch = () => {

    const listPaymentTransactionsByBatch = (batchId: string): Promise<AxiosResponse<PaymentTransactionsImportByBatch>> => {
        return api.get<PaymentTransactionsImportByBatch>(`${BASE_URL_ADMIN}/payment-transactions/import/disbursements/batches/${batchId}`);
    };

    const createPaymentTransactionsByBatch = (
        paymentTransactions: PaymentTransactionsCreateByBatch
    ): Promise<AxiosResponse<PaymentTransactionsByBatchErrorsList>> => {
        return api.post(`${BASE_URL_ADMIN}/payment-transactions/by-batch`, paymentTransactions);
    };

    return {
        listPaymentTransactionsByBatch,
        createPaymentTransactionsByBatch
    };
};

export default paymentTransactionByBatch();
