import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import './disbursement-confirmation-header.scss';

interface HeaderList {
    title: string;
}

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.disbursement-confirmation.list.table.header';

const HEADER_LIST: HeaderList[] = [{ title: `${I18N_PREFIX}.date` }, { title: `${I18N_PREFIX}.status` }];
const EMPTY_HEADER_FOR_ICON: ReactNode = <th className="disbursement-confirmation-list-header__table-header" />;

interface DisbursementConfirmationListHeaderProps {}

const DisbursementConfirmationListHeader: React.FC<DisbursementConfirmationListHeaderProps> = () => {
    const { t } = useTranslation();

    return (
        <thead className="disbursement-confirmation-list-header">
            <tr>
                {EMPTY_HEADER_FOR_ICON}
                {HEADER_LIST.map((header, index) => (
                    <th className="disbursement-confirmation-list-header__table-header" key={`${header.title}-${index}`}>
                        {t(header.title)}
                    </th>
                ))}
                {EMPTY_HEADER_FOR_ICON}
            </tr>
        </thead>
    );
};

export default DisbursementConfirmationListHeader;
