import { AxiosResponse } from 'axios';
import { ScdOriginator, ScdOriginatorList, ScdOriginatorSearch, ScdOriginatorSimple } from 'model/originator';

import { OriginatorFilterPageable, SearchRequest } from 'model/reducers';
import { api } from 'services/api/api';
import { createQueryParam } from 'services/api/api-utils';
import { BASE_URL_ADMIN, Page } from 'services/page';

const originators = () => {
    const createOriginator = (originator: ScdOriginator): Promise<AxiosResponse<ScdOriginatorSimple>> => {
        return api.post<ScdOriginatorSimple>(`${BASE_URL_ADMIN}/originators`, originator);
    };

    const updateOriginator = (originator: ScdOriginator): Promise<AxiosResponse<ScdOriginatorSimple>> => {
        return api.put<ScdOriginatorSimple>(`${BASE_URL_ADMIN}/originators`, originator);
    };

    const detailOriginator = (originatorId: string): Promise<AxiosResponse<ScdOriginator>> => {
        return api.get<ScdOriginator>(`${BASE_URL_ADMIN}/originators/${originatorId}`);
    };

    const listOriginators = (request: OriginatorFilterPageable): Promise<AxiosResponse<Page<ScdOriginatorList>>> => {
        const { filter, pageable } = request

        const _requestFilters = [
            filter?.name && `name=${filter.name}`,
            filter?.identification && `identification=${filter.identification}`,
            filter?.companyId && `companyId=${filter.companyId}`,
            filter?.paymentInstitutionId && `paymentInstitutionId=${filter.paymentInstitutionId}`
        ]
            .filter(it => !!it)
            .join('&')

        return api.get<Page<ScdOriginatorList>>(`${BASE_URL_ADMIN}/originators?${_requestFilters}`, { params: { ...pageable } });
    };

    const searchOriginators = (request: SearchRequest): Promise<AxiosResponse<Page<ScdOriginatorSearch>>> => {
        const { organizationId, financialInstitutionId, bankingCorrespondentId, pageable, search, bankingCorrespondentIsNullFilter } = request;

        const requestFilters = [
            createQueryParam('search', search),
            createQueryParam('organizationId', organizationId),
            createQueryParam('financialInstitutionId', financialInstitutionId),
            createQueryParam('bankingCorrespondentId', bankingCorrespondentId),
            createQueryParam('bankingCorrespondentIsNullFilter', bankingCorrespondentIsNullFilter)
        ].filter(Boolean).join('&');


        return api.get<Page<ScdOriginatorSearch>>(`${BASE_URL_ADMIN}/originators/search?${requestFilters}`, {
            params: { ...pageable },
        });
    };

    return {
        createOriginator,
        updateOriginator,
        detailOriginator,
        listOriginators,
        searchOriginators,
    };
};

export default originators();
