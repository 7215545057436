import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import Loading from 'components/loading/loading';
import { SEARCH_ITEM_SIZE, SEARCH_ITEM_SIZE_LARGE } from 'components/modals/constants';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ModalSearch from 'components/modals/modal-search/modal-search';
import ValidOriginatorIdentificationInput from 'entities/originator/components/inputs/originator-identification-input/valid-originator-identification-input';
import OriginatorPaymentInstitutionManager from 'entities/originator/components/originator-payment-institution-card/originator-payment-institution-manager';
import {
    validateOriginatorCompany,
    validateOriginatorFinancialInstitution,
    validateOriginatorIdentification,
    validateOriginatorName,
    validateOriginatorOrganization,
    validateScdOriginator,
} from 'entities/originator/validation/originator-validation';
import { BankingCorrespondentSearch } from 'model/banking-correspondent';
import { ScdCompany } from 'model/company';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { FinancialInstitutionSearch } from 'model/financial-institution';
import { ScdOrganization } from 'model/organization';
import { ScdOriginator, defaultScdOriginator } from 'model/originator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import {
    searchBankingCorrespondentsRequest,
    searchBankingCorrespondentsResetState,
} from 'reducer/banking-correspondent/search-banking-correspondents/actions';
import { searchCompaniesRequest, searchCompaniesResetState } from 'reducer/company/search-companies/actions';
import {
    searchFinancialInstituionsRequest,
    searchFinancialInstituionsResetState,
} from 'reducer/financial-institution/search-financial-institutions/actions';
import { useCreateOriginatorState, useDetailOriginatorState, useRootDispatch, useUpdateOriginatorState } from 'reducer/hooks';
import { searchOrganizationsRequest, searchOrganizationsResetState } from 'reducer/organization/search-organizations/actions';
import { createOriginatorRequest, createOriginatorResetState } from 'reducer/originator/create-originator/actions';
import { detailOriginatorRequest, detailOriginatorResetState } from 'reducer/originator/detail-originator/actions';
import { updateOriginatorRequest, updateOriginatorResetState } from 'reducer/originator/update-originator/actions';
import { useToast } from 'shared/hooks/use-toast';
import './originator-create-update.scss';

export interface ScdOriginatorUpdateProps extends RouteComponentProps<{ originatorId: string }> {}

const I18N_PREFIX = 'pages.scd.originator';

const ScdOriginatorCreateUpdatePage = (props: ScdOriginatorUpdateProps) => {
    const { t } = useTranslation();
    const [originatorId] = useState<string | undefined>(props.match?.params?.originatorId ?? undefined);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);

    const { status: createStatus } = useCreateOriginatorState();
    const { status: updateStatus } = useUpdateOriginatorState();
    const { status: detailStatus, originator: detailOriginator } = useDetailOriginatorState();

    const [scdOriginator, setScdOriginator] = useState<ScdOriginator>(defaultScdOriginator);

    const dispatch = useRootDispatch();
    const history = useHistory();
    const { toastSuccess } = useToast();

    const handleSave = () => {
        setShowValidation(true);

        const _scdOriginator: ScdOriginator = {
            ...scdOriginator,
        };

        if (!validateScdOriginator(_scdOriginator)) return;

        if (originatorId) {
            dispatch(updateOriginatorRequest(_scdOriginator));
        } else {
            dispatch(createOriginatorRequest(_scdOriginator));
        }
    };

    const handleChange = (value: Partial<ScdOriginator>) => {
        setScdOriginator(ps => ({ ...ps, ...value }));
    };

    useEffect(() => {
        if (originatorId) {
            dispatch(detailOriginatorRequest(originatorId));
        }
    }, [originatorId, dispatch]);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && detailOriginator) {
            setScdOriginator({
                ...detailOriginator,
                financialInstitutionName: detailOriginator.financialInstitution?.name,
                financialInstitutionId: detailOriginator.financialInstitution?.id
                    ? Number(detailOriginator.financialInstitution?.id)
                    : undefined,
                bankingCorrespondentId: detailOriginator.bankingCorrespondent?.id
                    ? Number(detailOriginator.bankingCorrespondent?.id)
                    : undefined,
                bankingCorrespondentName: detailOriginator.bankingCorrespondent?.name,
            });
        }
    }, [detailStatus, detailOriginator]);

    useEffect(() => {
        if (updateStatus === HttpRequestStatus.SUCCESS) {
            toastSuccess(t(`${I18N_PREFIX}.toast-success.edit`));
            history.push('/originators');
        }
    }, [updateStatus, history, t, toastSuccess]);

    useEffect(() => {
        if (createStatus === HttpRequestStatus.SUCCESS) {
            toastSuccess(t(`${I18N_PREFIX}.toast-success.create`));
            history.push('/originators');
        }
    }, [createStatus, history, t, toastSuccess]);

    useEffect(() => {
        return () => {
            dispatch(createOriginatorResetState());
            dispatch(updateOriginatorResetState());
            dispatch(detailOriginatorResetState());
        };
    }, [dispatch]);
    const loading = detailStatus !== HttpRequestStatus.SUCCESS;

    return (
        <main className="scd-originator-create-update__create">
            <ContextRibbon />
            <div className="scd-originator-create-update--container">
                <header className="scd-originator-create-update--header">
                    <h2 className="scd-originator-create-update--header-title">
                        {!originatorId ? t(`${I18N_PREFIX}.create.title`) : t(`${I18N_PREFIX}.edit.title`)}
                    </h2>
                </header>
                {loading && originatorId ? (
                    <Loading />
                ) : (
                    <form className="scd-originator-create-update--form">
                        <div className="scd-originator-create-update--form-field">
                            <ValidTextInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.name.label`)}
                                value={scdOriginator?.name ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.name.placeholder`)}
                                onChange={name => handleChange({ name })}
                                validate={validateOriginatorName}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-originator-create-update--form-field">
                            <ValidOriginatorIdentificationInput
                                label={t(`${I18N_PREFIX}.input.identification.label`)}
                                value={scdOriginator?.identification ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.identification.placeholder`)}
                                onChange={identification => handleChange({ identification })}
                                validate={validateOriginatorIdentification}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-originator-create-update--form-field">
                            <ModalSearch<ScdCompany>
                                action={searchCompaniesRequest}
                                itemSize={SEARCH_ITEM_SIZE_LARGE}
                                modalTitle={t(`${I18N_PREFIX}.input.company.modal.title`)}
                                modalLabel={t(`${I18N_PREFIX}.input.company.modal.label`)}
                                modalPlaceholder={t(`${I18N_PREFIX}.input.company.modal.placeholder`)}
                                onSelect={company => handleChange({ company })}
                                renderItem={company => `${company?.identification} - ${company?.name}`}
                                statusSelector={(state: IRootState) => state.searchCompanies.status}
                                dataSelector={(state: IRootState) => state.searchCompanies.companies}
                                resetState={searchCompaniesResetState}
                            >
                                {handleOpen => (
                                    <ValidSearchInput
                                        label={`${I18N_PREFIX}.input.company.label`}
                                        showValidation={showValidation}
                                        placeholder={`${I18N_PREFIX}.input.company.placeholder`}
                                        validate={validateOriginatorCompany}
                                        value={
                                            scdOriginator?.company?.name || scdOriginator?.company?.identification !== undefined
                                                ? `${scdOriginator?.company?.name ?? ''} - ${scdOriginator?.company?.identification ?? ''}`
                                                : ''
                                        }
                                        onClick={handleOpen}
                                        onFocus={handleOpen}
                                        readOnly
                                        externalUpdate
                                    />
                                )}
                            </ModalSearch>
                        </div>
                        <div className="scd-originator-create-update--form-field">
                            <ModalSearch<ScdOrganization>
                                action={searchOrganizationsRequest}
                                itemSize={SEARCH_ITEM_SIZE}
                                modalTitle={t(`${I18N_PREFIX}.input.organization.modal.title`)}
                                modalLabel={t(`${I18N_PREFIX}.input.organization.modal.label`)}
                                modalPlaceholder={t(`${I18N_PREFIX}.input.organization.modal.placeholder`)}
                                onSelect={organization => handleChange({ organization })}
                                renderItem={organization => organization.name}
                                statusSelector={(state: IRootState) => state.searchOrganizations.status}
                                dataSelector={(state: IRootState) => state.searchOrganizations.organizations}
                                resetState={searchOrganizationsResetState}
                            >
                                {handleOpen => (
                                    <ValidSearchInput
                                        label={`${I18N_PREFIX}.input.organization.label`}
                                        showValidation={showValidation}
                                        placeholder={`${I18N_PREFIX}.input.organization.placeholder`}
                                        validate={validateOriginatorOrganization}
                                        value={scdOriginator.organization?.name ?? ''}
                                        onClick={handleOpen}
                                        onFocus={handleOpen}
                                        readOnly
                                        externalUpdate
                                    />
                                )}
                            </ModalSearch>
                        </div>
                        <div className="scd-originator-create-update--form-field">
                            <ModalSearch<FinancialInstitutionSearch>
                                action={searchFinancialInstituionsRequest}
                                itemSize={SEARCH_ITEM_SIZE}
                                modalTitle={t(`${I18N_PREFIX}.input.financialInstitution.modal.title`)}
                                modalLabel={t(`${I18N_PREFIX}.input.financialInstitution.modal.label`)}
                                modalPlaceholder={t(`${I18N_PREFIX}.input.financialInstitution.modal.placeholder`)}
                                onSelect={financialInstitution =>
                                    handleChange({
                                        financialInstitutionId: financialInstitution.id,
                                        financialInstitutionName: financialInstitution.name,
                                    })
                                }
                                renderItem={financialInstitution => financialInstitution.name}
                                statusSelector={(state: IRootState) => state.searchFinancialInstitutions.status}
                                dataSelector={(state: IRootState) => state.searchFinancialInstitutions.financialInstituions}
                                resetState={searchFinancialInstituionsResetState}
                            >
                                {handleOpen => (
                                    <ValidSearchInput
                                        label={`${I18N_PREFIX}.input.financialInstitution.label`}
                                        showValidation={showValidation}
                                        placeholder={`${I18N_PREFIX}.input.financialInstitution.placeholder`}
                                        validate={it =>
                                            validateOriginatorFinancialInstitution({ ...scdOriginator, financialInstitutionName: it })
                                        }
                                        value={scdOriginator.financialInstitutionName ?? ''}
                                        onClick={handleOpen}
                                        onFocus={handleOpen}
                                        disabled={!!originatorId}
                                        readOnly
                                        externalUpdate
                                    />
                                )}
                            </ModalSearch>
                        </div>
                        <div className="scd-originator-create-update--form-field">
                            <ModalSearch<BankingCorrespondentSearch>
                                action={searchBankingCorrespondentsRequest}
                                itemSize={SEARCH_ITEM_SIZE}
                                modalTitle={t(`${I18N_PREFIX}.input.bankingCorrespondent.modal.title`)}
                                modalLabel={t(`${I18N_PREFIX}.input.bankingCorrespondent.modal.label`)}
                                modalPlaceholder={t(`${I18N_PREFIX}.input.bankingCorrespondent.modal.placeholder`)}
                                onSelect={bankingCorrespondent =>
                                    handleChange({
                                        bankingCorrespondentId: bankingCorrespondent.id,
                                        bankingCorrespondentName: bankingCorrespondent.name,
                                    })
                                }
                                renderItem={bankingCorrespondent => bankingCorrespondent.name}
                                statusSelector={(state: IRootState) => state.searchBankingCorrespondent.status}
                                dataSelector={(state: IRootState) => state.searchBankingCorrespondent.bankingCorrespondents}
                                resetState={searchBankingCorrespondentsResetState}
                            >
                                {handleOpen => (
                                    <SimpleSearchInput
                                        label={`${I18N_PREFIX}.input.bankingCorrespondent.label`}
                                        placeholder={`${I18N_PREFIX}.input.bankingCorrespondent.placeholder`}
                                        value={scdOriginator.bankingCorrespondentName ?? ''}
                                        onClick={handleOpen}
                                        onFocus={handleOpen}
                                        disabled={!!originatorId}
                                        readOnly
                                        externalUpdate
                                    />
                                )}
                            </ModalSearch>
                        </div>

                        <div className="scd-originator-create-update--form-card">
                            <OriginatorPaymentInstitutionManager
                                originatorPaymentInstitutions={
                                    originatorId ? detailOriginator?.paymentInstitutions : scdOriginator?.paymentInstitutions
                                }
                                onUpdate={originatorPaymentInstitutions =>
                                    handleChange({ paymentInstitutions: originatorPaymentInstitutions })
                                }
                                isEditingOriginator={!!originatorId}
                            />
                        </div>

                        <div className="scd-originator-create-update--form-buttons">
                            <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                            <StandardButtonLegacy label={t('entity.action.save')} onClick={handleSave} />
                        </div>
                    </form>
                )}
            </div>
            {modalCancel && (
                <ModalMessageLegacy
                    title={originatorId ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.push('/originators');
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
        </main>
    );
};

export default ScdOriginatorCreateUpdatePage;
