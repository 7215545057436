import { ReactNode, useEffect, useState } from 'react';
import StandardButton from 'components/buttons/standard-button/standard-button';
import { useTranslation } from 'react-i18next';
import { useCessionOutputResumeBco, useCessionToResumeBco, useExportRequestSummaryResultsBco } from 'reducer-bco/hooks-bco';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import BalanceLoading from 'shared/external/payment-accounts-strategy/balance/components/balance-loading/balance-loading';
import { CessionOutputStatus } from 'model/bco/batch-output-cession-integration-webcred';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-webcred.table.footer.components';

enum IntegrationFlowSteps {
    UNDEFINED = 'UNDEFINED',
    SUMMARY_LOADING = 'SUMMARY_LOADING',
    SEND_CESSION_OPERATION = 'SEND_CESSION_OPERATION',
    SEND_CESSION_OPERATION_PROCESSING = 'SEND_CESSION_OPERATION_PROCESSING',
    SEND_CESSION_OPERATION_FAILURE = 'SEND_CESSION_OPERATION_FAILURE',
    FINALIZE_CESSION = 'FINALIZE_CESSION',
    FINALIZE_CESSION_FAILURE = 'FINALIZE_CESSION_FAILURE',
    CESSION_SUCCESSFUL = 'CESSION_SUCCESSFUL',
}

export const useFooterComponents = () => {
    const { t } = useTranslation();

    const [isProcessModalOpen, setIsProcessModalOpen] = useState<boolean>(false);
    const [isFinalizeModalOpen, setIsFinalizeModalOpen] = useState<boolean>(false);
    const [footerType, setFooterType] = useState<IntegrationFlowSteps>(IntegrationFlowSteps.UNDEFINED);

    const { cessionToResume } = useCessionToResumeBco();
    const finalizeModalOpen = isFinalizeModalOpen && cessionToResume;
    const processModalOpen = isProcessModalOpen && cessionToResume;

    const { status: exportRequestSummaryResultsStatus, exportRequestSummaryResults } = useExportRequestSummaryResultsBco();

    const { cessionOutputResume, status: cessionOutputResumeStatus } = useCessionOutputResumeBco();

    const summaryNotAvailable = exportRequestSummaryResultsStatus !== HttpRequestStatus.SUCCESS;
    const cessionNotAvailable = cessionOutputResumeStatus !== HttpRequestStatus.SUCCESS;

    const isSuccessCession = cessionOutputResume?.status === CessionOutputStatus.SUCCESS;
    const hasCessionError = cessionOutputResume?.status === CessionOutputStatus.ERROR;
    const allCessionOperationProcessed = exportRequestSummaryResults && cessionToResume?.numberOfOperations === exportRequestSummaryResults?.successes;

    const hasAnyCessionOperationFailed = exportRequestSummaryResults && exportRequestSummaryResults?.failures > 0;

    const hasAnyCessionOperationProcessing = exportRequestSummaryResults && exportRequestSummaryResults?.processing > 0;

    const determineFooterType = () => {
        if (summaryNotAvailable || cessionNotAvailable) return IntegrationFlowSteps.SUMMARY_LOADING;
        if (isSuccessCession) return IntegrationFlowSteps.CESSION_SUCCESSFUL;
        if (hasCessionError) return IntegrationFlowSteps.FINALIZE_CESSION_FAILURE;
        if (allCessionOperationProcessed) return IntegrationFlowSteps.FINALIZE_CESSION;
        if (hasAnyCessionOperationFailed) return IntegrationFlowSteps.SEND_CESSION_OPERATION_FAILURE;
        if (hasAnyCessionOperationProcessing) return IntegrationFlowSteps.SEND_CESSION_OPERATION_PROCESSING;

        return IntegrationFlowSteps.SEND_CESSION_OPERATION;
    };

    useEffect(() => {
        setFooterType(determineFooterType());
    }, [isSuccessCession, hasCessionError, allCessionOperationProcessed, hasAnyCessionOperationFailed, hasAnyCessionOperationProcessing, exportRequestSummaryResultsStatus, cessionOutputResumeStatus]);

    const generalFooterContent = (children: ReactNode): ReactNode => (
        <div className="cession-integration-footer">
            <div className="cession-integration-footer__button-box">{children}</div>
        </div>
    );

    const EmptyFooter: ReactNode = <></>;

    const SummaryLoadingFooter: ReactNode = generalFooterContent(
        <div className="cession-integration-footer__message-box">
            <BalanceLoading />
        </div>
    );

    const SendCessionOperationFailureContent: ReactNode = generalFooterContent(<div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.error-message`)}</div>);

    const CessionSuccessfulContent: ReactNode = generalFooterContent(<div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.success-message`)}</div>);
    const FinalizeCessionFailureContent: ReactNode = generalFooterContent(<div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.finalize-error`)}</div>);

    const FinalizeCessionContent: ReactNode = generalFooterContent(
        <>
            <div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.finalize-message`)}</div>
            <StandardButton label={t(`${I18N_PREFIX}.finalize`)} onClick={() => setIsFinalizeModalOpen(true)} disabled={!allCessionOperationProcessed} />
        </>
    );

    const SendCessionOperationProcessingContent: ReactNode = generalFooterContent(<div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.loading`)}</div>);

    const SendCessionOperationContent: ReactNode = generalFooterContent(
        <StandardButton label={t(`${I18N_PREFIX}.send`)} onClick={() => setIsProcessModalOpen(true)} disabled={hasAnyCessionOperationProcessing} />
    );

    const renderFooterContent = {
        UNDEFINED: EmptyFooter,
        SUMMARY_LOADING: SummaryLoadingFooter,
        SEND_CESSION_OPERATION: SendCessionOperationContent,
        SEND_CESSION_OPERATION_PROCESSING: SendCessionOperationProcessingContent,
        SEND_CESSION_OPERATION_FAILURE: SendCessionOperationFailureContent,
        FINALIZE_CESSION: FinalizeCessionContent,
        FINALIZE_CESSION_FAILURE: FinalizeCessionFailureContent,
        CESSION_SUCCESSFUL: CessionSuccessfulContent,
    } as Record<Partial<IntegrationFlowSteps>, ReactNode>;

    const currentFooter = renderFooterContent[footerType];

    return { currentFooter, processModalOpen, setIsProcessModalOpen, finalizeModalOpen, setIsFinalizeModalOpen };
};
