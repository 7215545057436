import { AxiosResponse } from 'axios';
import {
    PaymentAccountToGetInternalRepayment,
    PaymentTransactionDetail,
    PaymentTransactionManualDisbursementValidation,
    PaymentTransactionToCreateRepayment,
    PaymentTransactionToManualDisbursement,
    PaymentTransactionToSimple,
    PaymentTrasactionToProcessResponse,
    ScdLegacyPaymentTransactionData,
    ScdPaymentTransactionData,
    ScdPaymentTransactionPendingList,
    ScdPaymentTransactionSummarize,
    ScdPaymentTransactionToCreate,
    ScdPaymentTransactionsToArchive,
    ScdPaymentTransactionsToProcess,
} from 'model/payment-transaction';
import { PaymentTransactionFilterPageable } from 'model/reducers';
import { api } from 'services/api/api';
import { BASE_URL_ADMIN, Page } from 'services/page';

const paymentTransaction = () => {
    const listPaymentTransactions = (
        request: PaymentTransactionFilterPageable
    ): Promise<AxiosResponse<Page<ScdPaymentTransactionData>>> => {
        const { filter, pageable } = request;

        const _requestFilters = [
            filter?.date?.start && filter.date?.end && `date.start=${filter.date.start}&date.end=${filter.date.end}`,
            filter?.date?.start && !filter.date?.end && `date.start=${filter.date.start}`,
            !filter?.date?.start && filter?.date?.end && `date.end=${filter.date.end}`,
            filter?.amount?.minValue && `amount.minValue=${filter.amount.minValue}`,
            filter?.amount?.maxValue && `amount.maxValue=${filter.amount.maxValue}`,
            filter?.status && `status=${filter?.status}`,
            filter?.favored && `favored=${filter.favored}`,
            filter?.mode && `mode=${filter.mode}`,
            filter?.type && `type=${filter.type}`,
            filter?.dispatchMode && `dispatchMode=${filter.dispatchMode}`,
            filter?.originatorId && `originatorId=${filter.originatorId}`,
            filter?.bankIdentification && `bankIdentification=${filter.bankIdentification}`
        ]
            .filter(it => !!it)
            .join('&');

        return api.get<Page<ScdPaymentTransactionData>>(`${BASE_URL_ADMIN}/payment-transactions?${_requestFilters}`, {
            params: { ...pageable },
        });
    };

    const createPaymentTransaction = (
        paymentTransaction: ScdPaymentTransactionToCreate
    ): Promise<AxiosResponse<ScdLegacyPaymentTransactionData>> => {
        return api.post<ScdLegacyPaymentTransactionData>(`api/payment-transactions`, paymentTransaction);
    };

    const legacyProcessPaymentTransactions = (request: ScdPaymentTransactionsToProcess): Promise<AxiosResponse<void>> => {
        return api.post<void>(`api/payment-transactions/process`, request);
    };

    const archivePaymentTransactions = (request: ScdPaymentTransactionsToArchive): Promise<AxiosResponse<void>> => {
        return api.patch<void>(`api/payment-transactions/archive`, request);
    };

    const summarizePaymentTransactions = (): Promise<AxiosResponse<ScdPaymentTransactionSummarize>> => {
        return api.get<ScdPaymentTransactionSummarize>(`${BASE_URL_ADMIN}/payment-transactions/summarize`);
    };

    const listPendingPaymentTransactions = (): Promise<AxiosResponse<ScdPaymentTransactionPendingList>> => {
        return api.get<ScdPaymentTransactionPendingList>(`${BASE_URL_ADMIN}/payment-transactions/pending`);
    };

    const processManualDispatchPaymentTransactions = (
        request: ScdPaymentTransactionsToProcess
    ): Promise<AxiosResponse<PaymentTrasactionToProcessResponse>> => {
        return api.post(`${BASE_URL_ADMIN}/payment-transactions/dispatch/manual`, request);
    };

    const detailPaymentTransaction = (paymentTransactionId: string): Promise<AxiosResponse<PaymentTransactionDetail>> => {
        return api.get<PaymentTransactionDetail>(`${BASE_URL_ADMIN}/payment-transactions/${paymentTransactionId}`);
    };

    const manualDisbursementPaymentTransaction = (
        request: PaymentTransactionToManualDisbursement
    ): Promise<AxiosResponse<PaymentTransactionToSimple>> => {
        return api.post<PaymentTransactionToSimple>(
            `${BASE_URL_ADMIN}/payment-transactions/${request.paymentTransactionId}/disbursement/manual`,
            request
        );
    };

    const manualDisbursementValidationPaymentTransaction = (
        paymentTransactionId: string
    ): Promise<AxiosResponse<PaymentTransactionManualDisbursementValidation>> => {
        return api.get<PaymentTransactionManualDisbursementValidation>(
            `${BASE_URL_ADMIN}/payment-transactions/${paymentTransactionId}/disbursement/manual/validate`
        );
    };

    const createInternalRepaymentPaymentTransaction = (
        request: PaymentTransactionToCreateRepayment
    ): Promise<AxiosResponse<PaymentTransactionToSimple>> => {
        return api.post<PaymentTransactionToSimple>(`${BASE_URL_ADMIN}/payment-transactions/repayment/internal/default`, request);
    };

    const internalRepaymentAccountPaymentTransaction = (): Promise<AxiosResponse<PaymentAccountToGetInternalRepayment>> => {
        return api.get<PaymentAccountToGetInternalRepayment>(`${BASE_URL_ADMIN}/payment-transactions/repayment/internal/accounts/default`);
    };

    const confirmExternalTransaction = (paymentTransactionId: number): Promise<AxiosResponse<PaymentTransactionToSimple>> => {
        return api.put<PaymentTransactionToSimple>(`${BASE_URL_ADMIN}/payment-transactions/${paymentTransactionId}/external-disbursement/confirm`);
    }

    return {
        listPaymentTransactions,
        createPaymentTransaction,
        legacyProcessPaymentTransactions,
        archivePaymentTransactions,
        listPendingPaymentTransactions,
        summarizePaymentTransactions,
        processManualDispatchPaymentTransactions,
        detailPaymentTransaction,
        manualDisbursementPaymentTransaction,
        manualDisbursementValidationPaymentTransaction,
        createInternalRepaymentPaymentTransaction,
        internalRepaymentAccountPaymentTransaction,
        confirmExternalTransaction
    };
};

export default paymentTransaction();
