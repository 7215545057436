import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';

// BatchOutput
import { CessionOperationStatusBcoState } from 'reducer-bco/batch-output/cession-operation-status/types';
import { CessionOperationBcoState } from 'reducer-bco/batch-output/cession-operation/types';
import { CessionOutputResumeBcoState } from 'reducer-bco/batch-output/cession-output-resume/types';
import { CessionToResumeBcoState } from 'reducer-bco/batch-output/cession-to-resume/types';
import { ChunkCessionFinalizeBcoState } from 'reducer-bco/batch-output/chunk-cession-finalize/types';
import { ChunkStreamPartitioningBcoState } from 'reducer-bco/batch-output/chunk-stream-partitioning/types';
import { ChunkStreamProcessBcoState } from 'reducer-bco/batch-output/chunk-stream-process/types';
import { ChunkStreamToResumeBcoState } from 'reducer-bco/batch-output/chunk-stream-to-resume/types';
import { ConcludeCessionBcoState } from 'reducer-bco/batch-output/conclude-cession/types';
import { BatchOutputsDetailsBcoState } from 'reducer-bco/batch-output/details-batch-output/types';
import { DisbursementConfirmationToProcessBcoState } from 'reducer-bco/batch-output/disbursement-confirmation-to-process/types';
import { DisbursementConfirmationToResumeBcoState } from 'reducer-bco/batch-output/disbursement-confirmation-to-resume/types';
import { ExportRequestSummaryResultsBcoState } from 'reducer-bco/batch-output/export-request-summary-results/types';
import { BatchOutputsListBcoState } from 'reducer-bco/batch-output/list-batch-output/types';
import { ProcessCessionOperationBcoState } from 'reducer-bco/batch-output/process-cession-operation/types';
import { ChunkStreamRetryBcoState } from 'reducer-bco/batch-output/retry-chunk-stream/types';
import { DataChunkRetryBcoState } from 'reducer-bco/batch-output/retry-data-chunk/types';

// BatchOutput List
export const useBatchOutputsListBco = () =>
    useSelector<IRootState, BatchOutputsListBcoState>((state: IRootState) => state.listBatchOutputs);

// BatchOutput Details
export const useBatchOutputDetailsBco = () =>
    useSelector<IRootState, BatchOutputsDetailsBcoState>((state: IRootState) => state.detailsBatchOutput);

// ChunkStreamToResume
export const useChunkStreamToResumeBco = () =>
    useSelector<IRootState, ChunkStreamToResumeBcoState>((state: IRootState) => state.chunkStreamToResume);

// ChunkStreamPartitioning
export const useChunkStreamPartitioningBco = () =>
    useSelector<IRootState, ChunkStreamPartitioningBcoState>((state: IRootState) => state.chunkStreamPartitioning);

//CessionToResume
export const useCessionToResumeBco = () => useSelector<IRootState, CessionToResumeBcoState>((state: IRootState) => state.cessionToResume);

// ChunkStreamProcess
export const useChunkStreamProcessBco = () =>
    useSelector<IRootState, ChunkStreamProcessBcoState>((state: IRootState) => state.chunkStreamProcess);

// ChunkStreamProcess
export const useChunkCessionFinalizeBco = () =>
    useSelector<IRootState, ChunkCessionFinalizeBcoState>((state: IRootState) => state.chunkCessionFinalize);

// DataChunkRetry
export const useDataChunkRetryBco = () => useSelector<IRootState, DataChunkRetryBcoState>((state: IRootState) => state.dataChunkRetry);

// ChunkStreamRetry
export const useChunkStreamRetryBco = () =>
    useSelector<IRootState, ChunkStreamRetryBcoState>((state: IRootState) => state.chunkStreamRetry);

// CessionOperation
export const useCessionOperationBco = () =>
    useSelector<IRootState, CessionOperationBcoState>((state: IRootState) => state.listCessionOperation);

// ProcessCessionOperation
export const useProcessCessionOperationBco = () =>
    useSelector<IRootState, ProcessCessionOperationBcoState>((state: IRootState) => state.processCessionOperation);

// ExportRequestSummaryResults
export const useExportRequestSummaryResultsBco = () =>
    useSelector<IRootState, ExportRequestSummaryResultsBcoState>((state: IRootState) => state.exportRequestSummaryResults);

// ConcludeCession
export const useConcludeCessionBco = () => useSelector<IRootState, ConcludeCessionBcoState>((state: IRootState) => state.concludeCession);

// CessionOperationStatus
export const useCessionOperationStatusBco = () =>
    useSelector<IRootState, CessionOperationStatusBcoState>((state: IRootState) => state.cessionOperationStatus);

// CessionOutputResume
export const useCessionOutputResumeBco = () =>
    useSelector<IRootState, CessionOutputResumeBcoState>((state: IRootState) => state.cessionOutputResume);

// DisbursementConfirmationToResume
export const useDisbursementConfirmationToResumeBco = () =>
    useSelector<IRootState, DisbursementConfirmationToResumeBcoState>((state: IRootState) => state.confirmationToResume)

// DisbursementConfirmationToProcess
export const useDisbursementConfirmationToProcessBco = () =>
    useSelector<IRootState, DisbursementConfirmationToProcessBcoState>((state: IRootState) => state.confirmationToProcess)
