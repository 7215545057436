import { action } from 'typesafe-actions';

import { BankingCorrespondent } from 'model/banking-correspondent';
import { GiroWebappError } from 'model/error';
import { SearchRequest } from 'model/reducers';
import { SearchBankingCorrespondentTypes } from 'reducer/banking-correspondent/search-banking-correspondents/types';
import { Page } from 'services/page';

export const searchBankingCorrespondentsRequest = (request: SearchRequest) => action(SearchBankingCorrespondentTypes.SEARCH_BANKING_CORRESPONDENTS_REQUEST, request);
export const searchBankingCorrespondentsSuccess = (bankingCorrespondents: Page<BankingCorrespondent>) => action(SearchBankingCorrespondentTypes.SEARCH_BANKING_CORRESPONDENTS_SUCCESS, bankingCorrespondents);
export const searchBankingCorrespondentsError = (error: GiroWebappError) => action(SearchBankingCorrespondentTypes.SEARCH_BANKING_CORRESPONDENTS_ERROR, error);
export const searchBankingCorrespondentsResetState = () => action(SearchBankingCorrespondentTypes.SEARCH_BANKING_CORRESPONDENTS_RESET);
