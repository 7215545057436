import { Button, CircularProgress } from '@material-ui/core';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { useRootDispatch } from 'reducer/hooks';
import { AnyAction } from 'redux';
import { Page } from 'services/page';

import { INFINITE_SCROLL_PAGE_SIZE } from 'services/pageable';
import './use-infinite-scroll-with-pageable-collapse.scss';

interface InfiniteScroll<T> {
    handlePageChange: () => void;
    currentPage: number;
    dataSelector: (state: IRootState) => Page<T> | undefined;
    statusSelector: (state: IRootState) => HttpRequestStatus;
    action: (request: any) => AnyAction;
    resetStateAction: () => AnyAction;
    filterPageable: () => void;
    setPage: (value: number) => void;
    itemsPerPage?: number;
}

const UseInfiniteScrollWithPageableCollapse = <T extends object>(props: InfiniteScroll<T>) => {
    const { handlePageChange, currentPage, dataSelector, statusSelector, filterPageable, action, resetStateAction, setPage, itemsPerPage } = props;

    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const items = useSelector<IRootState, Page<T> | undefined>(dataSelector);
    const status = useSelector<IRootState, HttpRequestStatus>(statusSelector);

    const isLoading = useMemo(() => status === HttpRequestStatus.ON_GOING, [status]);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

    const isDisabled = useMemo(() => {
        if (!items?.content?.length || items.numberOfElements < INFINITE_SCROLL_PAGE_SIZE) {
            return true;
        }
        return false;
    }, [items]);

    const nextPage = useCallback(() => {
        if (isDisabled) return;
        handlePageChange();
    }, [isDisabled, handlePageChange]);

    const handleRefresh = useCallback(() => {
        if (currentPage === 0) {
            setIsRefreshing(true);
            return;
        }
        setPage(0);
        dispatch(resetStateAction());
    }, [setPage, currentPage]);

    useEffect(() => {
        if (isRefreshing) {
            setIsRefreshing(false);
        }
        if (currentPage === items?.number) return;
        if (!isRefreshing) {
            dispatch(action(filterPageable()));
        }
    }, [dispatch, action, filterPageable, isRefreshing]);

    const isMoreItemsButtonVisible: boolean = !isLoading && (items?.content?.length ?? 0) >= (itemsPerPage ?? INFINITE_SCROLL_PAGE_SIZE);

    const InfiniteScrollButton = () => {
        return (
            <footer className="page-container--infinite-scroll">
                {isLoading && !!items?.content?.length && (
                    <div className="page-container--infinite-scroll--loading">
                        <CircularProgress size="35px" style={{ color: '#3f41d1' }} />
                    </div>
                )}

                {isMoreItemsButtonVisible && (
                    <Button variant="contained" onClick={nextPage} disabled={isDisabled} disableRipple>
                        {t('infinite-scroll.more')}
                    </Button>
                )}
            </footer>
        );
    };

    return {
        InfiniteScrollButton,
        isLoading,
        handleRefresh,
        status,
        items: items?.content,
    };
};

export default UseInfiniteScrollWithPageableCollapse;
