import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// create payment transactions by batch
import {
    createPaymentTransactionsByBatchReducer
} from 'reducer/payment-transaction-by-batch/create-payment-transactions-by-batch/reducer';
import createPaymentTransactionsByBatchSaga
    from 'reducer/payment-transaction-by-batch/create-payment-transactions-by-batch/sagas';
import {
    CreatePaymentTransactionsByBatchState
} from 'reducer/payment-transaction-by-batch/create-payment-transactions-by-batch/types';

// List payment transactions by batch
import {
    listPaymentTransactionsByBatchReducer
} from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch/reducer';
import listPaymentTransactionsByBatchSaga
    from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch/sagas';
import {
    ListPaymentTransactionsByBatchState
} from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch/types';

export interface PaymentTransactionByBatchState {
    readonly createPaymentTransactionsByBatch: CreatePaymentTransactionsByBatchState;
    readonly listPaymentTransactionsByBatch: ListPaymentTransactionsByBatchState;
}

export const PaymentTransactionByBatchReducer: ReducersMapObject<PaymentTransactionByBatchState, AnyAction> = {
    createPaymentTransactionsByBatch: createPaymentTransactionsByBatchReducer,
    listPaymentTransactionsByBatch: listPaymentTransactionsByBatchReducer
};

export function* paymentTransactionByBatchSagas() {
    yield all([
        fork(createPaymentTransactionsByBatchSaga),
        fork(listPaymentTransactionsByBatchSaga)
    ]);
}
