import {
    PaymentTransactionByBatchToCreateList
} from 'entities/payment-transaction/payment-transaction-in-series-by-batch/payment-transaction-in-series-by-batch';
import { PaymentValueExportType } from 'model/enums/payment-value-export-type';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.payment-transaction.validate';

type TransactionToCreate = PaymentTransactionByBatchToCreateList | undefined | null;

const PAYMENT_TRANSACTIONS_ACCOUNT_VALIDATION: Validations<TransactionToCreate> = {
    notFound: {
        predicate: it => {
            const isSelectedAccount = !!it?.accountId;
            return isSelectedAccount || ValidationUtils.ARRAYS.isNotEmpty(it?.accounts);
        },
        errorMessage: `${I18N_PREFIX}.account.notFound`
    },
    maxLength: {
        predicate: it => {
            const isSelectedAccount = !!it?.accountId;
            return isSelectedAccount || ValidationUtils.ARRAYS.maxSize(1)(it?.accounts);
        },
        errorMessage: `${I18N_PREFIX}.account.maxLength`
    }
};

const PAYMENT_TRANSACTIONS_ACCOUNT_TO_DEBTOR_VALIDATION: Validations<TransactionToCreate> = {
    notFoundToDebtor: {
        predicate: it => {
            const isSelectedAccount = !!it?.accountId;
            return isSelectedAccount || ValidationUtils.ARRAYS.isNotEmpty(it?.accounts);
        },
        errorMessage: `${I18N_PREFIX}.account.notFoundToDebtor`
    },
    maxLength: {
        predicate: it => {
            const isSelectedAccount = !!it?.accountId;
            return isSelectedAccount || ValidationUtils.ARRAYS.maxSize(1)(it?.accounts);
        },
        errorMessage: `${I18N_PREFIX}.account.maxLength`
    }
};

const VALIDATION_BY_EXPORT_TYPE_RECORD: Record<PaymentValueExportType, Validations<TransactionToCreate>> = {
    DISBURSEMENT_BY_BATCH: PAYMENT_TRANSACTIONS_ACCOUNT_VALIDATION,
    DISBURSEMENT_TO_DEBTOR: PAYMENT_TRANSACTIONS_ACCOUNT_TO_DEBTOR_VALIDATION,
    EXTERNAL_DISBURSEMENT: PAYMENT_TRANSACTIONS_ACCOUNT_VALIDATION
};

export const validatePaymentTransactionsAccount = (value: TransactionToCreate) =>
    ValidationUtils.validate(
        value,
        VALIDATION_BY_EXPORT_TYPE_RECORD[value?.type ?? PaymentValueExportType.DISBURSEMENT_BY_BATCH]
    );
