import StandardButton from 'components/buttons/standard-button/standard-button';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisbursementConfirmationToResumeBco, useExportRequestSummaryResultsBco } from 'reducer-bco/hooks-bco';
import BalanceLoading from 'shared/external/payment-accounts-strategy/balance/components/balance-loading/balance-loading';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.disbursement-confirmation.table.footer.components';

enum DisbursementConfirmationApexIntegrationSteps {
    UNDEFINED = 'UNDEFINED',
    SUMMARY_LOADING = 'SUMMARY_LOADING',
    SEND_OPERATION = 'SEND_OPERATION',
    SEND_OPERATION_PROCESSING = 'SEND_OPERATION_PROCESSING',
    SEND_OPERATION_FAILURE = 'SEND_OPERATION_FAILURE',
    SEND_SUCCESSFUL = 'SEND_SUCCESSFUL',
}

export const useFooterComponents = () => {
    const { t } = useTranslation();
    const [isProcessModalOpen, setIsProcessModalOpen] = useState<boolean>(false);
    const [footerType, setFooterType] = useState<DisbursementConfirmationApexIntegrationSteps>(DisbursementConfirmationApexIntegrationSteps.UNDEFINED);

    const { confirmationToResume } = useDisbursementConfirmationToResumeBco();

    const processModalOpen = isProcessModalOpen && confirmationToResume;

    const { status: exportRequestSummaryResultsStatus, exportRequestSummaryResults } = useExportRequestSummaryResultsBco();

    const allOperationProcessed = exportRequestSummaryResults && exportRequestSummaryResults?.successes > 0;
    const hasAnyOperationFailed = exportRequestSummaryResults && exportRequestSummaryResults?.failures > 0;
    const hasAnyOperationProcessing = exportRequestSummaryResults && exportRequestSummaryResults?.processing > 0;

    const determineFooterType = () => {
        if (exportRequestSummaryResultsStatus !== HttpRequestStatus.SUCCESS) return DisbursementConfirmationApexIntegrationSteps.SUMMARY_LOADING;
        if (allOperationProcessed) return DisbursementConfirmationApexIntegrationSteps.SEND_SUCCESSFUL;
        if (hasAnyOperationFailed) return DisbursementConfirmationApexIntegrationSteps.SEND_OPERATION_FAILURE;
        if (hasAnyOperationProcessing) return DisbursementConfirmationApexIntegrationSteps.SEND_OPERATION_PROCESSING;

        return DisbursementConfirmationApexIntegrationSteps.SEND_OPERATION;
    };

    useEffect(() => {
        setFooterType(determineFooterType());
    }, [allOperationProcessed, hasAnyOperationFailed, hasAnyOperationProcessing, exportRequestSummaryResultsStatus]);

    const generalFooterContent = (children: ReactNode): ReactNode => (
        <div className="cession-integration-footer">
            <div className="cession-integration-footer__button-box">{children}</div>
        </div>
    );

    const EmptyFooter: ReactNode = <></>;

    const SummaryLoadingFooter: ReactNode = generalFooterContent(
        <div className="cession-integration-footer__message-box">
            <BalanceLoading />
        </div>
    );

    const SendOperationFailureContent: ReactNode = generalFooterContent(<div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.error-message`)}</div>);

    const SuccessfulContent: ReactNode = generalFooterContent(<div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.success-message`)}</div>);

    const SendOperationProcessingContent: ReactNode = generalFooterContent(<div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.loading`)}</div>);

    const SendOperationContent: ReactNode = generalFooterContent(
        <StandardButton
            label={t(`${I18N_PREFIX}.send`)}
            onClick={() => {
                setIsProcessModalOpen(true);
            }}
            disabled={hasAnyOperationProcessing}
        />
    );

    const renderFooterContent = {
        UNDEFINED: EmptyFooter,
        SUMMARY_LOADING: SummaryLoadingFooter,
        SEND_OPERATION: SendOperationContent,
        SEND_OPERATION_PROCESSING: SendOperationProcessingContent,
        SEND_OPERATION_FAILURE: SendOperationFailureContent,
        SEND_SUCCESSFUL: SuccessfulContent,
    } as Record<DisbursementConfirmationApexIntegrationSteps, ReactNode>;

    const currentFooter = renderFooterContent[footerType];

    return { currentFooter, processModalOpen, setIsProcessModalOpen };
};
