import clsx from 'clsx';
import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import PaymentInstitutionsAccountsMoreOptionsMenuItems from 'entities/payment-institutions-accounts/components/payment-institutions-accounts-more-options-menu-items/payment-institutions-accounts-more-options-menu-items';
import { PaymentInstitutionAccountStatus } from 'model/enums/payment-institution-account-status';
import { PaymentInstitutionAccountType } from 'model/enums/payment-institution-account-type';
import { PaymentInstitutionReference } from 'model/enums/payment-institution-reference';
import { PaymentInstitutionAccountToGetAll } from 'model/payment-institution-account';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import ExternalBalance from 'shared/external/payment-accounts-strategy/balance/external-balance';

interface PaymentInstitutionsAccountsListProps {
    paymentInstitutionsAccounts: PaymentInstitutionAccountToGetAll[];
}

const I18N_PREFIX = 'pages.scd.payment-institutions-accounts.table';

const PaymentInstitutionsAccountsList = (props: PaymentInstitutionsAccountsListProps) => {
    const { paymentInstitutionsAccounts } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [selectedPaymentInstitutionAccount, setSelectedPaymentInstitutionAccount] = 
        useState<PaymentInstitutionAccountToGetAll | undefined>(undefined);

    const { t } = useTranslation();
    const { url } = useRouteMatch();

    const disableByTechnicalSupplierReference = (technicalSupplierReference: string) => {
        if (technicalSupplierReference !== PaymentInstitutionReference.TRANSFEERA) {
            return true;
        }

        return false;
    };

    const isAccountExternal = (accountType: string) => {
        if (accountType === PaymentInstitutionAccountType.EXTERNAL) {
            return true;
        }

        return false;
    };

    return (
        <>
            {paymentInstitutionsAccounts?.map(account => (
                <tr className="page-container--table-cell" key={account.id}>
                    <td className="page-container--table-cell-title">{account.name}</td>
                    <td className="page-container--table-cell-title">
                        <span className="page-container--table-status">
                            <span
                                className={clsx('page-container--table-status', {
                                    ACTIVE: account.status === PaymentInstitutionAccountStatus.ACTIVE,
                                    INACTIVE: account.status === PaymentInstitutionAccountStatus.INACTIVE,
                                })}
                            >
                                {t(`${I18N_PREFIX}.status.options.${account.status}`).toUpperCase()}
                            </span>
                        </span>
                    </td>
                    <td className="page-container--table-cell-title">{t(`${I18N_PREFIX}.type.options.${account.type}`)}</td>
                    <td className="page-container--table-cell-title">{account.technicalSupplierName}</td>
                    {isAccountExternal(account.type) ? (
                        <td className="page-container--table-cell-title">{t(`${I18N_PREFIX}.no-external-balance`)}</td>
                    ) : (
                        <td className="page-container--table-cell-title">
                            <ExternalBalance
                                technicalSupplierReference={account.technicalSupplierReference}
                                paymentInstitutionAccountId={account.id}
                            />
                        </td>
                    )}
                    <td className="page-container--table-cell-icon">
                        <Link to={`${url}/${account.id}`}>
                            <VisualizeIconButton />
                        </Link>
                    </td>
                    <td className="page-container--table-cell-icon">
                        <Link to={`${url}/${account.id}/edit`}>
                            <EditIconButton />
                        </Link>
                    </td>
                    <td className="page-container--table-cell-icon">
                        <MoreOptionsMenu
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            onClick={() => setSelectedPaymentInstitutionAccount(account)}
                            disabled={
                                disableByTechnicalSupplierReference(account.technicalSupplierReference) ||
                                isAccountExternal(account.type)
                            }
                        >
                            <PaymentInstitutionsAccountsMoreOptionsMenuItems
                                selectedPaymentInstitutionAccount={selectedPaymentInstitutionAccount}
                            />
                        </MoreOptionsMenu>
                    </td>
                </tr>
            ))}
        </>
    );
};

export default PaymentInstitutionsAccountsList;
