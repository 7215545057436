import { TechnicalSupplierType } from 'model/enums/technical-supplier-type';
import { ScdTechnicalSupplier } from 'model/technical-supplier';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.technical-supplier.validate';

const TECHNICAL_SUPPLIER_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.name.maxLength`,
    },
};
export const validateTechnicalSupplierName = (value: string | undefined | null) =>
    ValidationUtils.validate(value, TECHNICAL_SUPPLIER_NAME_VALIDATION);

const TECHNICAL_SUPPLIER_INTERNAL_REFERENCE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.internalReference.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.internalReference.maxLength`,
    },
};
export const validateTechnicalSupplierInternalReference = (value: string | undefined | null) =>
    ValidationUtils.validate(value, TECHNICAL_SUPPLIER_INTERNAL_REFERENCE_VALIDATION);

const TECHNICAL_SUPPLIER_ACCOUNT_TYPE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.accountType.required`,
    },
};
export const validateTechnicalSupplierAccountType = (value: string | undefined | null) =>
    ValidationUtils.validate(value, TECHNICAL_SUPPLIER_ACCOUNT_TYPE_VALIDATION);

const TECHNICAL_SUPPLIER_TYPE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.type.required`,
    },
};
export const validateTechnicalSupplierType = (value: string | undefined | null) =>
    ValidationUtils.validate(value, TECHNICAL_SUPPLIER_TYPE_VALIDATION);

const TECHNICAL_SUPPLIER_FINANCIAL_INSTITUTION_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.financialInstitution.required`,
    },
};
export const validateTechnicalSupplierFinancialInstitution = (value: string | undefined | null) =>
    ValidationUtils.validate(value, TECHNICAL_SUPPLIER_FINANCIAL_INSTITUTION_VALIDATION);

export const validateScdTechnicalSupplier = (value: ScdTechnicalSupplier | undefined): boolean => {
    if (!value) return false;

    return validateTechnicalSupplierName(value.name).isValid &&
        validateTechnicalSupplierInternalReference(value.internalReference).isValid &&
        validateTechnicalSupplierType(value.type).isValid &&
        validateTechnicalSupplierFinancialInstitution(value.financialInstitutionName).isValid &&
        (value.type === TechnicalSupplierType.PAYMENT_INSTITUTION
            ? validateTechnicalSupplierAccountType(value.accountType).isValid
            : true);
};
