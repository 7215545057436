import ContextRibbon from 'components/context-ribbon/context-ribbon';

import { Loading } from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';

import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useDetailTechnicalSupplierState, useRootDispatch } from 'reducer/hooks';

import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { DefaultPaymentInstitution } from 'model/enums/default-payment-institution';
import { TechnicalSupplierType } from 'model/enums/technical-supplier-type';
import {
    detailTechnicalSupplierRequest,
    detailTechnicalSupplierResetState,
} from 'reducer/technical-supplier/detail-technical-supplier/actions';
import { getPersonType } from 'shared/util/person-type-utils';
import './technical-supplier-detail.scss';

type ScdProgramDetailProps = RouteComponentProps<{ technicalSupplierId: string }>;

const I18N_PREFIX = 'pages.scd.technical-supplier.input';

const ScdTechnicalSupplierDetailPage = (props: ScdProgramDetailProps) => {
    const { t } = useTranslation();
    const [technicalSupplierId] = useState<number>(Number(props.match.params.technicalSupplierId));
    const { status, technicalSupplier } = useDetailTechnicalSupplierState();

    const loading = status !== HttpRequestStatus.SUCCESS;

    const dispatch = useRootDispatch();

    useEffect(() => {
        dispatch(detailTechnicalSupplierRequest(technicalSupplierId));
        return () => {
            dispatch(detailTechnicalSupplierResetState());
        };
    }, [dispatch, technicalSupplierId]);

    const getIsDefaultPaymentInstitution = (isDefaultPaymentInstitution: boolean | undefined) => {
        return isDefaultPaymentInstitution !== null && isDefaultPaymentInstitution !== undefined
            ? isDefaultPaymentInstitution
                ? DefaultPaymentInstitution.YES
                : DefaultPaymentInstitution.NO
            : '-';
    };

    const getNumberAndDigit = (number: string | undefined | null, digit: string | undefined | null) => {
        return number && digit ? `${number} - ${digit}` : number ?? '-';
    };

    return (
        <main className="scd-technical-supplier__detail">
            <ContextRibbon />
            <div className="scd-technical-supplier__detail--main-container">
                <header className="scd-technical-supplier__detail--header">
                    <h2 className="scd-technical-supplier__detail--header--title">{t('pages.scd.technical-supplier.detail.title')}</h2>
                </header>
                <div className="scd-technical-supplier__detail--container">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.name.label`)} value={technicalSupplier?.name} />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.internalReference.label`)}
                                value={technicalSupplier?.internalReference ?? '-'}
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.type.label`)}
                                value={t(`${I18N_PREFIX}.type.options.${technicalSupplier?.type}`)}
                            />

                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.status.label`)}
                                value={t(`${I18N_PREFIX}.status.options.${technicalSupplier?.status}`)}
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.financialInstitution.label`)}
                                value={technicalSupplier?.financialInstitution?.name}
                            />
                            {technicalSupplier?.type === TechnicalSupplierType.PAYMENT_INSTITUTION && (
                                <>
                                    <ReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.accountType.label`)}
                                        value={t(`${I18N_PREFIX}.accountType.options.${technicalSupplier?.accountType}`)}
                                    />
                                    <ReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.isDefaultPaymentInstitution.label`)}
                                        value={getIsDefaultPaymentInstitution(technicalSupplier?.isDefaultPaymentInstitution)}
                                    />
                                </>
                            )}

                            {technicalSupplier?.debtorAccount && (
                                <>
                                    <ReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.debtorAccount.bank.name`)}
                                        value={technicalSupplier?.debtorAccount?.bank?.name}
                                    />
                                    <ReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.debtorAccount.bank.number`)}
                                        value={technicalSupplier?.debtorAccount?.bank?.number}
                                    />
                                    <ReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.debtorAccount.accountNumber.detail`)}
                                        value={getNumberAndDigit(
                                            technicalSupplier?.debtorAccount?.accountNumber,
                                            technicalSupplier?.debtorAccount?.accountDigit
                                        )}
                                    />
                                    <ReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.debtorAccount.agencyNumber.detail`)}
                                        value={getNumberAndDigit(
                                            technicalSupplier?.debtorAccount?.agencyNumber,
                                            technicalSupplier?.debtorAccount?.agencyDigit
                                        )}
                                    />
                                    <ReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.debtorAccount.favoredName.label`)}
                                        value={technicalSupplier?.debtorAccount?.favoredName}
                                    />
                                    <ReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.debtorAccount.favoredIdentification.label`)}
                                        value={
                                            <IdentificationFormatter
                                                value={technicalSupplier?.debtorAccount?.favoredIdentification}
                                                type={getPersonType(technicalSupplier?.debtorAccount?.favoredIdentification)}
                                            />
                                        }
                                    />
                                    <ReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.debtorAccount.externalReference.detail`)}
                                        value={technicalSupplier?.debtorAccount?.externalReference}
                                    />
                                    <ReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.debtorAccount.type.label`)}
                                        value={t(`${I18N_PREFIX}.debtorAccount.type.options.${technicalSupplier?.debtorAccount?.type}`)}
                                    />
                                    <ReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.debtorAccount.bankAccountType.label`)}
                                        value={t(
                                            `${I18N_PREFIX}.debtorAccount.bankAccountType.options.${technicalSupplier?.debtorAccount?.bankAccountType}`
                                        )}
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default ScdTechnicalSupplierDetailPage;
