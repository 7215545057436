import BackDrop from 'components/backdrop/backdrop';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import { CopyIcon } from 'components/copy/copy-icon';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter from 'components/formatter/date/date-formatter';
import FormatterUtils from 'components/formatter/formatter-utils';
import PercentageFormatterWithSixDecimals from 'components/formatter/percentage/percentage-formatter-six-decimals';
import PercentageFormatterWithTwelveDecimals from 'components/formatter/percentage/percentage-formatter-twelve-decimals';
import ValidCurrencyInput from 'components/inputs/currency-input/valid-currency-input';
import ValidLocalDateInput from 'components/inputs/date-input/valid-local-date-input';
import ValidEstimatedPercentInput from 'entities/batch/batch-session-interest-rate-calculate-recalculate/components/inputs/valid-estimated-percent-input';
import {
    validateCalculateSessionEstimatedPercentage,
    validateCalculateSessionRateBankChargesAmount,
    validateCalculateSessionRateCommissionAmount,
    validateCalculateSessionRateSessionDate,
    validateScdCalculateSessionInterestRate,
} from 'entities/batch/batch-session-interest-rate-calculate-recalculate/validation/batch-session-interest-rate-validation';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { SessionInterestRateCalculationResult } from 'model/enums/session-interest-rate-result';
import { defaultSessionInterestRateRequest, SessionInterestRateRequest } from 'model/session-interest-rates';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { useCalculateSessionInterestRateState, useRootDispatch } from 'reducer/hooks';
import { calculateSessionInterestRateResetState } from 'reducer/session-interest-rate/calculate-session-interest-rate/actions';
import { useToast } from 'shared/hooks/use-toast';
import './batch-session-interest-rate-calculate.scss';
import OutlinedButton from 'components/buttons/outlined-button/outlined-button';
import WarningOutlinedButton from 'components/buttons/warning-outlined-button/warning-outlined-button';
import StandardButton from 'components/buttons/standard-button/standard-button';
import SimpleRadioInput from 'components/inputs/radio-input/simple-radio-input';
import { CessionInterestRateVersion } from 'model/enums/CessionInterestVersion';
import CalculateSessionInterestRateModal from 'entities/batch/batch-session-interest-rate-calculate-recalculate/calculate-session-interest-rate/calculate-session-interest-rate-modal/calculate-session-interest-rate-modal';
import VersionedComponent from 'components/versioned-component';

type ScdBatchSessionInterestCalculateProps = RouteComponentProps<{ batchId: string }>;

const I18N_PREFIX = 'pages.scd.batch.session-interest-rate';

const ScdBatchSessionInterestRateCalculatePage = (props: ScdBatchSessionInterestCalculateProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useRootDispatch();
    const { toastSuccess, toastError } = useToast();

    const [batchId] = useState<number | undefined>(props.match.params.batchId ? Number(props.match.params.batchId) : undefined);
    const [sessionInterestRate, setSessionInterestRate] = useState<SessionInterestRateRequest>();
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [calculateModalOpen, setCalculateModalOpen] = useState<boolean>(false);
    const { sessionInterestRateResponse, status: calculateStatus } = useCalculateSessionInterestRateState();

    const isLoadingRequest = calculateStatus === HttpRequestStatus.ON_GOING;

    useEffect(() => {
        if (!batchId) return;

        setSessionInterestRate(ps => ({ ...ps, batchId, ...defaultSessionInterestRateRequest }));
    }, [batchId]);

    useEffect(() => {
        return () => {
            dispatch(calculateSessionInterestRateResetState());
        };
    }, [dispatch]);

    useEffect(() => {
        return () => {
            setSessionInterestRate(undefined);
        };
    }, []);

    useEffect(() => {
        if (!sessionInterestRate?.estimated?.lowEstimatePercentage && !sessionInterestRate?.estimated?.highEstimatePercentage) {
            setSessionInterestRate(ps => ({ ...ps, estimated: undefined }));
        }
    }, [batchId, sessionInterestRate?.estimated?.lowEstimatePercentage, sessionInterestRate?.estimated?.highEstimatePercentage]);

    useEffect(() => {
        if (
            calculateStatus === HttpRequestStatus.SUCCESS &&
            sessionInterestRateResponse?.result === SessionInterestRateCalculationResult.SUCCESS
        ) {
            toastSuccess(t(`${I18N_PREFIX}.toast.success`));
            history.push(`/batches/${batchId}/session-interest-rate/recalculate`);
        }
    }, [dispatch, calculateStatus, sessionInterestRateResponse?.result, t, history, batchId, toastSuccess]);

    useEffect(() => {
        if (
            calculateStatus === HttpRequestStatus.SUCCESS &&
            sessionInterestRateResponse?.result === SessionInterestRateCalculationResult.FAILURE
        ) {
            toastError(t(`${I18N_PREFIX}.toast.error`));
        }
    }, [dispatch, calculateStatus, sessionInterestRateResponse?.result, t, toastError]);

    const handleChange = (value: Partial<SessionInterestRateRequest>) => {
        setSessionInterestRate(ps => ({ ...ps, ...value }));
    };

    const isNotFailure = sessionInterestRateResponse?.result !== SessionInterestRateCalculationResult.FAILURE;

    const handleCheck = (value: CessionInterestRateVersion) => {
        setSessionInterestRate(ps => ({
            ...ps,
            version: value,
        }));
    };

    const renderCalculateButton = () => {
        switch (sessionInterestRate?.version) {
            case CessionInterestRateVersion.VERSION_1:
                return (
                    <StandardButton
                        label={`${I18N_PREFIX}.input.chooseVersion.calculate.button.options.${CessionInterestRateVersion.VERSION_1}`}
                        onClick={() => setCalculateModalOpen(true)}
                        disabled={!validateScdCalculateSessionInterestRate(sessionInterestRate)}
                    />
                );

            case CessionInterestRateVersion.VERSION_2_PRECISION:
                return (
                    <WarningOutlinedButton
                        label={`${I18N_PREFIX}.input.chooseVersion.calculate.button.options.${CessionInterestRateVersion.VERSION_2_PRECISION}`}
                        onClick={() => setCalculateModalOpen(true)}
                        disabled={!validateScdCalculateSessionInterestRate(sessionInterestRate)}
                    />
                );

            case CessionInterestRateVersion.VERSION_2_PERFORMANCE:
                return (
                    <WarningOutlinedButton
                        label={`${I18N_PREFIX}.input.chooseVersion.calculate.button.options.${CessionInterestRateVersion.VERSION_2_PERFORMANCE}`}
                        onClick={() => setCalculateModalOpen(true)}
                        disabled={!validateScdCalculateSessionInterestRate(sessionInterestRate)}
                    />
                );
            default:
                return (
                    <StandardButton
                        label={'entity.action.calculate'}
                        onClick={() => setCalculateModalOpen(true)}
                        disabled={!validateScdCalculateSessionInterestRate(sessionInterestRate) || !sessionInterestRate?.version}
                    />
                );
        }
    };

    const renderVersionedComponent: JSX.Element = (
        <SimpleRadioInput<CessionInterestRateVersion>
            options={[
                {
                    label: t(`${I18N_PREFIX}.input.chooseVersion.calculate.options.${CessionInterestRateVersion.VERSION_1}`),
                    value: CessionInterestRateVersion.VERSION_1,
                    selectedValue: sessionInterestRate?.version,
                    onChange: value => handleCheck(value),
                },
                {
                    label: t(`${I18N_PREFIX}.input.chooseVersion.calculate.options.${CessionInterestRateVersion.VERSION_2_PRECISION}`),
                    value: CessionInterestRateVersion.VERSION_2_PRECISION,
                    selectedValue: sessionInterestRate?.version,
                    onChange: value => handleCheck(value),
                },
                {
                    label: t(`${I18N_PREFIX}.input.chooseVersion.calculate.options.${CessionInterestRateVersion.VERSION_2_PERFORMANCE}`),
                    value: CessionInterestRateVersion.VERSION_2_PERFORMANCE,
                    selectedValue: sessionInterestRate?.version,
                    onChange: value => handleCheck(value),
                },
            ]}
        />
    );

    return (
        <>
            {isLoadingRequest && <BackDrop open />}
            {sessionInterestRate && (
                <main className="batch-session-interest-calculate">
                    <ContextRibbon action={() => history.push('/batches')} />
                    <article className="batch-session-interest-calculate--left-column">
                        <div className="batch-session-interest-calculate--detail-container">
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className="batch-session-interest-calculate--field">
                                        <label className="batch-session-interest-calculate--label">
                                            {t(`${I18N_PREFIX}.detail.sessionDate`)}
                                        </label>
                                        <span className="batch-session-interest-calculate--text">
                                            {sessionInterestRateResponse?.sessionDate ? (
                                                <DateFormatter date={sessionInterestRateResponse?.sessionDate} />
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </div>
                                    <div className="batch-session-interest-calculate--field">
                                        <label className="batch-session-interest-calculate--label">
                                            {t(`${I18N_PREFIX}.detail.bankChargesAmount`)}
                                        </label>
                                        <span className="batch-session-interest-calculate--text">
                                            {sessionInterestRateResponse?.bankChargesAmount ||
                                            sessionInterestRateResponse?.bankChargesAmount === 0 ? (
                                                <CurrencyFormatter value={sessionInterestRateResponse?.bankChargesAmount} prefix />
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </div>

                                    <div className="batch-session-interest-calculate--field">
                                        <label className="batch-session-interest-calculate--label">
                                            {t(`${I18N_PREFIX}.detail.commissionAmount`)}
                                        </label>
                                        <span className="batch-session-interest-calculate--text">
                                            {sessionInterestRateResponse?.commissionAmount ||
                                            sessionInterestRateResponse?.commissionAmount === 0 ? (
                                                <CurrencyFormatter value={sessionInterestRateResponse?.commissionAmount} prefix />
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className="batch-session-interest-calculate--field">
                                        <label className="batch-session-interest-calculate--label">
                                            {t(`${I18N_PREFIX}.detail.disbursementAmount`)}
                                        </label>
                                        <span className="batch-session-interest-calculate--text">
                                            {sessionInterestRateResponse?.disbursementAmount ||
                                            sessionInterestRateResponse?.disbursementAmount === 0 ? (
                                                <CurrencyFormatter value={sessionInterestRateResponse?.disbursementAmount} prefix />
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </div>
                                    <div className="batch-session-interest-calculate--field">
                                        <label className="batch-session-interest-calculate--label">
                                            {t(`${I18N_PREFIX}.detail.grossAmount`)}
                                        </label>
                                        <span className="batch-session-interest-calculate--text">
                                            {sessionInterestRateResponse?.grossAmount || sessionInterestRateResponse?.grossAmount === 0 ? (
                                                <CurrencyFormatter value={sessionInterestRateResponse?.grossAmount} prefix />
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </div>
                                    <div className="batch-session-interest-calculate--field">
                                        <label className="batch-session-interest-calculate--label">
                                            {t(`${I18N_PREFIX}.detail.iofAmount`)}
                                        </label>
                                        <span className="batch-session-interest-calculate--text">
                                            {sessionInterestRateResponse?.iofAmount || sessionInterestRateResponse?.iofAmount === 0 ? (
                                                <CurrencyFormatter value={sessionInterestRateResponse?.iofAmount} prefix />
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div className="batch-session-interest-calculate--field">
                                    <label className="batch-session-interest-calculate--label">
                                        {t(`${I18N_PREFIX}.detail.exchangePremiumPercentage`)}
                                    </label>
                                    <span className="batch-session-interest-calculate--text">
                                        {sessionInterestRateResponse?.exchangePremiumPercentage ||
                                        sessionInterestRateResponse?.exchangePremiumPercentage === 0 ? (
                                            <PercentageFormatterWithSixDecimals
                                                value={sessionInterestRateResponse?.exchangePremiumPercentage}
                                            />
                                        ) : (
                                            '-'
                                        )}
                                    </span>
                                </div>
                                <div className="batch-session-interest-calculate--field">
                                    <label className="batch-session-interest-calculate--label">
                                        {t(`${I18N_PREFIX}.detail.exchangePremiumAmount`)}
                                    </label>
                                    <span className="batch-session-interest-calculate--text">
                                        {sessionInterestRateResponse?.exchangePremiumAmount ||
                                        sessionInterestRateResponse?.exchangePremiumAmount === 0 ? (
                                            <CurrencyFormatter value={sessionInterestRateResponse?.exchangePremiumAmount} prefix />
                                        ) : (
                                            '-'
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className="batch-session-interest-calculate--field">
                                        <label className="batch-session-interest-calculate--label">
                                            {t(`${I18N_PREFIX}.detail.baseEstimateInterestRatePercentage`)}
                                        </label>
                                        <span className="batch-session-interest-calculate--text">
                                            {sessionInterestRateResponse?.baseEstimateInterestRatePercentage ||
                                            sessionInterestRateResponse?.baseEstimateInterestRatePercentage === 0 ? (
                                                <PercentageFormatterWithSixDecimals
                                                    value={sessionInterestRateResponse?.baseEstimateInterestRatePercentage}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </div>
                                    <div className="batch-session-interest-calculate--field">
                                        <label className="batch-session-interest-calculate--label">
                                            {t(`${I18N_PREFIX}.detail.lowEstimateInterestRatePercentage`)}
                                        </label>
                                        <span className="batch-session-interest-calculate--text">
                                            {sessionInterestRateResponse?.lowEstimateInterestRatePercentage ||
                                            sessionInterestRateResponse?.lowEstimateInterestRatePercentage === 0 ? (
                                                <PercentageFormatterWithSixDecimals
                                                    value={sessionInterestRateResponse?.lowEstimateInterestRatePercentage}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </div>
                                    <div className="batch-session-interest-calculate--field">
                                        <label className="batch-session-interest-calculate--label">
                                            {t(`${I18N_PREFIX}.detail.highEstimateInterestRatePercentage`)}
                                        </label>
                                        <span className="batch-session-interest-calculate--text">
                                            {sessionInterestRateResponse?.highEstimateInterestRatePercentage ||
                                            sessionInterestRateResponse?.highEstimateInterestRatePercentage === 0 ? (
                                                <PercentageFormatterWithSixDecimals
                                                    value={sessionInterestRateResponse?.highEstimateInterestRatePercentage}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className="batch-session-interest-calculate--field">
                                        <label className="batch-session-interest-calculate--label">
                                            {t(`${I18N_PREFIX}.detail.monthlyInterestRatePercentage`)}
                                        </label>
                                        <span className="batch-session-interest-calculate--text">
                                            {sessionInterestRateResponse?.monthlyInterestRatePercentage ||
                                            sessionInterestRateResponse?.monthlyInterestRatePercentage === 0 ? (
                                                <>
                                                    <PercentageFormatterWithTwelveDecimals
                                                        value={sessionInterestRateResponse?.monthlyInterestRatePercentage}
                                                    />
                                                    {isNotFailure && (
                                                        <CopyIcon
                                                            value={sessionInterestRateResponse.monthlyInterestRatePercentage}
                                                            formatter={
                                                                FormatterUtils.formatNumberToPercentageWithTwelveDecimalsWithoutSymbols
                                                            }
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </div>
                                    <div className="batch-session-interest-calculate--field">
                                        <label className="batch-session-interest-calculate--label">
                                            {t(`${I18N_PREFIX}.detail.yearlyInterestRatePercentage`)}
                                        </label>
                                        <span className="batch-session-interest-calculate--text">
                                            {sessionInterestRateResponse?.yearlyInterestRatePercentage ||
                                            sessionInterestRateResponse?.yearlyInterestRatePercentage === 0 ? (
                                                <>
                                                    <PercentageFormatterWithTwelveDecimals
                                                        value={sessionInterestRateResponse?.yearlyInterestRatePercentage}
                                                    />
                                                    {isNotFailure && (
                                                        <CopyIcon
                                                            value={sessionInterestRateResponse.yearlyInterestRatePercentage}
                                                            formatter={
                                                                FormatterUtils.formatNumberToPercentageWithTwelveDecimalsWithoutSymbols
                                                            }
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </div>
                                    <div className="batch-session-interest-calculate--field">
                                        <label className="batch-session-interest-calculate--label">
                                            {t(`${I18N_PREFIX}.detail.result.label`)}
                                        </label>
                                        <span className="batch-session-interest-calculate--text">
                                            {t(`${I18N_PREFIX}.detail.result.${sessionInterestRateResponse?.result}`)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div style={{ display: 'flex' }}>
                                <div className="batch-session-interest-calculate--field">
                                    <label className="batch-session-interest-calculate--label">
                                        {t(`${I18N_PREFIX}.detail.sessionAmount`)}
                                    </label>
                                    <span className="batch-session-interest-calculate--value">
                                        {sessionInterestRateResponse?.sessionAmount || sessionInterestRateResponse?.sessionAmount === 0 ? (
                                            <>
                                                <CurrencyFormatter value={sessionInterestRateResponse?.sessionAmount} prefix />
                                                {isNotFailure && (
                                                    <CopyIcon
                                                        value={sessionInterestRateResponse.sessionAmount}
                                                        formatter={FormatterUtils.formatNumberToCurrency}
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            '-'
                                        )}
                                    </span>
                                </div>
                                <div className="batch-session-interest-calculate--field">
                                    <label className="batch-session-interest-calculate--label">
                                        {t(`${I18N_PREFIX}.detail.sessionPresentValue`)}
                                    </label>
                                    <span className="batch-session-interest-calculate--value">
                                        {sessionInterestRateResponse?.sessionPresentValue ||
                                        sessionInterestRateResponse?.sessionPresentValue === 0 ? (
                                            <CurrencyFormatter value={sessionInterestRateResponse?.sessionPresentValue} prefix />
                                        ) : (
                                            '-'
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </article>
                    <section className="batch-session-interest-calculate--content">
                        <article className="batch-session-interest-calculate--rigth-column">
                            <header className="batch-session-interest-calculate--header">
                                <h2>{t(`${I18N_PREFIX}.calculate.title`)}</h2>
                            </header>
                            <form className="batch-session-interest-calculate--form">
                                <div className="batch-session-interest-calculate--form-field">
                                    <ValidLocalDateInput
                                        value={sessionInterestRate.sessionDate}
                                        label={t(`${I18N_PREFIX}.input.sessionDate.label`)}
                                        placeholder={t(`${I18N_PREFIX}.input.sessionDate.placeholder`)}
                                        validate={validateCalculateSessionRateSessionDate}
                                        onChange={sessionDate => handleChange({ sessionDate })}
                                        showValidation={showValidation}
                                        externalUpdate
                                    />
                                </div>
                                <div className="batch-session-interest-calculate--form-field">
                                    <ValidCurrencyInput
                                        value={sessionInterestRate.commissionAmount}
                                        label={t(`${I18N_PREFIX}.input.commissionAmount.label`)}
                                        placeholder={t(`${I18N_PREFIX}.input.commissionAmount.placeholder`)}
                                        onChange={commissionAmount => handleChange({ commissionAmount })}
                                        showValidation={showValidation}
                                        validate={validateCalculateSessionRateCommissionAmount}
                                        zeroValue
                                        externalUpdate
                                    />
                                </div>
                                <div className="batch-session-interest-calculate--form-field">
                                    <ValidCurrencyInput
                                        value={sessionInterestRate.bankChargesAmount}
                                        label={t(`${I18N_PREFIX}.input.bankChargesAmount.label`)}
                                        placeholder={t(`${I18N_PREFIX}.input.bankChargesAmount.placeholder`)}
                                        onChange={bankChargesAmount => handleChange({ bankChargesAmount })}
                                        showValidation={showValidation}
                                        validate={validateCalculateSessionRateBankChargesAmount}
                                        zeroValue
                                        externalUpdate
                                    />
                                </div>
                                <div className="batch-session-interest-calculate--form-field">
                                    <ValidEstimatedPercentInput
                                        value={sessionInterestRate?.estimated?.lowEstimatePercentage}
                                        label={t(`${I18N_PREFIX}.input.lowEstimatePercentage.label`)}
                                        placeholder={t(`${I18N_PREFIX}.input.lowEstimatePercentage.placeholder`)}
                                        onChange={lowEstimatePercentage =>
                                            handleChange({
                                                estimated: {
                                                    ...sessionInterestRate?.estimated,
                                                    lowEstimatePercentage,
                                                },
                                            })
                                        }
                                        showValidation={showValidation}
                                        validate={() =>
                                            validateCalculateSessionEstimatedPercentage({
                                                lowEstimatePercentage: sessionInterestRate?.estimated?.lowEstimatePercentage,
                                                highEstimatePercentage: sessionInterestRate?.estimated?.highEstimatePercentage,
                                            })
                                        }
                                    />
                                </div>

                                <div className="batch-session-interest-calculate--form-field">
                                    <ValidEstimatedPercentInput
                                        value={sessionInterestRate?.estimated?.highEstimatePercentage}
                                        label={t(`${I18N_PREFIX}.input.highEstimatePercentage.label`)}
                                        placeholder={t(`${I18N_PREFIX}.input.highEstimatePercentage.placeholder`)}
                                        onChange={highEstimatePercentage =>
                                            handleChange({
                                                estimated: {
                                                    ...sessionInterestRate?.estimated,
                                                    highEstimatePercentage,
                                                },
                                            })
                                        }
                                        showValidation={showValidation}
                                        validate={() =>
                                            validateCalculateSessionEstimatedPercentage({
                                                lowEstimatePercentage: sessionInterestRate?.estimated?.lowEstimatePercentage,
                                                highEstimatePercentage: sessionInterestRate?.estimated?.highEstimatePercentage,
                                            })
                                        }
                                    />
                                </div>

                                <div className="batch-session-interest-calculate--form-check">
                                    <VersionedComponent production={<></>} staging={renderVersionedComponent} development={renderVersionedComponent} />
                                </div>
                                <div className="batch-session-interest-calculate--form--buttons">
                                    <Link to="/batches">
                                        <OutlinedButton label="entity.action.cancel" />
                                    </Link>
                                    {renderCalculateButton()}
                                </div>
                            </form>
                        </article>
                    </section>
                    {calculateModalOpen && (
                        <CalculateSessionInterestRateModal
                            setIsModalOpen={setCalculateModalOpen}
                            setShowValidation={setShowValidation}
                            sessionInterestRate={sessionInterestRate}
                        />
                    )}
                </main>
            )}
        </>
    );
};

export default ScdBatchSessionInterestRateCalculatePage;
