import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { useDetailPaymentInstitutionAccountState, useRootDispatch } from 'reducer/hooks';
import {
    detailPaymentInstitutionsAccountsRequest,
    detailPaymentInstitutionsAccountsResetState,
} from 'reducer/payment-institutions-accounts/detail-payment-institution-account/actions';

import './payment-institution-account-detail.scss';

interface PaymentInstitutionAccountDetailProps extends RouteComponentProps<{ paymentInstitutionAccountId: string }> {}

const I18N_PREFIX = 'pages.scd.payment-institutions-accounts';

const PaymentInstitutionAccountDetail = (props: PaymentInstitutionAccountDetailProps) => {
    const { match } = props;

    const [paymentInstitutionAccountId] = useState<string>(match.params.paymentInstitutionAccountId);

    const dispatch = useRootDispatch();

    const { t } = useTranslation();
    const { status, paymentInstitutionsAccount } = useDetailPaymentInstitutionAccountState();

    const isLoading: boolean = status === HttpRequestStatus.ON_GOING;

    useEffect(() => {
        dispatch(detailPaymentInstitutionsAccountsRequest(Number(paymentInstitutionAccountId)));
    }, [dispatch, paymentInstitutionAccountId]);

    useEffect(() => {
        return () => {
            dispatch(detailPaymentInstitutionsAccountsResetState());
        };
    }, [dispatch]);

    return (
        <main className="scd-payment-institutions-accounts__detail">
            <ContextRibbon />
            <div className="scd-payment-institutions-accounts--container">
                <header className="scd-payment-institutions-accounts--header">
                    <h2 className="scd-payment-institutions-accounts--header-title">{t(`${I18N_PREFIX}.detail.title`)}</h2>
                </header>
                <div className="scd-payment-institutions-accounts__detail--container">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField label={`${I18N_PREFIX}.inputs.name.label`} value={paymentInstitutionsAccount?.name} />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.inputs.externalReference.label`}
                                value={paymentInstitutionsAccount?.externalReference ?? '-'}
                            />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.inputs.status.label`}
                                value={t(`${I18N_PREFIX}.inputs.status.options.${paymentInstitutionsAccount?.status}`)}
                            />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.inputs.technicalSupplier.label`}
                                value={paymentInstitutionsAccount?.technicalSupplierName}
                            />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.inputs.technicalSupplier.status.label`}
                                value={t(
                                    `${I18N_PREFIX}.inputs.technicalSupplier.status.options.${paymentInstitutionsAccount?.technicalSupplierStatus}`
                                )}
                            />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.inputs.type.label`}
                                value={t(`${I18N_PREFIX}.inputs.type.options.${paymentInstitutionsAccount?.type}`)}
                            />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.inputs.originator.label`}
                                value={paymentInstitutionsAccount?.originatorName}
                            />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.inputs.issuer.label`}
                                value={paymentInstitutionsAccount?.issuerName}
                            />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.inputs.financialInstitution.label`}
                                value={paymentInstitutionsAccount?.financialInstitutionName}
                            />
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default PaymentInstitutionAccountDetail;
