// BatchClosing ResumeOperations
import { batchClosingResumeOperationsReducer } from 'reducer/batch-closing/batch-operations-resume/reducer';
import batchClosingResumeOperationsBatchSaga from 'reducer/batch-closing/batch-operations-resume/sagas';
import { BatchClosingResumeOperationsState } from 'reducer/batch-closing/batch-operations-resume/types';

// BatchClosing ResumePayments
import { batchClosingResumePaymentsReducer } from 'reducer/batch-closing/batch-payments-resume/reducer';
import batchClosingResumePaymentsSaga from 'reducer/batch-closing/batch-payments-resume/sagas';
import { BatchClosingResumePaymentsState } from 'reducer/batch-closing/batch-payments-resume/types';

// BatchClosing OperationsList
import { batchClosingOperationsListReducer } from 'reducer/batch-closing/operations-list/reducer';
import batchClosingOperationsListSaga from 'reducer/batch-closing/operations-list/sagas';
import { BatchClosingOperationsListState } from 'reducer/batch-closing/operations-list/types';

import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

export interface BatchClosingState {
    readonly resumeOperations: BatchClosingResumeOperationsState;
    readonly operationsList: BatchClosingOperationsListState;
    readonly resumePayments: BatchClosingResumePaymentsState;
}

export const BatchClosingReducer: ReducersMapObject<BatchClosingState, AnyAction> = {
    resumeOperations: batchClosingResumeOperationsReducer,
    operationsList: batchClosingOperationsListReducer,
    resumePayments: batchClosingResumePaymentsReducer
};

export function* batchClosingSagas() {
    yield all([
        fork(batchClosingResumeOperationsBatchSaga),
        fork(batchClosingOperationsListSaga),
        fork(batchClosingResumePaymentsSaga)
    ]);
}
