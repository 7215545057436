import Loading from 'components/loading/loading';
import CessionIntegrationApexRow, {
    CessionIntegrationApexRowProps,
} from 'entities/batch/batch-outputs-detail/components/apex/components/cession-integration-apex-list/cession-integration-apex-row/cession-integration-apex-row';
import { CessionOperationBcoResponse } from 'model/bco/batch-output';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { LIST_HEIGHT, LIST_ITEMS_SIZE } from 'shared/infinite-components/constants';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-apex';

interface ApexListProps {
    cessionOperation: CessionOperationBcoResponse[];
    isLoading: boolean;
}

const CessionIntegrationApexList: FC<ApexListProps> = ({ cessionOperation, isLoading }) => {
    const { t } = useTranslation();
    const CessionIntegrationApexRowObjectData: CessionIntegrationApexRowProps = {
        cessionOperation,
    };

    return (
        <>
            {isLoading && !cessionOperation.length && <Loading isTable />}
            {cessionOperation?.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={CessionIntegrationApexRowObjectData}
                            height={height}
                            width={width}
                            itemSize={LIST_ITEMS_SIZE}
                            itemCount={cessionOperation.length}
                            className="page-container--table-container"
                        >
                            {CessionIntegrationApexRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                !isLoading && (
                    <div className="cession-integration-apex__table-empty-filter">
                        <span className="cession-integration-apex__table-empty-filter-image" />
                        <span className="cession-integration-apex__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                    </div>
                )
            )}
        </>
    );
};

export default CessionIntegrationApexList;
