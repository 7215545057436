import { Grid } from '@material-ui/core';
import { ScdBatchClosingToGetOperation } from 'model/batch-closing';
import { OperationsListPageable } from 'model/reducers';
import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import { IRootState } from 'reducer';
import { batchClosingOperationsListRequest, batchClosingOperationsListResetState } from 'reducer/batch-closing/operations-list/actions';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import { useParams } from 'react-router-dom';
import Loading from 'components/loading/loading';
import './batch-operations-card-list.scss';
import BatchOperationsCardRow from 'entities/batch/batch-closing/components/cards/batch-operations-card/batch-operations-card-row/batch-operations-card-row';
import UseInfiniteScrollWithPageableCollapse from 'shared/infinite-components/infinite-scroll-with-local-data/use-infinite-scroll-with-pageable-collapse';

interface BatchOperationsCardListProps {
    operationListCurrentPage: number;
    setOperationListCurrentPage: Dispatch<SetStateAction<number>>;
}

const BatchOperationsCardList: FC<BatchOperationsCardListProps> = ({ operationListCurrentPage, setOperationListCurrentPage }) => {
    const { batchId } = useParams<{ batchId: string }>();
    const batchIdNumber = Number(batchId);

    const [sortedProperty] = useState<string>('id');
    const [sortOrder] = useState<SortOrder>('desc');

    const sortPageable = useCallback((): OperationsListPageable => {
        const pageable: Pageable = {
            page: operationListCurrentPage,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: sortedProperty === 'id' ? `id,${sortOrder}` : `${sortedProperty},${sortOrder},id,${sortOrder}`,
        };

        return {
            pageable: pageable,
            batchId: batchIdNumber,
        };
    }, [sortedProperty, sortOrder, operationListCurrentPage]);

    const handlePageChange = () => {
        setOperationListCurrentPage(ps => ps + 1);
    };

    const { InfiniteScrollButton, items, isLoading } = UseInfiniteScrollWithPageableCollapse<ScdBatchClosingToGetOperation>({
        handlePageChange,
        dataSelector: (state: IRootState) => state.operationsList.operationsList,
        statusSelector: (state: IRootState) => state.operationsList.status,
        action: batchClosingOperationsListRequest,
        resetStateAction: batchClosingOperationsListResetState,
        filterPageable: sortPageable,
        currentPage: operationListCurrentPage,
        setPage: setOperationListCurrentPage,
    });

    return (
        <>
            {isLoading && <Loading />}
            <Grid className="batch-operations-card-list">
                {items?.map((operation, index, operations) => (
                    <BatchOperationsCardRow key={`${operation.id}-${index}`} operation={operation} operations={operations} index={index} />
                ))}
                <InfiniteScrollButton />
            </Grid>
        </>
    );
};
export default BatchOperationsCardList;
