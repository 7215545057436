import BackButton from 'components/buttons/back-button/back-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ScdProgramPolicyHeader from 'entities/program-policy/components/program-policy-header/program-policy-header';
import ProgramPolicyList from 'entities/program-policy/components/program-policy-list/program-policy-list';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ScdProgramPolicy } from 'model/program-policy';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducer';
import { useRootDispatch } from 'reducer/hooks';
import { listProgramPoliciesRequest } from 'reducer/program-policy/list-program-policies/actions';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import useInfiniteScrollLegacy from 'shared/infinite-components/infinite-scroll-legacy/infinite-scroll-legacy';
import './program-policy.scss';

const I18N_PREFIX = 'pages.scd.program-policy';

const ScdProgramPolicyPage = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState<number>(0);
    const dispatch = useRootDispatch();
    const [sortedProperty, setSortedProperty] = useState<string>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

    const sortPageable = useCallback((): Pageable => {
        return {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: sortedProperty && sortOrder ? `${sortedProperty},${sortOrder}` : '',
        };
    }, [sortedProperty, sortOrder, page]);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };

    const {
        InfiniteScrollButton,
        items,
        resetInfiniteScrollListing,
        isLoading,
        status: listStatus,
    } = useInfiniteScrollLegacy<ScdProgramPolicy>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listProgramPolicies.programPolicies,
        statusSelector: (state: IRootState) => state.listProgramPolicies.status,
        action: listProgramPoliciesRequest,
        filterPageable: sortPageable,
        setPage,
    });

    const handleRefresh = () => {
        dispatch(listProgramPoliciesRequest(sortPageable()));
    };

    const hasError = listStatus === HttpRequestStatus.ERROR;

    const handleSort = (property: keyof ScdProgramPolicy, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    return hasError ? (
        <div className="page__global-error">
            <div className="error-message">{t('global.scd.program-policy.error-message')}</div>
            <BackButton label={t('global.try-again')} onClick={handleRefresh} />
        </div>
    ) : (
        <main className="scd-program-policy">
            <section className="scd-program-policy__container">
                <header className="scd-program-policy__header">
                    <h2 className="scd-program-policy__header--title">
                        {t(`${I18N_PREFIX}.title`)}
                        <RefreshButton onClick={handleRefresh} />
                    </h2>
                </header>
                <div className="scd-program-policy__table">
                    <table className="page-container--table">
                        <thead>
                            <ScdProgramPolicyHeader
                                programPolicies={items}
                                sortOrder={sortOrder}
                                sortedProperty={sortedProperty}
                                handleSort={handleSort}
                            />
                        </thead>
                        <tbody>
                            <ProgramPolicyList programPolicy={items} isLoading={isLoading} />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
            </section>
        </main>
    );
};

export default ScdProgramPolicyPage;
