import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchClosingOperationsListState, BatchClosingOperationsListTypes } from 'reducer/batch-closing/operations-list/types';
import { Reducer } from 'redux';
import {ScdBatchClosingToGetOperation} from "model/batch-closing";

const initialState: BatchClosingOperationsListState = {
    status: HttpRequestStatus.NOOP,
    operationsList: undefined,
    error: undefined,
};

const reducer: Reducer<BatchClosingOperationsListState> = (state = initialState, action): BatchClosingOperationsListState => {
    switch (action.type) {
        case BatchClosingOperationsListTypes.BATCH_CLOSING_OPERATIONS_LIST_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchClosingOperationsListTypes.BATCH_CLOSING_OPERATIONS_LIST_SUCCESS: {
            if (!state.operationsList) {
                return {
                    ...state,
                    status: HttpRequestStatus.SUCCESS,
                    operationsList: action.payload,
                    error: undefined,
                };
            }

            const prevContent: ScdBatchClosingToGetOperation[] = state.operationsList.content || [];
            const newContent: ScdBatchClosingToGetOperation[] = action.payload.content || [];
            const filteredNewData: ScdBatchClosingToGetOperation[] = newContent.filter((newItem: { id: number }) => !prevContent.some(existingItem => existingItem.id === newItem.id));

            const mergedOperationsList = {
                ...action.payload,
                content: [...prevContent, ...filteredNewData],
            };

            return {
                ...state,
                status: HttpRequestStatus.SUCCESS,
                operationsList: mergedOperationsList,
                error: undefined,
            };
        }

        case BatchClosingOperationsListTypes.BATCH_CLOSING_OPERATIONS_LIST_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchClosingOperationsListTypes.BATCH_CLOSING_OPERATIONS_LIST_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as batchClosingOperationsListReducer };
