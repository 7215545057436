import { listBankingCorrespondentReducer } from 'reducer/banking-correspondent/banking-correspondent-list/reducer';
import bankingCorrespondentListSaga from 'reducer/banking-correspondent/banking-correspondent-list/sagas';
import { ListBankingCorrespondentState } from 'reducer/banking-correspondent/banking-correspondent-list/types';
import { createBankingCorrespondentReducer } from 'reducer/banking-correspondent/create-banking-correspondent/reducer';
import createBankingCorrespondentSaga from 'reducer/banking-correspondent/create-banking-correspondent/sagas';
import { CreateBankingCorrespondentState } from 'reducer/banking-correspondent/create-banking-correspondent/types';
import { bankingCorrespondentDetailsReducer } from 'reducer/banking-correspondent/detail-banking-correspondent/reducer';
import bankingCorrespondentDetailsSaga from 'reducer/banking-correspondent/detail-banking-correspondent/sagas';
import { BankingCorrespondentDetailsState } from 'reducer/banking-correspondent/detail-banking-correspondent/types';
import { searchBankingCorrespondentsReducer } from 'reducer/banking-correspondent/search-banking-correspondents/reducer';
import searchBankingCorrespondentsSaga from 'reducer/banking-correspondent/search-banking-correspondents/sagas';
import { SearchBankingCorrespondentsState } from 'reducer/banking-correspondent/search-banking-correspondents/types';
import { updateBankingCorrespondentReducer } from 'reducer/banking-correspondent/update-banking-correspondent/reducer';
import updateBankingCorrespondentSaga from 'reducer/banking-correspondent/update-banking-correspondent/sagas';
import { UpdateBankingCorrespondentState } from 'reducer/banking-correspondent/update-banking-correspondent/types';
import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

export interface BankingCorrespondentState {
    readonly listBankingCorrespondents: ListBankingCorrespondentState;
    readonly detailBankingCorrespondent: BankingCorrespondentDetailsState;
    readonly createBankingCorrespondent: CreateBankingCorrespondentState;
    readonly updateBankingCorrespondent: UpdateBankingCorrespondentState;
    readonly searchBankingCorrespondent: SearchBankingCorrespondentsState;
}

export const BankingCorrespondentReducer: ReducersMapObject<BankingCorrespondentState, AnyAction> = {
    listBankingCorrespondents: listBankingCorrespondentReducer,
    detailBankingCorrespondent: bankingCorrespondentDetailsReducer,
    createBankingCorrespondent: createBankingCorrespondentReducer,
    updateBankingCorrespondent: updateBankingCorrespondentReducer,
    searchBankingCorrespondent: searchBankingCorrespondentsReducer
};

export function* bankingCorrespondentSagas() {
    yield all([fork(bankingCorrespondentListSaga)]);
    yield all([fork(bankingCorrespondentDetailsSaga)]);
    yield all([fork(createBankingCorrespondentSaga)]);
    yield all([fork(updateBankingCorrespondentSaga)]);
    yield all([fork(searchBankingCorrespondentsSaga)]);
}