import { BatchOutputBridgeProps } from 'entities/batch/batch-outputs-detail/bridge/batch-outputs-detail-bridge';
import DisbursementConfirmationApexFooter from 'entities/batch/batch-outputs-detail/components/disbursement-confirmation-apex/components/disbursement-confirmation-apex-footer/disbursement-confirmation-apex-footer';
import { useRefreshStatus } from 'entities/batch/batch-outputs-detail/components/disbursement-confirmation-apex/components/disbursement-confirmation-apex-footer/hooks/useRefreshStatus';
import DisbursementConfirmationListApex from 'entities/batch/batch-outputs-detail/components/disbursement-confirmation-apex/components/disbursment-confirmation-list/disbursement-confirmation-list';
import DisbursementConfirmationIntegrationApexHeader from 'entities/batch/batch-outputs-detail/components/disbursement-confirmation-apex/disbursement-confirmation-apex-header/disbursement-confirmation-apex-header';
import { FC, useEffect } from 'react';
import { useDisbursementConfirmationToResumeBco } from 'reducer-bco/hooks-bco';
import './disbursement-confirmation-apex.scss';
import { disbursementConfirmationToResumeBcoResetState } from 'reducer-bco/batch-output/disbursement-confirmation-to-resume/action';
import { exportRequestSummaryResultsBcoResetState } from 'reducer-bco/batch-output/export-request-summary-results/action';

const DisbursementConfirmationIntegrationApex: FC<BatchOutputBridgeProps> = ({ output, batchWithProgram }) => {
    const { confirmationToResume } = useDisbursementConfirmationToResumeBco();

    const customHandleRefresh = useRefreshStatus();

    useEffect(() => {
        customHandleRefresh();
        return () => {
            disbursementConfirmationToResumeBcoResetState();
            exportRequestSummaryResultsBcoResetState();
        };
    }, [batchWithProgram.id, output.id]);

    return (
        <>
            <DisbursementConfirmationIntegrationApexHeader batchOutputDetails={output} batchWithProgram={batchWithProgram} handleRefresh={customHandleRefresh} />

            <DisbursementConfirmationListApex confirmationToResume={confirmationToResume} batchId={batchWithProgram.id} />

            <DisbursementConfirmationApexFooter handleRefresh={customHandleRefresh} />
        </>
    );
};

export default DisbursementConfirmationIntegrationApex;
