import { useFooterComponents } from 'entities/batch/batch-outputs-detail/components/disbursement-confirmation-apex/components/disbursement-confirmation-apex-footer/hooks/use-footer-components';
import ProcessDisbursementConfirmationModal from 'entities/batch/batch-outputs-detail/components/disbursement-confirmation-apex/components/process-disbursement-confirmation-modal/process-disbursement-confirmation-modal';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { disbursementConfirmationToProcessBcoResetState } from 'reducer-bco/batch-output/disbursement-confirmation-to-process/action';
import { useDisbursementConfirmationToProcessBco } from 'reducer-bco/hooks-bco';
import { useRootDispatch } from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.disbursement-confirmation.table.footer';

interface ApexFooterProps {
    handleRefresh: () => void;
}

const DisbursementConfirmationApexFooter: FC<ApexFooterProps> = ({ handleRefresh }) => {
    const { currentFooter, processModalOpen, setIsProcessModalOpen } = useFooterComponents();

    const dispatch = useRootDispatch();
    const { t } = useTranslation();

    const { status: confirmationToProcessStatus, confirmationToProcess } = useDisbursementConfirmationToProcessBco();

    const { toastLoading, closeToast, toastSuccess, toastError } = useToast();

    useEffect(() => {
        if (confirmationToProcessStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }

        if (confirmationToProcessStatus === HttpRequestStatus.ERROR) {
            toastError(t(`${I18N_PREFIX}.send-disbursement-confirmation.toast.error`));
            dispatch(disbursementConfirmationToProcessBcoResetState());
            closeToast();
        }

        if (confirmationToProcessStatus === HttpRequestStatus.SUCCESS) {
            toastSuccess(t(`${I18N_PREFIX}.send-disbursement-confirmation.toast.success`));
            handleRefresh();
            dispatch(disbursementConfirmationToProcessBcoResetState());
            closeToast();
        }
    }, [confirmationToProcessStatus, confirmationToProcess, toastLoading, closeToast, toastSuccess, t, dispatch]);

    return (
        <>
            {currentFooter}
            {processModalOpen && <ProcessDisbursementConfirmationModal setIsModalOpen={setIsProcessModalOpen} />}
        </>
    );
};

export default DisbursementConfirmationApexFooter;
