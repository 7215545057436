import BackButton from 'components/buttons/back-button/back-button';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import './batch-payments-error.scss';

const I18N_PREFIX = 'pages.scd.batch.close-batch';

interface BatchPaymentsErrorProps {
    handleRetry: () => void;
    errorComponent?: ReactNode;
}

const BatchPaymentsError = (props: BatchPaymentsErrorProps) => {
    const { t } = useTranslation();

    const { handleRetry, errorComponent: ErrorComponent } = props;

    return (
        <div className="batch-payments-error">
            {ErrorComponent}
            <div className="batch-payments-error--container">
                <div className="batch-payments-error--message">{t(`${I18N_PREFIX}.error-message.payments`)}</div>
                <BackButton label={t('global.try-again')} onClick={handleRetry} />
            </div>
        </div>
    );
};

export default BatchPaymentsError;
