import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-webcred.table.header';

interface CessionIntegrationWebcredHeaderListProps {}

export const CessionIntegrationWebcredHeaderList = (props: CessionIntegrationWebcredHeaderListProps) => {
    const { t } = useTranslation();

    return (
        <tr className="page-container--table-head">
            <th className="cession-integration-list-header__table-header">{t(`${I18N_PREFIX}.ccbNumber`).toUpperCase()}</th>
            <th className="cession-integration-list-header__table-header">{t(`${I18N_PREFIX}.status`).toUpperCase()}</th>
        </tr>
    );
};

export default CessionIntegrationWebcredHeaderList;
