import { Pageable } from 'services/pageable';

export interface CessionOperationRequestRequired {
    batchId: string;
    outputId: string;
}

export interface CessionOperationRequest {
    requestRequired: CessionOperationRequestRequired;
    pageable: Pageable;
}

export enum CessionOperationStatus {
    SUCCESS = 'SUCCESS',
    PROCESSING = 'PROCESSING',
    PENDENT = 'PENDENT',
    ERROR = 'ERROR',
}

export enum CessionOutputStatus {
    SUCCESS = 'SUCCESS',
    PROCESSING = 'PROCESSING',
    PENDENT = 'PENDENT',
    ERROR = 'ERROR',
}

export interface CessionOperationBco {
    id: number;
    ccbNumber: string;
    operationId: number;
}

export interface CessionToSimpleDTO {
    id: number;
}
