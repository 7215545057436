import { DisbursementConfirmationSimpleBco } from 'model/bco/batch-output-disbursement-confirmation';
import { BaseStoreState } from 'reducer/base-store-state';

export enum DisbursementConfirmationToProcessBcoTypes {
    DISBURSEMENT_CONFIRMATION_TO_PROCESS_BCO_REQUEST = '@@disbursement-confirmation-to-process-bco/DISBURSEMENT_CONFIRMATION_TO_PROCESS_BCO_REQUEST',
    DISBURSEMENT_CONFIRMATION_TO_PROCESS_BCO_SUCCESS = '@@disbursement-confirmation-to-process-bco/DISBURSEMENT_CONFIRMATION_TO_PROCESS_BCO_SUCCESS',
    DISBURSEMENT_CONFIRMATION_TO_PROCESS_BCO_ERROR = '@@disbursement-confirmation-to-process-bco/DISBURSEMENT_CONFIRMATION_TO_PROCESS_BCO_ERROR',
    DISBURSEMENT_CONFIRMATION_TO_PROCESS_BCO_RESET = '@@disbursement-confirmation-to-process-bco/DISBURSEMENT_CONFIRMATION_TO_PROCESS_BCO_RESET',
}

export interface DisbursementConfirmationToProcessBcoState extends BaseStoreState {
    readonly confirmationToProcess?: DisbursementConfirmationSimpleBco;
}
