import { HttpRequestStatus } from 'model/enums/http-request-status';
import { DisbursementConfirmationToResumeBcoState, DisbursementConfirmationToResumeBcoTypes } from 'reducer-bco/batch-output/disbursement-confirmation-to-resume/types';
import { Reducer } from 'redux';

const initialState: DisbursementConfirmationToResumeBcoState = {
    status: HttpRequestStatus.NOOP,
    confirmationToResume: undefined,
    error: undefined,
};

const reducer: Reducer<DisbursementConfirmationToResumeBcoState> = (state = initialState, action): DisbursementConfirmationToResumeBcoState => {
    switch (action.type) {
        case DisbursementConfirmationToResumeBcoTypes.DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case DisbursementConfirmationToResumeBcoTypes.DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, confirmationToResume: action.payload, error: undefined };
        }
        case DisbursementConfirmationToResumeBcoTypes.DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case DisbursementConfirmationToResumeBcoTypes.DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as DisbursementConfirmationToResumeBcoReducer };
