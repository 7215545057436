import { DisbursementConfirmationToRequest, DisbursementConfirmationToResumeBco } from 'model/bco/batch-output-disbursement-confirmation';
import { GiroWebappError } from 'model/error';
import { DisbursementConfirmationToResumeBcoTypes } from 'reducer-bco/batch-output/disbursement-confirmation-to-resume/types';
import { action } from 'typesafe-actions';

export const disbursementConfirmationToResumeBcoRequest = (request: DisbursementConfirmationToRequest) =>
    action(DisbursementConfirmationToResumeBcoTypes.DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_REQUEST, request);
export const disbursementConfirmationToResumeBcoSuccess = (disbursementConfirmationToResumeBco: DisbursementConfirmationToResumeBco) =>
    action(DisbursementConfirmationToResumeBcoTypes.DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_SUCCESS, disbursementConfirmationToResumeBco);
export const disbursementConfirmationToResumeBcoError = (error: GiroWebappError) =>
    action(DisbursementConfirmationToResumeBcoTypes.DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_ERROR, error);
export const disbursementConfirmationToResumeBcoResetState = () => action(DisbursementConfirmationToResumeBcoTypes.DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_RESET);
