import InputUtils from 'components/inputs/input-utils';
import { SessionInterestRateCalculationResult } from 'model/enums/session-interest-rate-result';
import { CessionInterestRateVersion } from 'model/enums/CessionInterestVersion';

export interface SessionInterestRateRequest {
    batchId?: number;
    sessionDate?: string;
    commissionAmount?: number;
    bankChargesAmount?: number;
    estimated?: {
        lowEstimatePercentage?: number;
        highEstimatePercentage?: number;
    };
    version?: CessionInterestRateVersion;
}

export const defaultSessionInterestRateRequest: SessionInterestRateRequest = {
    sessionDate: InputUtils.formatToLocalDateISO(new Date()),
    commissionAmount: 0,
    bankChargesAmount: 0,
    version: CessionInterestRateVersion.VERSION_1, // TODO: PROVISIONAL DEFAULT VALUE
};

export interface SessionInterestRateResponse {
    result?: SessionInterestRateCalculationResult;
    sessionDate?: Date;
    commissionAmount?: number;
    bankChargesAmount?: number;
    exchangePremiumPercentage?: number;
    grossAmount?: number;
    disbursementAmount?: number;
    iofAmount?: number;
    exchangePremiumAmount?: number;
    sessionAmount?: number;
    baseEstimateInterestRatePercentage?: number;
    lowEstimateInterestRatePercentage?: number;
    highEstimateInterestRatePercentage?: number;
    monthlyInterestRatePercentage?: number;
    yearlyInterestRatePercentage?: number;
    sessionPresentValue?: number;
}
