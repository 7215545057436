export enum PaymentTransactionType {
    MANUAL = 'MANUAL',
    DISBURSEMENT_BY_BATCH = 'DISBURSEMENT_BY_BATCH',
    DISBURSEMENT_TO_DEBTOR = 'DISBURSEMENT_TO_DEBTOR',
    MONTHLY_COMMISSION = 'MONTHLY_COMMISSION',
    MANUAL_DISBURSEMENT = 'MANUAL_DISBURSEMENT',
    AMENDED_DISBURSEMENT = 'AMENDED_DISBURSEMENT',
    INTERNAL_REPAYMENT = 'INTERNAL_REPAYMENT',
    EXTERNAL_DISBURSEMENT = 'EXTERNAL_DISBURSEMENT'
}
