import clsx from 'clsx';
import { BatchOutputBridgeProps } from 'entities/batch/batch-outputs-detail/bridge/batch-outputs-detail-bridge';
import CessionIntegrationApexHeader from 'entities/batch/batch-outputs-detail/components/apex/cession-integration-apex-header/cession-integration-apex-header';
import CessionIntegrationApexFooter from 'entities/batch/batch-outputs-detail/components/apex/components/cession-integration-apex-footer/cession-integration-apex-footer';
import CessionIntegrationApexHeaderList from 'entities/batch/batch-outputs-detail/components/apex/components/cession-integration-apex-list/cession-integration-apex-header-list/cession-integration-apex-header-list';
import CessionIntegrationApexList from 'entities/batch/batch-outputs-detail/components/apex/components/cession-integration-apex-list/cession-integration-apex-list';
import { useRefreshCessionOperationStatus } from 'entities/batch/batch-outputs-detail/components/webcred/hooks/useRefreshCessionOperationStatus';
import { CessionOperationBcoRequest, CessionOperationBcoResponse, ExportRequestSummaryResultsRequestRequired } from 'model/bco/batch-output';
import { ExportRequestType } from 'model/bco/enums/batch-output-cession-integration-gtsec';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IRootState } from 'reducer';
import { listCessionOperationBcoRequest, listCessionOperationBcoResetState } from 'reducer-bco/batch-output/cession-operation/action';
import { exportRequestSummaryResultsBcoRequest } from 'reducer-bco/batch-output/export-request-summary-results/action';
import { useCessionToResumeBco, useProcessCessionOperationBco } from 'reducer-bco/hooks-bco';
import { useRootDispatch } from 'reducer/hooks';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import useInfiniteScroll from 'shared/infinite-components/infinite-scroll/infinite-scroll';
import './cession-integration-apex.scss';

type SortableProperties = keyof Pick<CessionOperationBcoResponse, 'id' | 'ccbNumber'>;

const CessionIntegrationApex: FC<BatchOutputBridgeProps> = ({ output, batchWithProgram }) => {
    const { batchId, outputId } = useParams<{ batchId: string; outputId: string }>();
    const [page, setPage] = useState<number>(0);
    const [sortedProperty] = useState<SortableProperties>('id');
    const [sortOrder] = useState<SortOrder>('desc');
    const dispatch = useRootDispatch();

    const { cessionToResume, status: cessionToResumeStatus } = useCessionToResumeBco();
    const isLoadingCessionSuccessful = cessionToResumeStatus === HttpRequestStatus.SUCCESS;

    const customHandleRefresh = useRefreshCessionOperationStatus();

    const exportRequestSummaryData = useCallback((): ExportRequestSummaryResultsRequestRequired => {
        return {
            outputId: Number(outputId),
            batchId: Number(batchId),
            type: ExportRequestType.CESSION_OPERATION,
        };
    }, [outputId, batchId]);

    const { processCessionOperation } = useProcessCessionOperationBco();

    useEffect(() => {
        dispatch(exportRequestSummaryResultsBcoRequest(exportRequestSummaryData()));
    }, [processCessionOperation, dispatch]);

    const filterPageable = useCallback((): CessionOperationBcoRequest => {
        const _pageable: Pageable = {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: `${sortedProperty},${sortOrder}`,
        };

        return {
            pageable: _pageable,
            requestRequired: { batchId, outputId },
        };
    }, [sortedProperty, sortOrder, page]);

    const handlePageChange = () => {
        setPage(page + 1);
    };

    const {
        InfiniteScrollButton,
        items: cessionOperations,
        isLoading,
    } = useInfiniteScroll<CessionOperationBcoResponse>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listCessionOperation.listCessionOperation,
        statusSelector: (state: IRootState) => state.listCessionOperation.status,
        action: listCessionOperationBcoRequest,
        filterPageable,
        setPage,
    });

    useEffect(() => {
        return () => {
            dispatch(listCessionOperationBcoResetState());
        };
    }, [dispatch]);

    return (
        <>
            <CessionIntegrationApexHeader
                batchOutputDetails={output}
                batchWithProgram={batchWithProgram}
                handleRefresh={customHandleRefresh}
                cessionToResume={cessionToResume}
                isLoadingCessionToResume={!isLoadingCessionSuccessful}
            />

            <div className="cession-integration-apex">
                <div className="cession-integration-apex__table">
                    <table
                        className={clsx('page-container--table', {
                            EMPTY_COLLECTION: !cessionOperations.length,
                        })}
                    >
                        <thead>
                            <CessionIntegrationApexHeaderList />
                        </thead>
                        <tbody>
                            <CessionIntegrationApexList cessionOperation={cessionOperations} isLoading={isLoading} />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
            </div>
            <CessionIntegrationApexFooter handleRefresh={customHandleRefresh} />
        </>
    );
};

export default CessionIntegrationApex;
