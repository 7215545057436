import FormControl from '@material-ui/core/FormControl';
import { RadioGroup } from '@material-ui/core';
import BaseRadioInput, { BaseRadioInputProps } from 'components/inputs/radio-input/base-radio-input';

export interface SimpleRadioInputProps<T extends string = string> {
    options: BaseRadioInputProps<T>[];
}

const SimpleRadioInput = <T extends string = string>({ options }: SimpleRadioInputProps<T>) => {
    return (
        <FormControl fullWidth>
            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                {options.map(({ color, ...otherProps }, index) => (
                    <BaseRadioInput<T> key={`${otherProps.label}-${index}`} {...otherProps} color={color ?? 'primary'} />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default SimpleRadioInput;
