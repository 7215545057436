import { AxiosResponse } from 'axios';
import { BankingCorrespondent } from 'model/banking-correspondent';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { searchBankingCorrespondentsError, searchBankingCorrespondentsRequest, searchBankingCorrespondentsSuccess } from 'reducer/banking-correspondent/search-banking-correspondents/actions';
import { SearchBankingCorrespondentTypes } from 'reducer/banking-correspondent/search-banking-correspondents/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import bankingCorrespondentApi from 'services/api/banking-correspondent-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleSearchBankingCorrespondents(action: ReturnType<typeof searchBankingCorrespondentsRequest>) {
    try {
        const result: AxiosResponse<Page<BankingCorrespondent>> = yield call(bankingCorrespondentApi.searchBankingCorrespondents, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchBankingCorrespondentsError(mapped));
            return;
        }
        yield put(searchBankingCorrespondentsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchBankingCorrespondentsError(mapped));
    }
}

function* watchSearchBankingCorrespondents() {
    yield takeEvery(SearchBankingCorrespondentTypes.SEARCH_BANKING_CORRESPONDENTS_REQUEST, handleSearchBankingCorrespondents);
}

function* searchBankingCorrespondentsSaga() {
    yield all([fork(watchSearchBankingCorrespondents)]);
}

export default searchBankingCorrespondentsSaga;
