
import { isAfter, isSameDay, isValid, parseISO } from "date-fns";

export function isDueDateExpired(currentDate: Date | string | undefined, dueDate: Date | string | undefined): boolean {
    if (!currentDate || !dueDate) return false;

    const current = typeof currentDate === "string" ? parseISO(currentDate) : currentDate;
    const due = typeof dueDate === "string" ? parseISO(dueDate) : dueDate;

    if (!isValid(current) || !isValid(due)) return false;

    return isAfter(current, due);
}

export function isDueDateToday(currentDate: Date | string | undefined, dueDate: Date | string | undefined): boolean {
    if (!currentDate || !dueDate) return false;

    const current = typeof currentDate === "string" ? parseISO(currentDate) : currentDate;
    const due = typeof dueDate === "string" ? parseISO(dueDate) : dueDate;

    if (!isValid(current) || !isValid(due)) return false;

    return isSameDay(current, due);
}