import { AxiosResponse } from 'axios';
import { DisbursementConfirmationToResumeBco } from 'model/bco/batch-output-disbursement-confirmation';
import HttpStatus from 'model/enums/http-status';
import { disbursementConfirmationToResumeBcoError, disbursementConfirmationToResumeBcoRequest, disbursementConfirmationToResumeBcoSuccess } from 'reducer-bco/batch-output/disbursement-confirmation-to-resume/action';
import { DisbursementConfirmationToResumeBcoTypes } from 'reducer-bco/batch-output/disbursement-confirmation-to-resume/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchOutputBcoApi from 'services/bco/batch-outputs-bco';
import ErrorUtils from 'shared/error/error-utils';

function* handleDisbursementConfirmationToResumeBco(action: ReturnType<typeof disbursementConfirmationToResumeBcoRequest>) {
    try {
        const result: AxiosResponse<DisbursementConfirmationToResumeBco> = yield call(batchOutputBcoApi.disbursementConfirmationToResume, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(disbursementConfirmationToResumeBcoError(mapped));
            return;
        }
        yield put(disbursementConfirmationToResumeBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(disbursementConfirmationToResumeBcoError(mapped));
    }
}

function* watchDisbursementConfirmationToResumeBcoSaga() {
    yield takeEvery(DisbursementConfirmationToResumeBcoTypes.DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_REQUEST, handleDisbursementConfirmationToResumeBco);
}

function* disbursementConfirmationToResumeBcoSaga() {
    yield all([fork(watchDisbursementConfirmationToResumeBcoSaga)]);
}

export default disbursementConfirmationToResumeBcoSaga;
