import MenuItem from '@material-ui/core/MenuItem';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ValidIdentificationInput from 'components/inputs/identification-input/valid-identification-input';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import ValidSelectInput from 'components/inputs/select-input/valid-select-input';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import SimpleTextNumberInput from 'components/inputs/text-number-input/simple-text-number-input';
import ValidTextNumberInput from 'components/inputs/text-number-input/valid-text-number-input';
import Loading from 'components/loading/loading';
import { SEARCH_ITEM_SIZE, SEARCH_ITEM_SIZE_LARGE } from 'components/modals/constants';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ModalSearch from 'components/modals/modal-search/modal-search';
import ValidInternalReferenceInput from 'entities/technical-supplier/technical-supplier-create-update/inputs/valid-internal-reference-input';
import {
    validateDebtorAccount,
    validateDebtorAccountBankId,
    validateDebtorAccountDigit,
    validateDebtorAccountNumber,
    validateDebtorAccountType,
    validateDebtorAgencyNumber,
    validateDebtorBankAccountType,
    validateDebtorFavoredIdentification,
    validateDebtorFavoredName,
} from 'entities/technical-supplier/validation/debtor-account-validation';
import {
    validateScdTechnicalSupplier,
    validateTechnicalSupplierAccountType,
    validateTechnicalSupplierFinancialInstitution,
    validateTechnicalSupplierInternalReference,
    validateTechnicalSupplierName,
    validateTechnicalSupplierType,
} from 'entities/technical-supplier/validation/technical-supplier-validation';
import { Bank } from 'model/bank';
import { BankAccountType } from 'model/enums/bank-account-type';
import { DebtorAccountType } from 'model/enums/debtor-account-type';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { TechnicalSupplierAccountType } from 'model/enums/technical-supplier-account-type';
import { TechnicalSupplierStatus } from 'model/enums/technical-supplier-status';
import { TechnicalSupplierType } from 'model/enums/technical-supplier-type';
import { FinancialInstitutionSearch } from 'model/financial-institution';
import { ScdTechnicalSupplier, defaultScdTechnicalSupplier } from 'model/technical-supplier';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import { searchBanksRequest, searchBanksResetState } from 'reducer/bank/search-bank/actions';
import {
    searchFinancialInstituionsRequest,
    searchFinancialInstituionsResetState,
} from 'reducer/financial-institution/search-financial-institutions/actions';
import {
    useCreateTechnicalSupplierState,
    useDetailTechnicalSupplierState,
    useRootDispatch,
    useUpdateTechnicalSupplierState,
} from 'reducer/hooks';
import {
    createTechnicalSupplierRequest,
    createTechnicalSupplierResetState,
} from 'reducer/technical-supplier/create-technical-supplier/actions';
import {
    detailTechnicalSupplierRequest,
    detailTechnicalSupplierResetState,
} from 'reducer/technical-supplier/detail-technical-supplier/actions';
import {
    updateTechnicalSupplierRequest,
    updateTechnicalSupplierResetState,
} from 'reducer/technical-supplier/update-technical-supplier/actions';
import { useToast } from 'shared/hooks/use-toast';
import './technical-supplier-create-update.scss';

export interface ScdTechnicalSupplierUpdateProps extends RouteComponentProps<{ technicalSupplierId: string }> {}

const I18N_PREFIX = 'pages.scd.technical-supplier';

const ScdTechnicalSupplierCreateUpdatePage = (props: ScdTechnicalSupplierUpdateProps) => {
    const { t } = useTranslation();
    const [technicalSupplierId] = useState<number | undefined>(Number(props.match?.params?.technicalSupplierId) ?? undefined);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);
    const { toastSuccess } = useToast();

    const { status: createStatus } = useCreateTechnicalSupplierState();
    const { status: updateStatus } = useUpdateTechnicalSupplierState();
    const { status: detailStatus, technicalSupplier: detailTechnicalSupplier } = useDetailTechnicalSupplierState();

    const [technicalSupplier, setTechnicalSupplier] = useState<ScdTechnicalSupplier>(defaultScdTechnicalSupplier);
    const [currentStatus, setCurrentStatus] = useState<TechnicalSupplierStatus | undefined>(undefined);
    const [isOpenModalWarning, setIsOpenModalWarning] = useState<boolean>(false);
    const [isFinancialInstitutionPersisted, setIsFinancialInstitutionPersisted] = useState<boolean>(false);

    const dispatch = useRootDispatch();
    const history = useHistory();

    const handleUpdateTechnicalSupplier = useCallback(
        (_technicalSupplier: ScdTechnicalSupplier) => {
            if (currentStatus === TechnicalSupplierStatus.ACTIVE && currentStatus !== technicalSupplier.status) {
                setIsOpenModalWarning(true);
                return;
            }

            dispatch(updateTechnicalSupplierRequest(_technicalSupplier));
        },
        [currentStatus, dispatch, technicalSupplier.status]
    );

    const handleSave = () => {
        setShowValidation(true);

        const _technicalSupplier: ScdTechnicalSupplier = {
            ...technicalSupplier,
        };

        if (technicalSupplier.type === TechnicalSupplierType.PAYMENT_INSTITUTION) {
            if (!validateDebtorAccount(_technicalSupplier.debtorAccount)) return;
        }

        if (!validateScdTechnicalSupplier(_technicalSupplier)) return;

        if (technicalSupplierId) {
            handleUpdateTechnicalSupplier(_technicalSupplier);
        } else {
            dispatch(createTechnicalSupplierRequest(_technicalSupplier));
        }
    };

    const handleChange = (value: Partial<ScdTechnicalSupplier>) => {
        setTechnicalSupplier(ps => ({ ...ps, ...value }));
    };

    useEffect(() => {
        if (technicalSupplierId) {
            dispatch(detailTechnicalSupplierRequest(technicalSupplierId));
        }
    }, [technicalSupplierId, dispatch]);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && detailTechnicalSupplier !== undefined) {
            setTechnicalSupplier({
                ...detailTechnicalSupplier,
                debtorAccount: { ...detailTechnicalSupplier.debtorAccount, bankId: detailTechnicalSupplier.debtorAccount?.bank?.id },
                financialInstitutionId: detailTechnicalSupplier.financialInstitution?.id,
                financialInstitutionName: detailTechnicalSupplier.financialInstitution?.name,
            });
            setCurrentStatus(detailTechnicalSupplier.status);
            setIsFinancialInstitutionPersisted(Boolean(detailTechnicalSupplier.financialInstitution));
        }
    }, [detailStatus, detailTechnicalSupplier]);

    useEffect(() => {
        if (updateStatus === HttpRequestStatus.SUCCESS) {
            toastSuccess(t(`${I18N_PREFIX}.toast-success.edit`));
            history.push('/technical-suppliers');
        }
    }, [updateStatus, toastSuccess, t, history]);

    useEffect(() => {
        if (createStatus === HttpRequestStatus.SUCCESS) {
            toastSuccess(t(`${I18N_PREFIX}.toast-success.create`));
            history.push('/technical-suppliers');
        }
    }, [createStatus, toastSuccess, t, history]);

    useEffect(() => {
        if (technicalSupplier?.type === TechnicalSupplierType.ACCOUNTING_INSTITUTION) {
            setTechnicalSupplier(ps => ({ ...ps, debtorAccount: defaultScdTechnicalSupplier.debtorAccount }));
        }
        if (technicalSupplier?.type === TechnicalSupplierType.PAYMENT_INSTITUTION) {
            setTechnicalSupplier(ps => ({ ...ps, debtorAccount: { ...ps.debtorAccount, type: DebtorAccountType.BANK_ACCOUNT } }));
        }
    }, [technicalSupplier?.type]);

    useEffect(() => {
        return () => {
            dispatch(createTechnicalSupplierResetState());
            dispatch(updateTechnicalSupplierResetState());
            dispatch(detailTechnicalSupplierResetState());
        };
    }, [dispatch]);

    const loading = detailStatus !== HttpRequestStatus.SUCCESS;

    return (
        <main className="scd-tecnical-supplier__create">
            <ContextRibbon />
            <div className="scd-tecnical-supplier--container">
                <header className="scd-tecnical-supplier--header">
                    <h2 className="scd-tecnical-supplier--header-title">
                        {!technicalSupplierId ? t(`${I18N_PREFIX}.create.title`) : t(`${I18N_PREFIX}.edit.title`)}
                    </h2>
                </header>
                {loading && technicalSupplierId ? (
                    <Loading />
                ) : (
                    <form className="scd-tecnical-supplier--form">
                        <div className="scd-tecnical-supplier--form-field">
                            <ValidTextInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.name.label`)}
                                value={technicalSupplier?.name ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.name.placeholder`)}
                                onChange={name => handleChange({ name })}
                                validate={validateTechnicalSupplierName}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-tecnical-supplier--form-field">
                            <ValidInternalReferenceInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.internalReference.label`)}
                                value={technicalSupplier?.internalReference ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.internalReference.placeholder`)}
                                onChange={internalReference => handleChange({ internalReference })}
                                validate={validateTechnicalSupplierInternalReference}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-tecnical-supplier--form-field">
                            <ModalSearch<FinancialInstitutionSearch>
                                action={searchFinancialInstituionsRequest}
                                itemSize={SEARCH_ITEM_SIZE}
                                modalTitle={t(`${I18N_PREFIX}.input.financialInstitution.modal.title`)}
                                modalLabel={t(`${I18N_PREFIX}.input.financialInstitution.modal.label`)}
                                modalPlaceholder={t(`${I18N_PREFIX}.input.financialInstitution.modal.placeholder`)}
                                onSelect={financialInstitution =>
                                    handleChange({
                                        financialInstitutionId: financialInstitution.id,
                                        financialInstitutionName: financialInstitution.name,
                                    })
                                }
                                renderItem={financialInstitution => financialInstitution.name}
                                statusSelector={(state: IRootState) => state.searchFinancialInstitutions.status}
                                dataSelector={(state: IRootState) => state.searchFinancialInstitutions.financialInstituions}
                                resetState={searchFinancialInstituionsResetState}
                            >
                                {handleOpen => (
                                    <ValidSearchInput
                                        label={`${I18N_PREFIX}.input.financialInstitution.label`}
                                        showValidation={showValidation}
                                        placeholder={`${I18N_PREFIX}.input.financialInstitution.placeholder`}
                                        validate={validateTechnicalSupplierFinancialInstitution}
                                        value={technicalSupplier.financialInstitutionName ?? ''}
                                        onClick={handleOpen}
                                        onFocus={handleOpen}
                                        disabled={Boolean(technicalSupplierId && isFinancialInstitutionPersisted)}
                                        readOnly
                                        externalUpdate
                                    />
                                )}
                            </ModalSearch>
                        </div>
                        <div className="scd-tecnical-supplier--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.type.label`)}
                                value={technicalSupplier?.type ?? ''}
                                validate={validateTechnicalSupplierType}
                                mapperFromString={_value => (_value ? TechnicalSupplierType[_value] : undefined)}
                                showValidation={showValidation}
                                placeholder={t(`${I18N_PREFIX}.input.type.placeholder`)}
                                onChange={(type: string) => handleChange({ type: TechnicalSupplierType[type] })}
                                disabled={!!technicalSupplierId}
                                externalUpdate
                            >
                                <MenuItem value={TechnicalSupplierType.ACCOUNTING_INSTITUTION}>
                                    {t(`${I18N_PREFIX}.input.type.options.ACCOUNTING_INSTITUTION`)}
                                </MenuItem>
                                <MenuItem value={TechnicalSupplierType.PAYMENT_INSTITUTION}>
                                    {t(`${I18N_PREFIX}.input.type.options.PAYMENT_INSTITUTION`)}
                                </MenuItem>
                            </ValidSelectInput>
                        </div>
                        {technicalSupplier?.type === TechnicalSupplierType.PAYMENT_INSTITUTION && (
                            <>
                                <div className="scd-tecnical-supplier--form-field">
                                    <ModalSearch<Bank>
                                        action={searchBanksRequest}
                                        itemSize={SEARCH_ITEM_SIZE_LARGE}
                                        modalTitle={t(`${I18N_PREFIX}.input.debtorAccount.bankId.title`)}
                                        modalLabel={t(`${I18N_PREFIX}.input.debtorAccount.bankId.label`)}
                                        modalPlaceholder={t(`${I18N_PREFIX}.input.debtorAccount.bankId.placeholder`)}
                                        onSelect={bank => {
                                            handleChange({
                                                debtorAccount: { ...technicalSupplier.debtorAccount, bank, bankId: bank.id },
                                            });
                                        }}
                                        renderItem={bank => `${bank.number} - ${bank.name}`}
                                        statusSelector={(state: IRootState) => state.searchBanks.status}
                                        dataSelector={(state: IRootState) => state.searchBanks.banks}
                                        resetState={searchBanksResetState}
                                    >
                                        {handleOpen => (
                                            <ValidSearchInput
                                                value={
                                                    technicalSupplier.debtorAccount?.bank?.name &&
                                                    technicalSupplier.debtorAccount?.bank?.number
                                                        ? `${technicalSupplier.debtorAccount?.bank?.number} - ${technicalSupplier.debtorAccount?.bank?.name}`
                                                        : ''
                                                }
                                                label={t(`${I18N_PREFIX}.input.debtorAccount.bankId.label`)}
                                                placeholder={t(`${I18N_PREFIX}.input.debtorAccount.bankId.placeholder`)}
                                                validate={() => validateDebtorAccountBankId(technicalSupplier.debtorAccount?.bank)}
                                                showValidation={showValidation}
                                                onClick={handleOpen}
                                                onFocus={handleOpen}
                                                readOnly
                                                externalUpdate
                                            />
                                        )}
                                    </ModalSearch>
                                </div>

                                <div className="scd-tecnical-supplier--form-field">
                                    <ValidTextNumberInput
                                        type="text"
                                        label={t(`${I18N_PREFIX}.input.debtorAccount.accountNumber.label`)}
                                        value={technicalSupplier?.debtorAccount?.accountNumber ?? ''}
                                        placeholder={t(`${I18N_PREFIX}.input.debtorAccount.accountNumber.placeholder`)}
                                        onChange={accountNumber =>
                                            handleChange({ debtorAccount: { ...technicalSupplier.debtorAccount, accountNumber } })
                                        }
                                        validate={validateDebtorAccountNumber}
                                        showValidation={showValidation}
                                        externalUpdate
                                    />
                                </div>
                                <div className="scd-tecnical-supplier--form-field">
                                    <ValidTextNumberInput
                                        type="text"
                                        label={t(`${I18N_PREFIX}.input.debtorAccount.accountDigit.label`)}
                                        value={technicalSupplier?.debtorAccount?.accountDigit ?? ''}
                                        placeholder={t(`${I18N_PREFIX}.input.debtorAccount.accountDigit.placeholder`)}
                                        onChange={accountDigit =>
                                            handleChange({ debtorAccount: { ...technicalSupplier.debtorAccount, accountDigit } })
                                        }
                                        validate={validateDebtorAccountDigit}
                                        showValidation={showValidation}
                                        externalUpdate
                                    />
                                </div>
                                <div className="scd-tecnical-supplier--form-field">
                                    <ValidTextNumberInput
                                        type="text"
                                        label={t(`${I18N_PREFIX}.input.debtorAccount.agencyNumber.label`)}
                                        value={technicalSupplier?.debtorAccount?.agencyNumber ?? ''}
                                        placeholder={t(`${I18N_PREFIX}.input.debtorAccount.agencyNumber.placeholder`)}
                                        onChange={agencyNumber =>
                                            handleChange({ debtorAccount: { ...technicalSupplier.debtorAccount, agencyNumber } })
                                        }
                                        validate={validateDebtorAgencyNumber}
                                        showValidation={showValidation}
                                        externalUpdate
                                    />
                                </div>
                                <div className="scd-tecnical-supplier--form-field">
                                    <SimpleTextNumberInput
                                        type="text"
                                        label={t(`${I18N_PREFIX}.input.debtorAccount.agencyDigit.label`)}
                                        value={technicalSupplier?.debtorAccount?.agencyDigit ?? ''}
                                        placeholder={t(`${I18N_PREFIX}.input.debtorAccount.agencyDigit.placeholder`)}
                                        onChange={agencyDigit =>
                                            handleChange({ debtorAccount: { ...technicalSupplier.debtorAccount, agencyDigit } })
                                        }
                                        externalUpdate
                                    />
                                </div>
                                <div className="scd-tecnical-supplier--form-field">
                                    <ValidSelectInput
                                        label={t(`${I18N_PREFIX}.input.accountType.label`)}
                                        value={technicalSupplier?.accountType ?? ''}
                                        validate={validateTechnicalSupplierAccountType}
                                        mapperFromString={_value => (_value ? TechnicalSupplierAccountType[_value] : undefined)}
                                        showValidation={
                                            technicalSupplier.type === TechnicalSupplierType.PAYMENT_INSTITUTION ? showValidation : false
                                        }
                                        placeholder={t(`${I18N_PREFIX}.input.accountType.placeholder`)}
                                        onChange={(accountType: string) =>
                                            handleChange({ accountType: TechnicalSupplierAccountType[accountType] })
                                        }
                                        externalUpdate
                                    >
                                        <MenuItem value={TechnicalSupplierAccountType.UNIQUE}>
                                            {t(`${I18N_PREFIX}.input.accountType.options.UNIQUE`)}
                                        </MenuItem>
                                        <MenuItem value={TechnicalSupplierAccountType.SEGREGATED}>
                                            {t(`${I18N_PREFIX}.input.accountType.options.SEGREGATED`)}
                                        </MenuItem>
                                    </ValidSelectInput>
                                </div>
                                <div className="scd-tecnical-supplier--form-field">
                                    <ValidTextInput
                                        type="text"
                                        label={t(`${I18N_PREFIX}.input.debtorAccount.favoredName.label`)}
                                        value={technicalSupplier?.debtorAccount?.favoredName ?? ''}
                                        placeholder={t(`${I18N_PREFIX}.input.debtorAccount.favoredName.placeholder`)}
                                        onChange={favoredName =>
                                            handleChange({ debtorAccount: { ...technicalSupplier.debtorAccount, favoredName } })
                                        }
                                        validate={validateDebtorFavoredName}
                                        showValidation={showValidation}
                                        externalUpdate
                                    />
                                </div>
                                <div className="scd-tecnical-supplier--form-field">
                                    <ValidIdentificationInput
                                        label={t(`${I18N_PREFIX}.input.debtorAccount.favoredIdentification.label`)}
                                        value={technicalSupplier?.debtorAccount?.favoredIdentification ?? ''}
                                        placeholder={t(`${I18N_PREFIX}.input.debtorAccount.favoredIdentification.placeholder`)}
                                        onChange={favoredIdentification =>
                                            handleChange({ debtorAccount: { ...technicalSupplier.debtorAccount, favoredIdentification } })
                                        }
                                        validate={validateDebtorFavoredIdentification}
                                        showValidation={showValidation}
                                        externalUpdate
                                    />
                                </div>
                                <div className="scd-tecnical-supplier--form-field">
                                    <ValidSelectInput
                                        label={t(`${I18N_PREFIX}.input.debtorAccount.type.label`)}
                                        value={technicalSupplier?.debtorAccount?.type ?? ''}
                                        validate={validateDebtorAccountType}
                                        mapperFromString={_value => (_value ? DebtorAccountType[_value] : undefined)}
                                        showValidation={showValidation}
                                        placeholder={t(`${I18N_PREFIX}.input.debtorAccount.type.placeholder`)}
                                        onChange={(accountType: string) =>
                                            handleChange({
                                                debtorAccount: { ...technicalSupplier.debtorAccount, type: DebtorAccountType[accountType] },
                                            })
                                        }
                                        disabled
                                        externalUpdate
                                    >
                                        <MenuItem selected value={DebtorAccountType.BANK_ACCOUNT}>
                                            {t(`${I18N_PREFIX}.input.debtorAccount.type.options.BANK_ACCOUNT`)}
                                        </MenuItem>
                                        <MenuItem value={DebtorAccountType.DIGITAL_ACCOUNT}>
                                            {t(`${I18N_PREFIX}.input.debtorAccount.type.options.DIGITAL_ACCOUNT`)}
                                        </MenuItem>
                                    </ValidSelectInput>
                                </div>
                                <div className="scd-tecnical-supplier--form-field">
                                    <ValidSelectInput
                                        label={t(`${I18N_PREFIX}.input.debtorAccount.bankAccountType.label`)}
                                        value={technicalSupplier?.debtorAccount?.bankAccountType ?? ''}
                                        validate={validateDebtorBankAccountType}
                                        mapperFromString={_value => (_value ? BankAccountType[_value] : undefined)}
                                        showValidation={showValidation}
                                        placeholder={t(`${I18N_PREFIX}.input.debtorAccount.bankAccountType.placeholder`)}
                                        onChange={(accountType: string) =>
                                            handleChange({
                                                debtorAccount: {
                                                    ...technicalSupplier.debtorAccount,
                                                    bankAccountType: BankAccountType[accountType],
                                                },
                                            })
                                        }
                                        externalUpdate
                                    >
                                        <MenuItem value={BankAccountType.CHECKING_ACCOUNT}>
                                            {t(`${I18N_PREFIX}.input.debtorAccount.bankAccountType.options.CHECKING_ACCOUNT`)}
                                        </MenuItem>
                                        <MenuItem value={BankAccountType.SAVINGS_ACCOUNT}>
                                            {t(`${I18N_PREFIX}.input.debtorAccount.bankAccountType.options.SAVINGS_ACCOUNT`)}
                                        </MenuItem>
                                    </ValidSelectInput>
                                </div>
                            </>
                        )}
                        {!!technicalSupplierId && (
                            <div className="scd-tecnical-supplier--form-field">
                                <ValidSelectInput
                                    label={t(`${I18N_PREFIX}.input.status.label`)}
                                    value={technicalSupplier?.status ?? ''}
                                    validate={validateTechnicalSupplierAccountType}
                                    mapperFromString={_value => (_value ? TechnicalSupplierStatus[_value] : undefined)}
                                    showValidation={showValidation}
                                    placeholder={t(`${I18N_PREFIX}.input.status.placeholder`)}
                                    onChange={(status: string) => handleChange({ status: TechnicalSupplierStatus[status] })}
                                    externalUpdate
                                >
                                    <MenuItem value={TechnicalSupplierStatus.ACTIVE}>
                                        {t(`${I18N_PREFIX}.input.status.options.ACTIVE`)}
                                    </MenuItem>
                                    <MenuItem value={TechnicalSupplierStatus.INACTIVE}>
                                        {t(`${I18N_PREFIX}.input.status.options.INACTIVE`)}
                                    </MenuItem>
                                </ValidSelectInput>
                            </div>
                        )}

                        <div className="scd-tecnical-supplier--form-buttons">
                            <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                            <StandardButtonLegacy label={t('entity.action.save')} onClick={handleSave} />
                        </div>
                    </form>
                )}
            </div>
            {modalCancel && (
                <ModalMessageLegacy
                    title={technicalSupplierId ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.push('/technical-suppliers');
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
            {isOpenModalWarning && (
                <ModalMessageLegacy
                    title={t(`${I18N_PREFIX}.modal.warning.title`)}
                    message={t(`${I18N_PREFIX}.modal.warning.message`)}
                    onCancel={() => setIsOpenModalWarning(false)}
                    onClose={() => setIsOpenModalWarning(false)}
                    onAction={() => {
                        setIsOpenModalWarning(false);
                        dispatch(updateTechnicalSupplierRequest({ ...technicalSupplier }));
                    }}
                />
            )}
        </main>
    );
};

export default ScdTechnicalSupplierCreateUpdatePage;
