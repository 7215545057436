import clsx from 'clsx';
import { CessionOperationBcoResponse, CessionOperationStatusRequest } from 'model/bco/batch-output';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';
import {
    cessionOperationStatusBcoRequest,
    cessionOperationStatusBcoResetState,
} from 'reducer-bco/batch-output/cession-operation-status/action';
import { useCessionOperationStatusBco } from 'reducer-bco/hooks-bco';
import { useRootDispatch } from 'reducer/hooks';
import BalanceLoading from 'shared/external/payment-accounts-strategy/balance/components/balance-loading/balance-loading';

export interface CessionIntegrationMateraRowProps {
    cessionOperation: CessionOperationBcoResponse[];
}

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-matera.table.row';

const CessionIntegrationMateraRow = (props: ListChildComponentProps<CessionIntegrationMateraRowProps>) => {
    const { index, data, style } = props;
    const dispatch = useRootDispatch();
    const { batchId, outputId } = useParams<{ batchId: string; outputId: string }>();

    const { cessionOperation } = data;

    const { id, ccbNumber } = cessionOperation[index];

    const filter: CessionOperationStatusRequest = {
        filter: { batchId, outputId, cessionOperationId: id.toString() },
    };

    const { cessionOperationStatus } = useCessionOperationStatusBco();
    const { t } = useTranslation();
    const isLoading: boolean = cessionOperationStatus?.[id]?.status === HttpRequestStatus.ON_GOING;

    const handleCessionOperationStatusBcoRequest = useCallback(() => {
        dispatch(cessionOperationStatusBcoRequest(filter));
    }, [dispatch, filter]);

    useEffect(() => {
        if (isLoading) return;
        if (cessionOperationStatus?.[id]) return;

        handleCessionOperationStatusBcoRequest();
    }, []);

    useEffect(() => {
        return () => {
            dispatch(cessionOperationStatusBcoResetState());
        };
    }, []);

    const cessionOperationStatusModel = cessionOperationStatus?.[id]?.cessionOperationStatusModel?.status;

    return (
        <tr className={clsx('page-container--table-cell', {})} key={id} style={style}>
            <td className="page-container--table-cell-title">{id}</td>
            <td className="page-container--table-cell-title">{ccbNumber}</td>
            <td className="page-container--table-cell-title">
                {isLoading ? (
                    <BalanceLoading />
                ) : (
                    <span className={clsx('page-container--table-cell-status', cessionOperationStatusModel)}>
                        {t(`${I18N_PREFIX}.status.options.${cessionOperationStatusModel}`)}
                    </span>
                )}
            </td>
        </tr>
    );
};

export default CessionIntegrationMateraRow;
