import { BatchClosingResumePayments } from 'model/batch-closing';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchClosingResumePaymentsTypes {
    BATCH_CLOSING_RESUME_PAYMENTS_REQUEST = '@@batch-closing-resume-payments/BATCH_CLOSING_RESUME_PAYMENTS_REQUEST',
    BATCH_CLOSING_RESUME_PAYMENTS_SUCCESS = '@@batch-closing-resume-payments/BATCH_CLOSING_RESUME_PAYMENTS_SUCCESS',
    BATCH_CLOSING_RESUME_PAYMENTS_ERROR = '@@batch-closing-resume-payments/BATCH_CLOSING_RESUME_PAYMENTS_ERROR',
    BATCH_CLOSING_RESUME_PAYMENTS_RESET = '@@batch-closing-resume-payments/BATCH_CLOSING_RESUME_PAYMENTS_RESET',
}

export interface BatchClosingResumePaymentsState extends BaseStoreState {
    readonly resumePayments?: BatchClosingResumePayments;
}
