import { ScdProgramToCreateUpdate } from 'model/program';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.program.validate';

const PROGRAM_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.name.maxLength`,
    },
};
export const validateProgramName = (value: string | undefined | null) => ValidationUtils.validate(value, PROGRAM_NAME_VALIDATION);

const PROGRAM_IDENTIFIER_VALIDATION: Validations<string | undefined | null> = {
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.identifier.maxLength`,
    },
};
export const validateProgramIdentifier = (value: string | undefined | null) =>
    ValidationUtils.validate(value, PROGRAM_IDENTIFIER_VALIDATION);

const PROGRAM_STATUS_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.status.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.status.required`,
    },
};
export const validateProgramStatus = (value: string | undefined | null) => ValidationUtils.validate(value, PROGRAM_STATUS_VALIDATION);

const PROGRAM_GOODWILL_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty,
        errorMessage: `${I18N_PREFIX}.goodwill.required`,
    },
    minSize: {
        predicate: ValidationUtils.NUMBER.minSize(0.00001),
        errorMessage: `${I18N_PREFIX}.goodwill.required`,
    },
};
export const validateProgramGoodwill = (value: number | undefined | null) => ValidationUtils.validate(value, PROGRAM_GOODWILL_VALIDATION);

const PROGRAM_PRODUCT_TYPE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.productType.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.productType.required`,
    },
};
export const validateProgramProductType = (value: string | undefined | null) =>
    ValidationUtils.validate(value, PROGRAM_PRODUCT_TYPE_VALIDATION);

const PROGRAM_ORGANIZATION_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.organization.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.organization.required`,
    },
};
export const validateProgramOrganization = (value: string | undefined | null) =>
    ValidationUtils.validate(value, PROGRAM_ORGANIZATION_VALIDATION);

const PROGRAM_ORIGINATOR_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.originator.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.originator.required`,
    },
};
export const validateProgramOriginator = (value: string | undefined | null) =>
    ValidationUtils.validate(value, PROGRAM_ORIGINATOR_VALIDATION);

const PROGRAM_ISSUER_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.issuer.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.issuer.required`,
    },
};

export const validateProgramIssuer = (value: string | undefined | null) => ValidationUtils.validate(value, PROGRAM_ISSUER_VALIDATION);

const PROGRAM_FINANCIAL_INSTITUTION_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.financialInstitution.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.financialInstitution.required`,
    },
};
export const validateProgramFinancialInstitution = (value: string | undefined | null) =>
    ValidationUtils.validate(value, PROGRAM_FINANCIAL_INSTITUTION_VALIDATION);

export const validateScdProgram = (value: ScdProgramToCreateUpdate | undefined): boolean => {
    if (!value) return true;

    return (
        validateProgramName(value.name).isValid &&
        validateProgramIdentifier(value.identifier).isValid &&
        validateProgramGoodwill(value.goodwill).isValid &&
        validateProgramProductType(value.productType).isValid &&
        validateProgramOrganization(value.organizationName).isValid &&
        validateProgramStatus(value.status).isValid &&
        validateProgramOriginator(value.originatorName).isValid &&
        validateProgramIssuer(value.issuerName).isValid &&
        validateProgramFinancialInstitution(value.financialInstitutionName).isValid
    );
};
