import { Pageable } from 'services/pageable';

export interface BankingCorrespondent {
    id?: number;
    name?: string;
    identifier?: string;
    identification?: string;
}

export interface BankingCorrespondentDetails {
    id: number;
    name: string;
    identifier: string;
    identification: string;
}

export interface BankingCorrespondentPageable {
    pageable?: Pageable;
}

export interface BankingCorrespondentToSimple {
    id?: number;
}

export interface BankingCorrespondentToCreateUpdate {
    id?: number;
    name?: string;
    identifier?: string;
    identification?: string;
}


export const defaultBankingCorrespondent: BankingCorrespondentToCreateUpdate = {
    id: undefined,
    name: undefined,
    identifier: undefined,
    identification: undefined
};

export interface BankingCorrespondentToGetOriginator {
    id?: string;
    name?: string;
    identification?: string;
}

export interface BankingCorrespondentSearch {
    id?: number;
    name?: string;
    identification?: string;
    identifier?: string;
}

export interface BankingCorrespondentToGetProgram {
    id?: number;
    name?: string;
    identification?: string;
}