import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// List payment transactions
import { listPaymentTransactionsReducer } from 'reducer/payment-transaction/list-payment-transactions/reducer';
import listPaymentTransactionsSaga from 'reducer/payment-transaction/list-payment-transactions/sagas';
import { ListPaymentTransactionsState } from 'reducer/payment-transaction/list-payment-transactions/types';

// Create payment transaction
import { createPaymentTransactionReducer } from 'reducer/payment-transaction/create-payment-transaction/reducer';
import createPaymentTransactionSaga from 'reducer/payment-transaction/create-payment-transaction/sagas';
import { CreatePaymentTransactionState } from 'reducer/payment-transaction/create-payment-transaction/types';

// Archive payment transactions
import { archivePaymentTransactionsReducer } from 'reducer/payment-transaction/archive-payment-transactions/reducer';
import archivePaymentTransactionsSaga from 'reducer/payment-transaction/archive-payment-transactions/sagas';
import { ArchivePaymentTransactionsState } from 'reducer/payment-transaction/archive-payment-transactions/types';

// Legacy Process payment transactions
import { legacyProcessPaymentTransactionsReducer } from 'reducer/payment-transaction/legacy-process-payment-transactions/reducer';
import legacyProcessPaymentTransactionsSaga from 'reducer/payment-transaction/legacy-process-payment-transactions/sagas';
import { LegacyProcessPaymentTransactionsState } from 'reducer/payment-transaction/legacy-process-payment-transactions/types';

// New pending payment transactions
import { listPendingPaymentTransactionsReducer } from 'reducer/payment-transaction/list-pending-payment-transactions/reducer';
import listPendingPaymentTransactionsSaga from 'reducer/payment-transaction/list-pending-payment-transactions/sagas';
import { ListPendingPaymentTransactionsState } from 'reducer/payment-transaction/list-pending-payment-transactions/types';

// Summarize payment transactions
import { summarizePaymentTransactionsReducer } from 'reducer/payment-transaction/summarize-payment-transactions/reducer';
import summarizePaymentTransactionsSaga from 'reducer/payment-transaction/summarize-payment-transactions/sagas';
import { SummarizePaymentTransactionsState } from 'reducer/payment-transaction/summarize-payment-transactions/types';

// Process Manual dispatch payment transactions
import { processPaymentTransactionsReducer } from 'reducer/payment-transaction/process-payment-transactions/reducer';
import processPaymentTransactionsSaga from 'reducer/payment-transaction/process-payment-transactions/sagas';
import { ProcessPaymentTransactionsState } from 'reducer/payment-transaction/process-payment-transactions/types';

// Detail payment transaction
import { detailPaymentTransactionReducer } from 'reducer/payment-transaction/detail-payment-transaction/reducer';
import detailPaymentTransactionSaga from 'reducer/payment-transaction/detail-payment-transaction/sagas';
import { DetailPaymentTransactionState } from 'reducer/payment-transaction/detail-payment-transaction/types';

// Manual disbursement payment transaction
import { manualDisbursementPaymentTransactionReducer } from 'reducer/payment-transaction/manual-disbursement-payment-transaction/reducer';
import manualDisbursementPaymentTransactionSaga from 'reducer/payment-transaction/manual-disbursement-payment-transaction/sagas';
import { ManualDisbursementPaymentTransactionState } from 'reducer/payment-transaction/manual-disbursement-payment-transaction/types';

// Validate manual disbursement
import { validateManualDisbursementPaymentTransactionReducer } from 'reducer/payment-transaction/validate-manual-disbursement-payment-transaction/reducer';
import validateManualDisbursementPaymentTransactionSaga from 'reducer/payment-transaction/validate-manual-disbursement-payment-transaction/sagas';
import { ValidateManualDisbursementPaymentTransactionState } from 'reducer/payment-transaction/validate-manual-disbursement-payment-transaction/types';

// Create internal repayment
import { createInternalRepaymentPaymentTransactionReducer } from 'reducer/payment-transaction/create-internal-repayment-payment-transaction/reducer';
import createRepaymentPaymentTransactionSaga from 'reducer/payment-transaction/create-internal-repayment-payment-transaction/sagas';
import { CreateInternalRepaymentPaymentTransactionState } from 'reducer/payment-transaction/create-internal-repayment-payment-transaction/types';

// Repayment Account
import { internalRepaymentAccountPaymentTransactionReducer } from 'reducer/payment-transaction/internal-repayment-account-payment-transaction/reducer';
import internalRepaymentAccountPaymentTransactionSaga from 'reducer/payment-transaction/internal-repayment-account-payment-transaction/sagas';
import { InternalRepaymentAccountPaymentTransactionState } from 'reducer/payment-transaction/internal-repayment-account-payment-transaction/types';

//Confirm External Transaction
import { confirmExternalTransactionReducer } from 'reducer/payment-transaction/confirm-external-transaction/reducer';
import confirmExternalTransactionSaga from 'reducer/payment-transaction/confirm-external-transaction/sagas';
import { ConfirmExternalTransactionState } from 'reducer/payment-transaction/confirm-external-transaction/types';

export interface PaymentTransactionState {
    readonly listPaymentTransactions: ListPaymentTransactionsState
    readonly createPaymentTransaction: CreatePaymentTransactionState;
    readonly archivePaymentTransactions: ArchivePaymentTransactionsState;
    readonly legacyProcessPaymentTransactions: LegacyProcessPaymentTransactionsState;
    readonly listPendingPaymentTransactions: ListPendingPaymentTransactionsState;
    readonly summarizePaymentTransactions: SummarizePaymentTransactionsState;
    readonly processPaymentTransactions: ProcessPaymentTransactionsState;
    readonly detailPaymentTransaction: DetailPaymentTransactionState;
    readonly manualDisbursementPaymentTransaction: ManualDisbursementPaymentTransactionState;
    readonly validateManualDisbursement: ValidateManualDisbursementPaymentTransactionState;
    readonly createInternalRepaymentPaymentTransaction: CreateInternalRepaymentPaymentTransactionState;
    readonly internalRepaymentAccountPaymentTransaction: InternalRepaymentAccountPaymentTransactionState;
    readonly confirmExternalTransaction: ConfirmExternalTransactionState;
}

export const PaymentTransactionReducer: ReducersMapObject<PaymentTransactionState, AnyAction> = {
    listPaymentTransactions: listPaymentTransactionsReducer,
    createPaymentTransaction: createPaymentTransactionReducer,
    archivePaymentTransactions: archivePaymentTransactionsReducer,
    legacyProcessPaymentTransactions: legacyProcessPaymentTransactionsReducer,
    listPendingPaymentTransactions: listPendingPaymentTransactionsReducer,
    summarizePaymentTransactions: summarizePaymentTransactionsReducer,
    processPaymentTransactions: processPaymentTransactionsReducer,
    detailPaymentTransaction: detailPaymentTransactionReducer,
    manualDisbursementPaymentTransaction: manualDisbursementPaymentTransactionReducer,
    validateManualDisbursement: validateManualDisbursementPaymentTransactionReducer,
    createInternalRepaymentPaymentTransaction: createInternalRepaymentPaymentTransactionReducer,
    internalRepaymentAccountPaymentTransaction: internalRepaymentAccountPaymentTransactionReducer,
    confirmExternalTransaction: confirmExternalTransactionReducer
};

export function* paymentTransactionSagas() {
    yield all([
        fork(listPaymentTransactionsSaga),
        fork(createPaymentTransactionSaga),
        fork(archivePaymentTransactionsSaga),
        fork(legacyProcessPaymentTransactionsSaga),
        fork(listPendingPaymentTransactionsSaga),
        fork(summarizePaymentTransactionsSaga),
        fork(processPaymentTransactionsSaga),
        fork(detailPaymentTransactionSaga),
        fork(manualDisbursementPaymentTransactionSaga),
        fork(validateManualDisbursementPaymentTransactionSaga),
        fork(createRepaymentPaymentTransactionSaga),
        fork(internalRepaymentAccountPaymentTransactionSaga),
        fork(confirmExternalTransactionSaga)
    ]);
}
