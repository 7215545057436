import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchClosingResumePaymentsState, BatchClosingResumePaymentsTypes } from 'reducer/batch-closing/batch-payments-resume/types';
import { Reducer } from 'redux';

const initialState: BatchClosingResumePaymentsState = {
    status: HttpRequestStatus.NOOP,
    resumePayments: undefined,
    error: undefined,
};

const reducer: Reducer<BatchClosingResumePaymentsState> = (state = initialState, action): BatchClosingResumePaymentsState => {
    switch (action.type) {
        case BatchClosingResumePaymentsTypes.BATCH_CLOSING_RESUME_PAYMENTS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchClosingResumePaymentsTypes.BATCH_CLOSING_RESUME_PAYMENTS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, resumePayments: action.payload, error: undefined };
        }
        case BatchClosingResumePaymentsTypes.BATCH_CLOSING_RESUME_PAYMENTS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchClosingResumePaymentsTypes.BATCH_CLOSING_RESUME_PAYMENTS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as batchClosingResumePaymentsReducer };
