import ContextRibbon from 'components/context-ribbon/context-ribbon';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter from 'components/formatter/date/date-formatter';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import Loading from 'components/loading/loading';
import FavoredCard from 'entities/payment-transaction/payment-transaction-detail/components/favored-card/favored-card';
import ManualDisbursementForm from 'entities/payment-transaction/payment-transaction-detail/components/manual-disbursement-form/manual-disbursement-form';
import OriginatorCard from 'entities/payment-transaction/payment-transaction-detail/components/originator-card/originator-card';
import PaymentAccountRecipientCard from 'entities/payment-transaction/payment-transaction-detail/components/payment-account-recipient-card/payment-account-recipient-card';
import PaymentInstitutionAccountCard from 'entities/payment-transaction/payment-transaction-detail/components/payment-institution-account-card/payment-institution-account-card';
import PaymentInstitutionCard from 'entities/payment-transaction/payment-transaction-detail/components/payment-institution-card/payment-institution-card';
import PaymentValueCard from 'entities/payment-transaction/payment-transaction-detail/components/payment-value-card/payment-value-card';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PaymentTransactionStatus } from 'model/enums/payment-transaction-status';
import { PaymentTransactionType } from 'model/enums/payment-transaction-type';
import { PaymentTransactionToManualDisbursement } from 'model/payment-transaction';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useDetailPaymentTransactionState, useRootDispatch } from 'reducer/hooks';
import {
    detailPaymentTransactionRequest,
    detailPaymentTransactionResetState,
} from 'reducer/payment-transaction/detail-payment-transaction/actions';

import ExternalTransactionConfirmation from 'entities/payment-transaction/payment-transaction-detail/components/external-transaction-confirmation/external-transaction-confirmation';
import PaymentTransactionFailureCard from 'entities/payment-transaction/payment-transaction-detail/components/payment-transaction-failure-card/payment-transaction-failure-card';
import TransactionFailureCategorizationCard from 'entities/payment-transaction/payment-transaction-detail/components/transaction-failure-categorization-card/transaction-failure-categorization-card';
import './payment-transaction-detail.scss';

type PaymentTransactionDetailProps = RouteComponentProps<{ paymentTransactionId: string }>;

const I18N_PREFIX = 'pages.scd.payment-transaction.detail';

const PaymentTransactionDetail = (props: PaymentTransactionDetailProps) => {
    const { match } = props;

    const [paymentTransactionId] = useState<string | undefined>(match.params.paymentTransactionId);
    const [paymentTransactionToManualDisbursement, setPaymentTransactionToManualDisbursement] = useState<
        PaymentTransactionToManualDisbursement | undefined
    >(undefined);

    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { status, paymentTransaction } = useDetailPaymentTransactionState();

    const isManualDisbursementAvailable: boolean =
        paymentTransaction?.status === PaymentTransactionStatus.ERROR &&
        (paymentTransaction?.type === PaymentTransactionType.MANUAL_DISBURSEMENT ||
            paymentTransaction?.type === PaymentTransactionType.DISBURSEMENT_TO_DEBTOR);

    const handleDetailPaymentTransaction = useCallback(() => {
        if (!paymentTransactionId) return;

        dispatch(detailPaymentTransactionRequest(paymentTransactionId));
    }, [dispatch, paymentTransactionId]);

    useEffect(() => {
        handleDetailPaymentTransaction();
    }, [handleDetailPaymentTransaction]);

    useEffect(() => {
        return () => {
            dispatch(detailPaymentTransactionResetState());
        };
    }, [dispatch]);

    const isDetailsLoading: boolean = status === HttpRequestStatus.ON_GOING;

    return (
        <main className="scd-payment-transaction__detail">
            <ContextRibbon />
            <section className="scd-payment-transaction__detail--column-section">
                {isDetailsLoading ? (
                    <Loading />
                ) : (
                    <div className="scd-payment-transaction__detail--left-container">
                        <header className="scd-payment-transaction__detail--header">
                            <h2 className="scd-payment-transaction__detail--header-title">{t(`${I18N_PREFIX}.title`)}</h2>
                        </header>
                        <div className="scd-payment-transaction__detail--container">
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.inputs.date`)}
                                value={<DateFormatter date={paymentTransaction?.date} />}
                            />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.inputs.amount`}
                                value={<CurrencyFormatter value={paymentTransaction?.amount} prefix />}
                            />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.inputs.status.label`}
                                value={t(`${I18N_PREFIX}.inputs.status.options.${paymentTransaction?.status}`)}
                            />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.inputs.type.label`}
                                value={t(`${I18N_PREFIX}.inputs.type.options.${paymentTransaction?.type}`)}
                            />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.inputs.method.label`}
                                value={t(`${I18N_PREFIX}.inputs.method.options.${paymentTransaction?.method}`)}
                            />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.inputs.mode.label`}
                                value={t(`${I18N_PREFIX}.inputs.mode.options.${paymentTransaction?.mode}`)}
                            />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.inputs.dispatchMode.label`}
                                value={t(`${I18N_PREFIX}.inputs.dispatchMode.options.${paymentTransaction?.dispatchMode}`)}
                            />

                            <ReadOnlyTextField label={`${I18N_PREFIX}.inputs.description`} value={paymentTransaction?.description ?? '-'} />
                            <PaymentAccountRecipientCard paymentAccountRecipient={paymentTransaction?.paymentAccountRecipient} />
                            <FavoredCard paymentFavored={paymentTransaction?.paymentFavored} />
                            <OriginatorCard originator={paymentTransaction?.originator} />
                            <PaymentInstitutionAccountCard paymentInstitutionAccount={paymentTransaction?.paymentInstitutionAccount} />
                            <PaymentTransactionFailureCard paymentTransactionFailure={paymentTransaction?.paymentTransactionFailure} />
                            <TransactionFailureCategorizationCard
                                transactionFailureCategorization={paymentTransaction?.transactionFailureCategorization}
                            />
                            <PaymentValueCard paymentValue={paymentTransaction?.paymentValue} />
                            <PaymentInstitutionCard paymentInstitution={paymentTransaction?.paymentInstitution} />
                        </div>
                    </div>
                )}

                {isManualDisbursementAvailable && (
                    <ManualDisbursementForm
                        paymentTransaction={paymentTransaction}
                        paymentTransactionToManualDisbursement={paymentTransactionToManualDisbursement}
                        setPaymentTransactionToManualDisbursement={setPaymentTransactionToManualDisbursement}
                    />
                )}
                {paymentTransaction && <ExternalTransactionConfirmation paymentTransaction={paymentTransaction} />}
            </section>
        </main>
    );
};

export default PaymentTransactionDetail;
