import BatchCollateralsCard from 'entities/batch/batch-closing/components/cards/batch-operation-collateral-card/batch-collaterals-card';
import BatchOperationsCard from 'entities/batch/batch-closing/components/cards/batch-operations-card/batch-operations-card';
import BatchPaymentsCard from 'entities/batch/batch-closing/components/cards/batch-payments-card/batch-payments-card';
import { OperationCollateralPolicy } from 'model/enums/operation-collateral-policy';
import { ScdProgramPaymentFavoredPolicy } from 'model/enums/program-payment-favored-policy 2';
import { ScdProgramPaymentModePolicy } from 'model/enums/program-payment-mode-policy-type';
import { useBatchClosingResumePaymentsState, useBatchDetailWithProgramState } from 'reducer/hooks';

const BatchProcessComponents = () => {
    const { batchWithProgram } = useBatchDetailWithProgramState();
    const { resumePayments } = useBatchClosingResumePaymentsState();

    if (!batchWithProgram?.id) return null;

    const programPolicy = batchWithProgram.program?.policy;
    const operationsWithPaymentError = (resumePayments && resumePayments?.numberOfOperationsWithPaymentError) ?? 0;

    const showCollateralsCard = programPolicy?.operationCollateralPolicy == OperationCollateralPolicy.ACTIVE_VALIDATION;

    const showPaymentsCard =
        programPolicy?.paymentModePolicy === ScdProgramPaymentModePolicy.AUTOMATIC &&
        programPolicy.paymentFavoredPolicy === ScdProgramPaymentFavoredPolicy.DIRECT;

    const showBatchPaymentCard = showPaymentsCard && Boolean(operationsWithPaymentError);

    return (
        <>
            <BatchOperationsCard batchId={batchWithProgram?.id} />
            {showCollateralsCard && <BatchCollateralsCard batchId={batchWithProgram?.id} />}
            {showBatchPaymentCard && <BatchPaymentsCard batchId={batchWithProgram.id} />}
        </>
    );
};

export default BatchProcessComponents;
