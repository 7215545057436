import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Subtitle from '@material-ui/icons/Subtitles';
import clsx from 'clsx';
import { PaymentTransactionType } from 'model/enums/payment-transaction-type';
import React, { KeyboardEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './payment-types-legend.scss';

interface PaymentTransactionSubtitleProps {}

const I18N_PREFIX = 'pages.scd.payment-transaction.filter.input';
const PaymentTransactionTypesLegend: React.FC<PaymentTransactionSubtitleProps> = () => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const { t } = useTranslation();
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const handleListKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    // DEPRECATED: MONTHLY_COMMISSION will be removed in the future
    const typeList: PaymentTransactionType[] = Object.values(PaymentTransactionType).filter(
        type => type !== PaymentTransactionType.MONTHLY_COMMISSION
    );

    return (
        <>
            <button
                className="payment-type-subtitle__button"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Subtitle />
                {t('pages.scd.payment-transaction.legend')}
            </button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 10 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            zIndex: 10,
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {typeList.map(type => (
                                        <div key={type} className="payment-type-subtitle__subtitle-menu">
                                            <div
                                                className={clsx('payment-type-subtitle__subtitle-menu__subtitle-color', {
                                                    MANUAL: type === PaymentTransactionType.MANUAL,
                                                    DISBURSEMENT_BY_BATCH: type === PaymentTransactionType.DISBURSEMENT_BY_BATCH,
                                                    DISBURSEMENT_TO_DEBTOR: type === PaymentTransactionType.DISBURSEMENT_TO_DEBTOR,
                                                    MANUAL_DISBURSEMENT: type === PaymentTransactionType.MANUAL_DISBURSEMENT,
                                                    AMENDED_DISBURSEMENT: type === PaymentTransactionType.AMENDED_DISBURSEMENT,
                                                    INTERNAL_REPAYMENT: type === PaymentTransactionType.INTERNAL_REPAYMENT,
                                                    EXTERNAL_DISBURSEMENT : type === PaymentTransactionType.EXTERNAL_DISBURSEMENT,
                                                })}
                                            />
                                            {t(`${I18N_PREFIX}.type.options.${type}`).toLocaleUpperCase()}
                                        </div>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
export default PaymentTransactionTypesLegend;
