import { ExportRequestType } from 'model/bco/enums/batch-output-cession-integration-gtsec';
import { useParams } from 'react-router-dom';
import { disbursementConfirmationToResumeBcoRequest } from 'reducer-bco/batch-output/disbursement-confirmation-to-resume/action';
import { exportRequestSummaryResultsBcoRequest } from 'reducer-bco/batch-output/export-request-summary-results/action';
import { useRootDispatch } from 'reducer/hooks';

export const useRefreshStatus = () => {
    const { batchId, outputId } = useParams<{ batchId: string; outputId: string }>();
    const dispatch = useRootDispatch();

    return () => {
        const _request = {
            batchId: Number(batchId),
            outputId: Number(outputId),
        };
        dispatch(
            exportRequestSummaryResultsBcoRequest({
                ..._request,
                type: ExportRequestType.DISBURSEMENT_CONFIRMATION,
            })
        );
        dispatch(disbursementConfirmationToResumeBcoRequest(_request));
    };
};
