import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import DisbursementConfirmationListHeader from 'entities/batch/batch-outputs-detail/components/disbursement-confirmation-apex/components/disbursement-confirmation-header/disbursement-confirmation-header';
import DisbursementConfirmationCollapse from 'entities/batch/batch-outputs-detail/components/disbursement-confirmation-apex/components/disbursment-confirmation-list/disbursement-confirmation-collapse/disbursement-confirmation-collapse';
import ModalBatchExport from 'features/batch-export/components/modal/modal-batch-export';
import { DisbursementConfirmationToResumeBco } from 'model/bco/batch-output-disbursement-confirmation';
import { DisbusementConfirmationStatus } from 'model/bco/enums/batch-output-disbusement-confirmation-apex';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './disbursement-confirmation-list.scss';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.disbursement-confirmation.list.table';

interface DisbursementConfirmationListApexProps {
    confirmationToResume?: DisbursementConfirmationToResumeBco;
    batchId?: number;
}

const DisbursementConfirmationListApex: React.FC<DisbursementConfirmationListApexProps> = ({ confirmationToResume, batchId }) => {
    const { t } = useTranslation();

    const [exportId, setExportId] = useState<number>();
    const [filterCollapse, setFilterCollapse] = useState<string[]>([]);
    const [openModalDetail, setOpenModalDetail] = useState(false);

    const handleSelect = (itemId: string) => {
        setFilterCollapse(prevState => {
            if (prevState.includes(itemId)) {
                return prevState.filter(id => id !== itemId);
            } else {
                return [...prevState, itemId];
            }
        });
    };

    const tbodyRef = useRef<HTMLTableSectionElement>(null);

    const resetScroll = () => {
        if (tbodyRef.current) {
            tbodyRef.current.scrollTop = 0;
        }
    };

    const confirmExports = confirmationToResume?.exports;
    const confirmationId = confirmationToResume?.batchOutputId;

    return (
        <div className="disbursement-confirmation-list">
            {confirmationId &&
                (confirmExports && confirmExports?.length > 0 ? (
                    <table>
                        <DisbursementConfirmationListHeader />
                        {confirmExports?.map(exportRequest => (
                            <>
                                <div className="disbursement-confirmation-list__closing-box">
                                    <div className="disbursement-confirmation-list__closing">
                                        <div className="disbursement-confirmation-list__closing-cell">
                                            <ExpandMoreIcon
                                                className={filterCollapse.includes(exportRequest.id ? exportRequest.id.toString() : '') ? 'active' : ''}
                                                onClick={() => {
                                                    handleSelect(exportRequest.id ? exportRequest.id.toString() : '');
                                                    resetScroll();
                                                }}
                                            />
                                        </div>
                                        <div className="disbursement-confirmation-list__closing-cell">{t(`${I18N_PREFIX}.title`)}</div>
                                        <div className="disbursement-confirmation-list__closing-cell"></div>
                                        <div className={clsx('disbursement-confirmation-list__closing-cell')}>
                                            <span
                                                className={clsx('disbursement-confirmation-list__closing-cell-status', {
                                                    SUCCESS: confirmationToResume?.status === DisbusementConfirmationStatus.SUCCESS,
                                                    PENDENT: confirmationToResume?.status === DisbusementConfirmationStatus.PENDENT,
                                                    ERROR: confirmationToResume?.status === DisbusementConfirmationStatus.ERROR,
                                                    PROCESSING: confirmationToResume?.status === DisbusementConfirmationStatus.PROCESSING,
                                                })}
                                            >
                                                {t(`${I18N_PREFIX}.status.options.${confirmationToResume?.status}`)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <DisbursementConfirmationCollapse
                                    filterCollapse={filterCollapse}
                                    filterKey={exportRequest.id!.toString()}
                                    results={exportRequest.results || []}
                                    setOpenModalDetail={setOpenModalDetail}
                                    setExportId={setExportId}
                                />
                            </>
                        ))}
                    </table>
                ) : (
                    <div className="disbursement-confirmation-list__pending-message">
                        <span>{t(`${I18N_PREFIX}.pending`)}</span>
                    </div>
                ))}
            {openModalDetail && <ModalBatchExport batchId={batchId} exportId={exportId} openModal={openModalDetail} setOpenModal={setOpenModalDetail} />}
        </div>
    );
};

export default DisbursementConfirmationListApex;
