import { GiroWebappError } from 'model/error';
import { PaymentTransactionToSimple } from 'model/payment-transaction';
import { ConfirmExternalTransactionTypes } from 'reducer/payment-transaction/confirm-external-transaction/types';
import { action } from 'typesafe-actions';

export const confirmExternalTransactionRequest = (paymentTransactionId: number) =>
    action(ConfirmExternalTransactionTypes.CONFIRM_EXTERNAL_TRANSACTION_REQUEST, paymentTransactionId);
export const confirmExternalTransactionSuccess = (paymentTransaction: PaymentTransactionToSimple) =>
    action(ConfirmExternalTransactionTypes.CONFIRM_EXTERNAL_TRANSACTION_SUCCESS, paymentTransaction);
export const confirmExternalTransactionError = (error: GiroWebappError) =>
    action(ConfirmExternalTransactionTypes.CONFIRM_EXTERNAL_TRANSACTION_ERROR, error);
export const confirmExternalTransactionResetState = () =>
    action(ConfirmExternalTransactionTypes.CONFIRM_EXTERNAL_TRANSACTION_RESET);
