import { RouteComponentProps, Switch } from 'react-router-dom';

import ScdPaymentTransaction from 'entities/payment-transaction/payment-transaction';
import ScdPaymentTransactionInSeries from 'entities/payment-transaction/payment-transaction-in-series/payment-transaction-in-series';

import PrivateRoute from 'shared/routes/private-route';
import ScdCompletePaymentTransaction from 'entities/payment-transaction/complete-payment-transaction/complete-payment-transaction';
import PaymentTransactionDetail from 'entities/payment-transaction/payment-transaction-detail/payment-transaction-detail';
import ScdPaymentTransactionInSeriesByBatch from 'entities/payment-transaction/payment-transaction-in-series-by-batch/payment-transaction-in-series-by-batch';

interface MatchParams {
    url: string;
}

const PaymentTransactionRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}/in-series`} component={ScdPaymentTransactionInSeries} />
        <PrivateRoute exact path={`${match.url}/in-series/disbursement/by-batch/:batchId`} component={ScdPaymentTransactionInSeriesByBatch} />
        <PrivateRoute exact path={`${match.url}/complete-transactions`} component={ScdCompletePaymentTransaction} />
        <PrivateRoute exact path={`${match.url}`} component={ScdPaymentTransaction} />
        <PrivateRoute exact path={`${match.url}/:paymentTransactionId`} component={PaymentTransactionDetail} />
    </Switch>
);

export default PaymentTransactionRoutes;
