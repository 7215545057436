import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'components/loading/loading';
import AutoSizer from 'react-virtualized-auto-sizer';
import { LIST_HEIGHT, LIST_ITEMS_SIZE } from 'shared/infinite-components/constants';
import { FixedSizeList } from 'react-window';
import CessionIntegrationWebcredRow, {
    CessionIntegrationWebcredRowProps,
} from 'entities/batch/batch-outputs-detail/components/webcred/components/cession-integration-webcred-list/cession-integration-webcred-row/cession-integration-webcred-row';
import { CessionOperationBco } from 'model/bco/batch-output-cession-integration-webcred';
import { FIXED_CLOSING_HEIGHT } from 'entities/batch/batch-outputs-detail/components/webcred/components/cession-closing-fixed-line/cession-closing-fixed-line';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-webcred';

interface StandardListProps {
    cessionOperation: CessionOperationBco[];
    isLoading: boolean;
}

const CessionIntegrationWebcredList: FC<StandardListProps> = ({ cessionOperation, isLoading }) => {
    const { t } = useTranslation();
    const CessionIntegrationWebcredRowObjectData: CessionIntegrationWebcredRowProps = {
        cessionOperation,
    };

    return (
        <>
            {isLoading && !cessionOperation.length && <Loading isTable />}
            {cessionOperation?.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <>
                            <FixedSizeList
                                itemData={CessionIntegrationWebcredRowObjectData}
                                height={height - FIXED_CLOSING_HEIGHT}
                                width={width}
                                itemSize={LIST_ITEMS_SIZE}
                                itemCount={cessionOperation.length}
                                className="page-container--table-container"
                            >
                                {CessionIntegrationWebcredRow}
                            </FixedSizeList>
                        </>
                    )}
                </AutoSizer>
            ) : (
                !isLoading && (
                    <div className="cession-integration-webcred__table-empty-filter">
                        <span className="cession-integration-webcred__table-empty-filter-image" />
                        <span className="cession-integration-webcred__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                    </div>
                )
            )}
        </>
    );
};

export default CessionIntegrationWebcredList;
