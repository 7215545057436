import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { FinancialInstitution } from 'model/financial-institution';
import { markError } from 'reducer/application/error/actions';
import { searchFinancialInstituionsError, searchFinancialInstituionsRequest, searchFinancialInstituionsSuccess } from 'reducer/financial-institution/search-financial-institutions/actions';
import { SearchFinancialInstitutionTypes } from 'reducer/financial-institution/search-financial-institutions/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import financialInstitutionApi from 'services/api/financial-institution-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleSearchFinancialInstitutions(action: ReturnType<typeof searchFinancialInstituionsRequest>) {
    try {
        const result: AxiosResponse<Page<FinancialInstitution>> = yield call(financialInstitutionApi.searchFinancialInstitutions, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchFinancialInstituionsError(mapped));
            return;
        }
        yield put(searchFinancialInstituionsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchFinancialInstituionsError(mapped));
    }
}

function* watchSearchFinancialInstitutions() {
    yield takeEvery(SearchFinancialInstitutionTypes.SEARCH_FINANCIAL_INSTITUTIONS_REQUEST, handleSearchFinancialInstitutions);
}

function* searchFinancialInstitutionsSaga() {
    yield all([fork(watchSearchFinancialInstitutions)]);
}

export default searchFinancialInstitutionsSaga;
