import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import {
    cessionOutputResumeBcoError,
    cessionOutputResumeBcoRequest,
    cessionOutputResumeBcoSuccess,
} from 'reducer-bco/batch-output/cession-output-resume/action';
import { CessionOutputResumeBcoTypes } from 'reducer-bco/batch-output/cession-output-resume/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchOutputBcoApi from 'services/bco/batch-outputs-bco';
import ErrorUtils from 'shared/error/error-utils';
import { CessionOutputResumeBco } from 'model/bco/batch-output';

function* handleCessionOutputResumeBco(action: ReturnType<typeof cessionOutputResumeBcoRequest>) {
    try {
        const result: AxiosResponse<CessionOutputResumeBco> = yield call(batchOutputBcoApi.cessionOutputResume, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(cessionOutputResumeBcoError(mapped));
            return;
        }
        yield put(cessionOutputResumeBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(cessionOutputResumeBcoError(mapped));
    }
}

function* watchCessionOutputResumeBcoSaga() {
    yield takeEvery(CessionOutputResumeBcoTypes.CESSION_OUTPUT_RESUME_BCO_REQUEST, handleCessionOutputResumeBco);
}

function* cessionOutputResumeBcoSaga() {
    yield all([fork(watchCessionOutputResumeBcoSaga)]);
}

export default cessionOutputResumeBcoSaga;
