import { EnvironmentUtils } from 'shared/util/environment-utils';

import { PAYMENT_ACCOUNTS_BASE_URL } from 'config/constants';
import iconPaymentTransactions from 'images/arrows-horizontal.svg';
import iconReports from 'images/document-view.svg';
import iconCCBOperations from 'images/document.svg';
import iconBatches from 'images/documents.svg';
import iconCompanyGroups from 'images/group-security.svg';
import iconRegisters from 'images/identification.svg';
import iconPaymentInstitutionsAccounts from 'images/license-third-party.svg';
import iconPrograms from 'images/product.svg';
import iconAdmin from 'images/roadmap.svg';
import { MenuItemPropsList } from 'model/sidebar';

const I18N_PREFIX = 'navbar.entities';

export const EntitiesData = () => {
    const entities: MenuItemPropsList[] = [
        {
            link: '/payment-transactions',
            title: `${I18N_PREFIX}.transactions.title`,
            iconSource: iconPaymentTransactions,
        },
        {
            link: '/batches',
            title: `${I18N_PREFIX}.batches.title`,
            iconSource: iconBatches,
        },
        {
            link: '/ccb-operations',
            title: `${I18N_PREFIX}.operations.title`,
            iconSource: iconCCBOperations,
        },
        // TODO: UPDATE PAYMENT ACCOUNT LINKS AFTER MIGRATION
        {
            link: '/payment-institutions-accounts',
            title: `${I18N_PREFIX}.payment-institutions-accounts.title`,
            iconSource: iconPaymentInstitutionsAccounts,
        },
        {
            title: `${I18N_PREFIX}.registers.title`,
            hasSubmenu: true,
            iconSource: iconRegisters,
            submenu: [
                {
                    link: '/companies',
                    title: `${I18N_PREFIX}.registers.submenu.companies`,
                },
                {
                    link: '/organizations',
                    title: `${I18N_PREFIX}.registers.submenu.organizations`,
                },
                {
                    link: '/originators',
                    title: `${I18N_PREFIX}.registers.submenu.originators`,
                },
                {
                    link: '/technical-suppliers',
                    title: `${I18N_PREFIX}.registers.submenu.technical-suppliers`,
                },
                {
                    link: '/issuers',
                    title: `${I18N_PREFIX}.registers.submenu.issuers`,
                },
                {
                    link: '/banks',
                    title: `${I18N_PREFIX}.banks.title`,
                },
                {
                    link: '/banking-correspondents',
                    title: `${I18N_PREFIX}.banking-correspondents.title`,
                },
                {
                    link: '/financial-institutions',
                    title: `${I18N_PREFIX}.financial-institutions.title`,
                },
                // TODO: UPDATE PAYMENT ACCOUNT LINKS AFTER MIGRATION
            ],
        },

        {
            title: `${I18N_PREFIX}.program.title`,
            hasSubmenu: true,
            iconSource: iconPrograms,
            submenu: [
                {
                    link: '/programs',
                    title: `${I18N_PREFIX}.program.submenu.programs`,
                },
                {
                    link: '/program-policies',
                    title: `${I18N_PREFIX}.program.submenu.program-policies`,
                },
            ],
        },
        {
            title: `${I18N_PREFIX}.company-groups.title`,
            hasSubmenu: true,
            iconSource: iconCompanyGroups,
            submenu: [
                {
                    link: '/company-groups',
                    title: `${I18N_PREFIX}.company-groups.submenu.company-groups`,
                },
                {
                    link: '/company-group-policies',
                    title: `${I18N_PREFIX}.company-groups.submenu.company-groups-policies`,
                },
            ],
        },
        {
            title: `${I18N_PREFIX}.admin.title`,
            hasSubmenu: true,
            iconSource: iconAdmin,
            submenu: [
                {
                    link: '/bankers',
                    title: `${I18N_PREFIX}.admin.submenu.bankers`,
                },
                {
                    link: '/users',
                    title: `${I18N_PREFIX}.admin.submenu.users`,
                },
                {
                    link: '/taxes',
                    title: `${I18N_PREFIX}.admin.submenu.taxes`,
                },
            ],
        },
        {
            title: `${I18N_PREFIX}.report.title`,
            hasSubmenu: true,
            iconSource: iconReports,
            submenu: [
                {
                    link: '/disbursement-report',
                    title: `${I18N_PREFIX}.report.submenu.disbursement-report`,
                },
                {
                    link: '/monthly-processed-operations-report',
                    title: `${I18N_PREFIX}.report.submenu.monthly-processed-operations-report`,
                },
                {
                    link: '/fare-amount-report',
                    title: `${I18N_PREFIX}.report.submenu.fare-amount-report`,
                },
                {
                    link: '/request-log/legacy',
                    title: `${I18N_PREFIX}.report.submenu.request-log-dev`,
                    isHidden: !EnvironmentUtils.isStagingEnvironment(),
                },
                {
                    link: '/request-log',
                    title: `${I18N_PREFIX}.report.submenu.request-log`,
                },
            ],
        },
        // TODO: THIS LINK ONLY APPEARS IN STAG. REMOVE AFTER MIGRATION
        {
            link: PAYMENT_ACCOUNTS_BASE_URL,
            title: `${I18N_PREFIX}.provisoryLink.payment-institutions-accounts`,
            isHidden: EnvironmentUtils.isProductionEnvironment(),
            isExternalLink: true,
            iconSource: iconPaymentInstitutionsAccounts
        }
    ];

    return entities;
};
