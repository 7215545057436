import ModalMessage from 'components/modals/modal-message/modal-message';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { Trans, useTranslation } from 'react-i18next';
import { FC, useEffect } from 'react';
import { chunkStreamProcessBcoResetState } from 'reducer-bco/batch-output/chunk-stream-process/action';
import { useRecalculateSessionInterestRateState, useRootDispatch } from 'reducer/hooks';
import 'entities/batch/batch-session-interest-rate-calculate-recalculate/calculate-session-interest-rate/calculate-session-interest-rate-modal/calculate-session-interest-rate-modal.scss';
import { SessionInterestRateRequest } from 'model/session-interest-rates';
import { validateScdCalculateSessionInterestRate } from 'entities/batch/batch-session-interest-rate-calculate-recalculate/validation/batch-session-interest-rate-validation';
import { CessionInterestRateVersion } from 'model/enums/CessionInterestVersion';
import { recalculateSessionInterestRateRequest } from 'reducer/session-interest-rate/recalculate-session-interest-rate/actions';

const I18N_PREFIX = 'pages.scd.batch.session-interest-rate';

interface SendStreamModalProps {
    setIsModalOpen: (value: boolean) => void;
    setShowValidation: (value: boolean) => void;
    sessionInterestRate?: SessionInterestRateRequest;
}

const RecalculateSessionInterestRateModal: FC<SendStreamModalProps> = ({ setIsModalOpen, setShowValidation, sessionInterestRate }) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { status } = useRecalculateSessionInterestRateState();

    const handleRecalculateSession = () => {
        setShowValidation(true);

        const _scdSessionInterestRate: SessionInterestRateRequest = {
            ...sessionInterestRate,
        };

        if (!validateScdCalculateSessionInterestRate(_scdSessionInterestRate)) return;

        dispatch(recalculateSessionInterestRateRequest(_scdSessionInterestRate));
        setIsModalOpen(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        return () => {
            dispatch(chunkStreamProcessBcoResetState());
        };
    }, [dispatch]);

    const isVersion2Precision = sessionInterestRate?.version === CessionInterestRateVersion.VERSION_2_PRECISION;
    const isVersion2Performance = sessionInterestRate?.version === CessionInterestRateVersion.VERSION_2_PERFORMANCE;
    const isVersion2 = isVersion2Precision || isVersion2Performance;

    const standardButtonLabel = !isVersion2 ? t(`${I18N_PREFIX}.input.chooseVersion.recalculate.button.options.${sessionInterestRate?.version}`) : 'entity.action.back';
    const outlinedButtonLabel = !isVersion2 ? 'entity.action.back' : t(`${I18N_PREFIX}.input.chooseVersion.recalculate.button.options.${sessionInterestRate?.version}`);

    return (
        <ModalMessage
            title={t(`${I18N_PREFIX}.calculate.title`)}
            onClose={handleCloseModal}
            onAction={handleRecalculateSession}
            onCancel={handleCloseModal}
            standardButtonLabel={standardButtonLabel}
            outlinedButtonLabel={outlinedButtonLabel}
            disabled={status === HttpRequestStatus.ON_GOING}
            isWarningModal={isVersion2}
        >
            <div className="calculate-session-interest-rate-modal__text">
                <Trans i18nKey={`${I18N_PREFIX}.modal.recalculate.message.options.${sessionInterestRate?.version}`} />
            </div>
        </ModalMessage>
    );
};

export default RecalculateSessionInterestRateModal;
