import { CnabTemplate } from 'model/enums/cnab-template';
import { OperationCollateralPolicy } from 'model/enums/operation-collateral-policy';
import { ProductType } from 'model/enums/product-type';
import { ScdProgramDisbursementAmendmentPolicy } from 'model/enums/program-disbursement-amendment-policy';
import { ScdProgramOperationApprovalPolicy } from 'model/enums/program-operation-approval-policy-type';
import { ScdProgramPaymentFavoredPolicy } from 'model/enums/program-payment-favored-policy';
import { ScdProgramPaymentModePolicy } from 'model/enums/program-payment-mode-policy-type';
import { ScdProgramCommissionPolicy, ScdProgramPolicyType } from 'model/enums/program-policy-type';
import { ScdProgramTransferDispatchModePolicy } from 'model/enums/program-transfer-dispatch-mode';

export interface ScdProgramPolicy {
    id?: string;
    policyType?: ScdProgramPolicyType;
    scdProgram?: ScdProgramInfo;
    commissionPolicy?: ScdProgramCommissionPolicy;
    cnabTemplate?: CnabTemplate;
    paymentFavoredPolicy?: ScdProgramPaymentFavoredPolicy;
    paymentModePolicy?: ScdProgramPaymentModePolicy,
    operationApprovalPolicy?: ScdProgramOperationApprovalPolicy,
    transferDispatchModePolicy?: ScdProgramTransferDispatchModePolicy,
    disbursementAmendmentPolicy?: ScdProgramDisbursementAmendmentPolicy,
    operationCollateralPolicy?: OperationCollateralPolicy;
}

export const defaultScdProgramPolicy: ScdProgramPolicy = {
    id: undefined,
    policyType: undefined,
    scdProgram: undefined,
    commissionPolicy: undefined,
    cnabTemplate: undefined,
};

export interface ScdProgramPolicySimple {
    id: string;
}

export interface ScdProgramInfo {
    id: number;
    name?: string;
    productType?: ProductType
}

export interface ProgramPolicyToBatchDetail {
    id: number;
    policyType: ScdProgramPolicyType;
    cnabTemplate: CnabTemplate;
    paymentModePolicy: ScdProgramPaymentModePolicy
    operationApprovalPolicy: ScdProgramOperationApprovalPolicy;
    transferDispatchModePolicy: ScdProgramTransferDispatchModePolicy;
    paymentFavoredPolicy: ScdProgramPaymentFavoredPolicy;
    commissionPolicy: ScdProgramCommissionPolicy;
    operationCollateralPolicy: OperationCollateralPolicy;
}
