import { DisbursementConfirmationSimpleBco, DisbursementConfirmationToRequest } from 'model/bco/batch-output-disbursement-confirmation';
import { GiroWebappError } from 'model/error';
import { DisbursementConfirmationToProcessBcoTypes } from 'reducer-bco/batch-output/disbursement-confirmation-to-process/types';
import { action } from 'typesafe-actions';

export const disbursementConfirmationToProcessBcoRequest = (request: DisbursementConfirmationToRequest) =>
    action(DisbursementConfirmationToProcessBcoTypes.DISBURSEMENT_CONFIRMATION_TO_PROCESS_BCO_REQUEST, request);
export const disbursementConfirmationToProcessBcoSuccess = (disbursementConfirmationToProcessBco: DisbursementConfirmationSimpleBco) =>
    action(DisbursementConfirmationToProcessBcoTypes.DISBURSEMENT_CONFIRMATION_TO_PROCESS_BCO_SUCCESS, disbursementConfirmationToProcessBco);
export const disbursementConfirmationToProcessBcoError = (error: GiroWebappError) =>
    action(DisbursementConfirmationToProcessBcoTypes.DISBURSEMENT_CONFIRMATION_TO_PROCESS_BCO_ERROR, error);
export const disbursementConfirmationToProcessBcoResetState = () => action(DisbursementConfirmationToProcessBcoTypes.DISBURSEMENT_CONFIRMATION_TO_PROCESS_BCO_RESET);
