import { AxiosResponse } from 'axios';
import { SearchRequest } from 'model/reducers';
import { ScdTechnicalSupplier, ScdTechnicalSupplierSimple, TechnicalSupplierDetail } from 'model/technical-supplier';
import { api } from 'services/api/api';
import { createQueryParam } from 'services/api/api-utils';
import { BASE_URL_ADMIN, Page } from 'services/page';
import { Pageable } from 'services/pageable';

export const technicalSupplier = () => {
    const createTechnicalSupplier = (technicalSupplier: ScdTechnicalSupplier): Promise<AxiosResponse<ScdTechnicalSupplierSimple>> => {
        return api.post<ScdTechnicalSupplierSimple>(`${BASE_URL_ADMIN}/technical-suppliers`, technicalSupplier);
    };

    const updateTechnicalSupplier = (technicalSupplier: ScdTechnicalSupplier): Promise<AxiosResponse<ScdTechnicalSupplierSimple>> => {
        return api.put<ScdTechnicalSupplierSimple>(`${BASE_URL_ADMIN}/technical-suppliers/${technicalSupplier.id}`, technicalSupplier);
    };

    const detailTechnicalSupplier = (technicalSupplierId: number): Promise<AxiosResponse<TechnicalSupplierDetail>> => {
        return api.get<TechnicalSupplierDetail>(`${BASE_URL_ADMIN}/technical-suppliers/${technicalSupplierId}`);
    };

    const listTechnicalSuppliers = (request?: Pageable): Promise<AxiosResponse<Page<ScdTechnicalSupplier>>> => {
        return api.get<Page<ScdTechnicalSupplier>>(`${BASE_URL_ADMIN}/technical-suppliers`, { params: { ...request } });
    };

    const defaultTechnicalSupplier = (technicalSupplierId: number): Promise<AxiosResponse<ScdTechnicalSupplierSimple>> => {
        return api.patch<ScdTechnicalSupplierSimple>(
            `${BASE_URL_ADMIN}/technical-suppliers/${technicalSupplierId}/default-payment-institution`
        );
    };

    const searchTechnicalSuppliers = (request: SearchRequest): Promise<AxiosResponse<Page<ScdTechnicalSupplier>>> => {
        const { originatorId, financialInstitutionId, pageable, search } = request;

        const requestFilters = [
            createQueryParam('search', search),
            createQueryParam('originatorId', originatorId),
            createQueryParam('financialInstitutionId', financialInstitutionId),
        ].filter(Boolean).join('&');

        return api.get<Page<ScdTechnicalSupplier>>(`${BASE_URL_ADMIN}/technical-suppliers/search?${requestFilters}`, {
            params: { ...pageable },
        });
    };

    return {
        createTechnicalSupplier,
        detailTechnicalSupplier,
        updateTechnicalSupplier,
        listTechnicalSuppliers,
        defaultTechnicalSupplier,
        searchTechnicalSuppliers,
    };
};

export default technicalSupplier();
