import { Grid, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import ClearButton from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import SimpleSelectInput from 'components/inputs/select-input/simple-select-input';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import { PaymentInstitutionAccountStatus } from 'model/enums/payment-institution-account-status';
import { PaymentInstitutionAccountType } from 'model/enums/payment-institution-account-type';
import { PaymentInstitutionAccountFilterRequest } from 'model/payment-institution-account';
import { ScdTechnicalSupplier } from 'model/technical-supplier';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { IRootState } from 'reducer';
import { searchTechnicalSuppliersRequest, searchTechnicalSuppliersResetState } from 'reducer/technical-supplier/search-originators/actions';
import { valuesOfObject } from 'shared/util/object-utils';

import './payment-institutions-accounts-modal-filter.scss';

export type PaymentInstitutionAccountModalFilterType = 'filters' | 'name' | 'status' | 'type' | 'technicalSupplier';

interface PaymentInstitutionAccountsModalFilterProps {
    isOpenModalFilter: boolean;
    modalFilter: PaymentInstitutionAccountModalFilterType;
    onClose: () => void;
    title: string;
    filterActive: PaymentInstitutionAccountFilterRequest;
    setFilterActive: (value: PaymentInstitutionAccountFilterRequest) => void;
    setPage: (value: number) => void;
}

const DISABLED_MODAL_BUTTON_FUNCTION_RECORD = {
    filters: filter => valuesOfObject({ ...filter }).filter(it => !!it).length === 0,
    name: filter => !filter.name,
    status: filter => !filter.status,
    technicalSupplier: filter => !filter.technicalSupplierId,
    type: filter => !filter.type,
} as Record<PaymentInstitutionAccountModalFilterType, (value: PaymentInstitutionAccountFilterRequest) => boolean>;

const I18N_PREFIX = 'pages.scd.payment-institutions-accounts.filter.inputs';

const PaymentInstitutionAccountsModalFilter = (props: PaymentInstitutionAccountsModalFilterProps) => {
    const { filterActive, isOpenModalFilter, modalFilter, onClose, setFilterActive, setPage, title } = props;

    const [filterRequest, setFilterRequest] = useState<PaymentInstitutionAccountFilterRequest>(filterActive);

    const { t } = useTranslation();

    useEffect(() => {
        setFilterRequest(filterActive);
    }, [filterActive]);

    if (!isOpenModalFilter) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        setPage(0);
        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<PaymentInstitutionAccountFilterRequest>) => {
        setFilterRequest(ps => ({ ...ps, ...value }));
    };

    const handleClearFilter = {
        filters: () => {
            setFilterActive({});
            setFilterRequest({});
        },
        name: () => {
            setFilterActive({ ...filterActive, name: undefined });
            setFilterRequest({ ...filterRequest, name: undefined });
        },
        status: () => {
            setFilterActive({ ...filterActive, status: undefined });
            setFilterRequest({ ...filterRequest, status: undefined });
        },
        type: () => {
            setFilterActive({ ...filterActive, type: undefined });
            setFilterRequest({ ...filterRequest, type: undefined });
        },
        technicalSupplier: () => {
            setFilterActive({ ...filterActive, technicalSupplierId: undefined, technicalSupplierName: undefined });
            setFilterRequest({ ...filterRequest, technicalSupplierId: undefined, technicalSupplierName: undefined });
        },
    } as Record<PaymentInstitutionAccountModalFilterType, () => void>;

    const NameFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleTextInput
                    label={t(`${I18N_PREFIX}.name.label`)}
                    placeholder={t(`${I18N_PREFIX}.name.placeholder`)}
                    value={filterRequest?.name}
                    onChange={name => handleChange({ name })}
                    externalUpdate
                />
            </Grid>
        </>
    );

    const StatusFilter: JSX.Element = (
        <>
            <div
                className={clsx('scd-payment-institutions-accounts-modal-filter--content-status--label', {
                    ACTIVE: filterRequest.status === PaymentInstitutionAccountStatus.ACTIVE,
                })}
                onClick={() => handleChange({ status: PaymentInstitutionAccountStatus.ACTIVE })}
            >
                {t(`${I18N_PREFIX}.status.options.ACTIVE`).toUpperCase()}
            </div>
            <div
                className={clsx('scd-payment-institutions-accounts-modal-filter--content-status--label', {
                    INACTIVE: filterRequest.status === PaymentInstitutionAccountStatus.INACTIVE,
                })}
                onClick={() => handleChange({ status: PaymentInstitutionAccountStatus.INACTIVE })}
            >
                {t(`${I18N_PREFIX}.status.options.INACTIVE`).toUpperCase()}
            </div>
        </>
    );

    const TypeFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleSelectInput
                    label={t(`${I18N_PREFIX}.type.label`)}
                    placeholder={t(`${I18N_PREFIX}.type.placeholder`)}
                    mapperFromString={_value => (_value ? PaymentInstitutionAccountType[_value] : undefined)}
                    value={filterRequest.type ?? ''}
                    onChange={type => handleChange({ type: PaymentInstitutionAccountType[type] })}
                    externalUpdate
                >
                    <MenuItem value={PaymentInstitutionAccountType.GENERAL}>{t(`${I18N_PREFIX}.type.options.GENERAL`)}</MenuItem>
                    <MenuItem value={PaymentInstitutionAccountType.DIGITAL}>{t(`${I18N_PREFIX}.type.options.DIGITAL`)}</MenuItem>
                    <MenuItem value={PaymentInstitutionAccountType.EXCLUSIVE}>{t(`${I18N_PREFIX}.type.options.EXCLUSIVE`)}</MenuItem>
                    <MenuItem value={PaymentInstitutionAccountType.EXTERNAL}>{t(`${I18N_PREFIX}.type.options.EXTERNAL`)}</MenuItem>
                </SimpleSelectInput>
            </Grid>
        </>
    );

    const TechnicalSupplierFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <ModalSearch<ScdTechnicalSupplier>
                    modalTitle={t(`${I18N_PREFIX}.technicalSupplier.label`)}
                    modalLabel={t(`${I18N_PREFIX}.technicalSupplier.label`)}
                    modalPlaceholder={t(`${I18N_PREFIX}.technicalSupplier.placeholder`)}
                    itemSize={SEARCH_ITEM_SIZE}
                    action={searchTechnicalSuppliersRequest}
                    onSelect={technicalSupplier =>
                        handleChange({ technicalSupplierId: Number(technicalSupplier.id), technicalSupplierName: technicalSupplier.name })
                    }
                    renderItem={technicalSupplier => technicalSupplier.name}
                    statusSelector={(state: IRootState) => state.searchTechnicalSuppliers.status}
                    dataSelector={(state: IRootState) => state.searchTechnicalSuppliers.technicalSuppliers}
                    resetState={searchTechnicalSuppliersResetState}
                >
                    {handleOpen => (
                        <SimpleSearchInput
                            label={t(`${I18N_PREFIX}.technicalSupplier.label`)}
                            placeholder={t(`${I18N_PREFIX}.technicalSupplier.placeholder`)}
                            value={filterRequest.technicalSupplierName}
                            onClick={handleOpen}
                            onFocus={handleOpen}
                            readOnly
                            externalUpdate
                        />
                    )}
                </ModalSearch>
            </Grid>
        </>
    );

    const isConfirmButtonDisabled: boolean = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled: boolean = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterActive);

    return (
        isOpenModalFilter && (
            <Modal isOpen>
                <div className="scd-payment-institutions-accounts-modal-filter">
                    <div className="scd-payment-institutions-accounts-modal-filter--header">
                        <label className="scd-payment-institutions-accounts-modal-filter--header-title"> {title} </label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    filters: (
                                        <>
                                            {NameFilter}
                                            {StatusFilter}
                                            {TypeFilter}
                                            {TechnicalSupplierFilter}
                                        </>
                                    ),
                                    name: NameFilter,
                                    status: StatusFilter,
                                    type: TypeFilter,
                                    technicalSupplier: TechnicalSupplierFilter,
                                } as Record<PaymentInstitutionAccountModalFilterType, React.ReactNode>
                            )[modalFilter]
                        }
                    </Grid>

                    <div className="scd-payment-institutions-accounts-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButtonLegacy
                            label="entity.action.confirm"
                            onClick={handleConfirmRequest}
                            disabled={isConfirmButtonDisabled}
                        />
                    </div>
                </div>
            </Modal>
        )
    );
};

export default PaymentInstitutionAccountsModalFilter;
