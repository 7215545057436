import { GiroWebappError } from 'model/error';
import { CessionOutputResumeBcoTypes } from 'reducer-bco/batch-output/cession-output-resume/types';
import { action } from 'typesafe-actions';
import { CessionOutputResumeBco, CessionOutputResumeRequest } from 'model/bco/batch-output';

export const cessionOutputResumeBcoRequest = (request: CessionOutputResumeRequest) =>
    action(CessionOutputResumeBcoTypes.CESSION_OUTPUT_RESUME_BCO_REQUEST, request);
export const cessionOutputResumeBcoSuccess = (cessionOutputResumeBco: CessionOutputResumeBco) =>
    action(CessionOutputResumeBcoTypes.CESSION_OUTPUT_RESUME_BCO_SUCCESS, cessionOutputResumeBco);
export const cessionOutputResumeBcoError = (error: GiroWebappError) =>
    action(CessionOutputResumeBcoTypes.CESSION_OUTPUT_RESUME_BCO_ERROR, error);
export const cessionOutputResumeBcoResetState = () => action(CessionOutputResumeBcoTypes.CESSION_OUTPUT_RESUME_BCO_RESET);
