import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import './base-radio-input.scss'

type Base = Pick<RadioProps, 'color'>;

export interface BaseRadioInputProps<T extends string = string> extends Base {
    id?: string;
    label: string;
    value: T;
    selectedValue?: T;
    onChange?: (value: T) => void;
}

export const BaseRadioInput = <T extends string = string>({
    label,
    value: propsValue,
    selectedValue: propsSelectedValue,
    onChange,
    id,
    ...otherProps
}: BaseRadioInputProps<T>) => {
    const [value, setValue] = React.useState<string>(propsValue ?? false);
    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _value = event?.target?.value as T;
        if (_value === propsSelectedValue) return;

        setValue(_value);
        if (onChange) {
            onChange(_value);
        }
    };

    return (
        <FormControlLabel
            name={id}
            label={t(label)}
            control={<Radio checked={propsSelectedValue === value} value={value} onChange={handleChange} {...otherProps} />}
        />
    );
};

export default BaseRadioInput;
