import { AxiosResponse } from 'axios';
import { BatchClosingResumePayments, ScdBatchClosingResumeOperations, ScdBatchClosingToGetOperation } from 'model/batch-closing';
import { OperationsListPageable } from 'model/reducers';
import api from 'services/api/api';
import { BASE_URL_ADMIN, Page } from 'services/page';

const batchClosing = () => {
    const resumeOperations = (batchId: number): Promise<AxiosResponse<ScdBatchClosingResumeOperations>> => {
        return api.get<ScdBatchClosingResumeOperations>(`${BASE_URL_ADMIN}/batches/${batchId}/closing/operations/resume`);
    };

    const operationsList = (request: OperationsListPageable): Promise<AxiosResponse<Page<ScdBatchClosingToGetOperation>>> => {
        return api.get<Page<ScdBatchClosingToGetOperation>>(`${BASE_URL_ADMIN}/batches/${request.batchId}/closing/operations`, {
            params: { ...request.pageable },
        });
    };

    const resumePayments = (batchId: number): Promise<AxiosResponse<BatchClosingResumePayments>> => {
        return api.get<BatchClosingResumePayments>(`${BASE_URL_ADMIN}/batches/${batchId}/closing/payments/resume`);
    };

    return {
        resumeOperations,
        operationsList,
        resumePayments
    }
};

export default batchClosing();