import ModalMessage from 'components/modals/modal-message/modal-message';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDisbursementConfirmationToProcessBco, useDisbursementConfirmationToResumeBco } from 'reducer-bco/hooks-bco';
import { useRootDispatch } from 'reducer/hooks';

import { DisbursementConfirmationToRequest } from 'model/bco/batch-output-disbursement-confirmation';
import { disbursementConfirmationToProcessBcoRequest } from 'reducer-bco/batch-output/disbursement-confirmation-to-process/action';
import './process-disbursement-confirmation-modal.scss';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.disbursement-confirmation.table.footer.modal.process';

interface ProcessDisbursementConfirmationModalProps {
    setIsModalOpen: (value: boolean) => void;
}

const ProcessDisbursementConfirmationModal: FC<ProcessDisbursementConfirmationModalProps> = ({ setIsModalOpen }) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { batchId, outputId } = useParams<{ batchId: string; outputId: string }>();

    const { status } = useDisbursementConfirmationToProcessBco();
    const { confirmationToResume } = useDisbursementConfirmationToResumeBco();

    const confirmation = confirmationToResume;

    const handleSendProcess = () => {
        if (!confirmation) return;
        const request: DisbursementConfirmationToRequest = {
            batchId: Number(batchId),
            outputId: Number(outputId),
        };
        dispatch(disbursementConfirmationToProcessBcoRequest(request));
        setIsModalOpen(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <ModalMessage
            title={t(`${I18N_PREFIX}.title`)}
            onClose={handleCloseModal}
            onAction={handleSendProcess}
            onCancel={handleCloseModal}
            standardButtonLabel={`${I18N_PREFIX}.button.send`}
            outlinedButtonLabel={'entity.action.back'}
            disabled={status === HttpRequestStatus.ON_GOING}
        >
            <div className="process-disbursement-confirmation-modal__text">{t(`${I18N_PREFIX}.message`)}</div>
        </ModalMessage>
    );
};

export default ProcessDisbursementConfirmationModal;
