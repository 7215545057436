import clsx from 'clsx';
import Loading from 'components/loading/loading';
import BatchClosingFooter from 'entities/batch/batch-closing/batch-closing-footer/batch-closing-footer';
import BatchProcessComponents from 'entities/batch/batch-closing/components/batch-process-components/batch-process-components';
import BatchClosingHeaderContent from 'entities/batch/components/batch-closing-header-content/batch-closing-header-content';
import BatchClosingHeader from 'entities/batch/components/batch-closing-header/batch-closing-header';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    batchClosingResumePaymentsRequest,
    batchClosingResumePaymentsResetState,
} from 'reducer/batch-closing/batch-payments-resume/actions';
import { useBatchDetailWithProgramState } from 'reducer/hooks';
import { compose } from 'redux';
import { withResumeBatchWrapper } from 'shared/provider/batchContext';
import './batch-closing.scss';

const I18N_PREFIX = 'pages.scd.batch';

const BatchClosing = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { batchWithProgram, status: batchWithProgramStatus } = useBatchDetailWithProgramState();

    const isLoadingBatch = batchWithProgramStatus !== HttpRequestStatus.SUCCESS;

    useEffect(() => {
        if (!batchWithProgram?.id) return;

        dispatch(batchClosingResumePaymentsRequest(batchWithProgram.id));
        return () => {
            dispatch(batchClosingResumePaymentsResetState());
        };
    }, [dispatch, batchWithProgram?.id]);

    return (
        <main className="batch-closing">
            <section className="batch-closing__container">
                <h1 className="batch-closing__container-title">{t(`${I18N_PREFIX}.closing.title`)}</h1>
                {isLoadingBatch ? (
                    <Loading />
                ) : (
                    <>
                        <BatchClosingHeader>
                            <BatchClosingHeaderContent title={t(`${I18N_PREFIX}.batch-card.titles.batch`)}>
                                {batchWithProgram?.code ?? '-'}
                            </BatchClosingHeaderContent>
                            <BatchClosingHeaderContent title={t(`${I18N_PREFIX}.batch-card.titles.status`)} isTooltip>
                                <span className={clsx('batch-closing__container-status', batchWithProgram?.status)}>
                                    {t(`${I18N_PREFIX}.status.options.${batchWithProgram?.status}`).toUpperCase()}
                                </span>
                            </BatchClosingHeaderContent>
                            <BatchClosingHeaderContent title={t(`${I18N_PREFIX}.batch-card.titles.originator`)} isTooltip>
                                {batchWithProgram?.originator?.name ?? '-'}
                            </BatchClosingHeaderContent>
                            <BatchClosingHeaderContent title={t(`${I18N_PREFIX}.batch-card.titles.payment-favored-policy`)} isTooltip>
                                {t(
                                    `${I18N_PREFIX}.payment-favored-policy.options.${batchWithProgram?.program?.policy?.paymentFavoredPolicy}`
                                )}
                            </BatchClosingHeaderContent>
                            <BatchClosingHeaderContent title={t(`${I18N_PREFIX}.batch-card.titles.payment-mode-policy`)} isTooltip>
                                {t(`${I18N_PREFIX}.payment-mode-policy.options.${batchWithProgram?.program?.policy?.paymentModePolicy}`)}
                            </BatchClosingHeaderContent>
                            <BatchClosingHeaderContent title={t(`${I18N_PREFIX}.batch-card.titles.operation-collateral-policy`)} isTooltip>
                                {t(
                                    `${I18N_PREFIX}.operation-collateral-policy.options.${batchWithProgram?.program?.policy?.operationCollateralPolicy}`
                                )}
                            </BatchClosingHeaderContent>
                        </BatchClosingHeader>
                        <BatchClosingFooter />
                        <BatchProcessComponents />
                    </>
                )}
            </section>
        </main>
    );
};

export default compose(withResumeBatchWrapper())(BatchClosing);
