import { action } from 'typesafe-actions';

import { GiroWebappError } from 'model/error';
import { FinancialInstitution } from 'model/financial-institution';
import { SearchRequest } from 'model/reducers';
import { SearchFinancialInstitutionTypes } from 'reducer/financial-institution/search-financial-institutions/types';
import { Page } from 'services/page';

export const searchFinancialInstituionsRequest = (request: SearchRequest) => action(SearchFinancialInstitutionTypes.SEARCH_FINANCIAL_INSTITUTIONS_REQUEST, request);
export const searchFinancialInstituionsSuccess = (financialInstituions: Page<FinancialInstitution>) => action(SearchFinancialInstitutionTypes.SEARCH_FINANCIAL_INSTITUTIONS_SUCCESS, financialInstituions);
export const searchFinancialInstituionsError = (error: GiroWebappError) => action(SearchFinancialInstitutionTypes.SEARCH_FINANCIAL_INSTITUTIONS_ERROR, error);
export const searchFinancialInstituionsResetState = () => action(SearchFinancialInstitutionTypes.SEARCH_FINANCIAL_INSTITUTIONS_RESET);
