import { GiroWebappError } from 'model/error';
import { TechnicalSupplierDetail } from 'model/technical-supplier';
import { action } from 'typesafe-actions';
import { DetailTechnicalSupplierTypes } from './types';

export const detailTechnicalSupplierRequest = (technicalSupplierId: number) =>
    action(DetailTechnicalSupplierTypes.DETAIL_TECHNICAL_SUPPLIER_REQUEST, technicalSupplierId);
export const detailTechnicalSupplierSuccess = (technicalSupplier: TechnicalSupplierDetail) =>
    action(DetailTechnicalSupplierTypes.DETAIL_TECHNICAL_SUPPLIER_SUCCESS, technicalSupplier);
export const detailTechnicalSupplierError = (error: GiroWebappError) =>
    action(DetailTechnicalSupplierTypes.DETAIL_TECHNICAL_SUPPLIER_ERROR, error);
export const detailTechnicalSupplierResetState = () => action(DetailTechnicalSupplierTypes.DETAIL_TECHNICAL_SUPPLIER_RESET_STATE);
