import { FinancialInstitution } from 'model/financial-institution';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum SearchFinancialInstitutionTypes {
    SEARCH_FINANCIAL_INSTITUTIONS_REQUEST = '@@financial-institutions-search/SEARCH_FINANCIAL_INSTITUTIONS_REQUEST',
    SEARCH_FINANCIAL_INSTITUTIONS_SUCCESS = '@@financial-institutions-search/SEARCH_FINANCIAL_INSTITUTIONS_SUCCESS',
    SEARCH_FINANCIAL_INSTITUTIONS_ERROR = '@@financial-institutions-search/SEARCH_FINANCIAL_INSTITUTIONS_ERROR',
    SEARCH_FINANCIAL_INSTITUTIONS_RESET = '@@financial-institutions-search/SEARCH_FINANCIAL_INSTITUTIONS_RESET',
}

export interface SearchFinancialInstitutionsState extends BaseStoreState {
    readonly financialInstituions?: Page<FinancialInstitution>;
}
