import BackButton from 'components/buttons/back-button/back-button';
import LinkButton from 'components/buttons/link-button/link-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ScdTechnicalSupplierHeader from 'entities/technical-supplier/components/technical-supplier-header/technical-supplier-header';
import TechnicalSuppliersList from 'entities/technical-supplier/components/technical-suppliers-list/technical-suppliers-list';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ScdTechnicalSupplier } from 'model/technical-supplier';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IRootState } from 'reducer';
import { useDefaultTechnicalSuppliersState, useRootDispatch } from 'reducer/hooks';
import {
    defaultTechnicalSupplierRequest,
    defaultTechnicalSupplierResetState,
} from 'reducer/technical-supplier/default-technical-supplier/actions';
import { listTechnicalSuppliersRequest } from 'reducer/technical-supplier/list-technical-suppliers/actions';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import { useToast } from 'shared/hooks/use-toast';
import useInfiniteScrollLegacy from 'shared/infinite-components/infinite-scroll-legacy/infinite-scroll-legacy';
import './technical-supplier.scss';

const I18N_PREFIX = 'pages.scd.technical-supplier';

export type TechnicalSupplierMoreOptionsType = 'defaultPaymentInstitution';

const ScdTechnicalSupplierPage = () => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const history = useHistory();
    const [page, setPage] = useState<number>(0);
    const dispatch = useRootDispatch();
    const [modalOptionType, setModalOptionType] = useState<TechnicalSupplierMoreOptionsType | undefined>(undefined);
    const [sortedProperty, setSortedProperty] = useState<string>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [selectedTechnicalSupplier, setSelectedTechnicalSupplier] = useState<ScdTechnicalSupplier | undefined>(undefined);

    const { toastSuccess } = useToast();
    const { status: defaultStatus } = useDefaultTechnicalSuppliersState();

    const sortPageable = useCallback((): Pageable => {
        return {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: `${sortedProperty},${sortOrder}`,
        };
    }, [sortedProperty, sortOrder, page]);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };
    const {
        InfiniteScrollButton,
        items,
        resetInfiniteScrollListing,
        isLoading,
        status: listStatus,
        handleRefresh,
    } = useInfiniteScrollLegacy<ScdTechnicalSupplier>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listTechnicalSuppliers.technicalSuppliers,
        statusSelector: (state: IRootState) => state.listTechnicalSuppliers.status,
        action: listTechnicalSuppliersRequest,
        filterPageable: sortPageable,
        setPage,
    });

    useEffect(() => {
        if (defaultStatus === HttpRequestStatus.SUCCESS) {
            toastSuccess(t(`${I18N_PREFIX}.more-options.defaultPaymentInstitution.toast-success`));
            dispatch(defaultTechnicalSupplierResetState());
            handleRefresh();
        }
    }, [dispatch, t, toastSuccess, sortPageable, defaultStatus]);

    const handleSort = (property: keyof ScdTechnicalSupplier, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const handleMoreOptionsModalAction = {
        defaultPaymentInstitution: () => {
            if (selectedTechnicalSupplier?.id !== undefined) {
                dispatch(defaultTechnicalSupplierRequest(selectedTechnicalSupplier.id));
            }
            setModalOptionType(undefined);
        },
    } as Record<TechnicalSupplierMoreOptionsType, () => void>;

    const hasError = listStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.program.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleRefresh} />
            </div>
        );
    }
    return (
        <main className="scd-technical-supplier">
            <section className="scd-technical-supplier__container">
                <header className="scd-technical-supplier__header">
                    <h2 className="scd-technical-supplier__header--title">
                        {t(`${I18N_PREFIX}.title`)}
                        <RefreshButton onClick={handleRefresh} />
                    </h2>
                    <LinkButton label={t(`${I18N_PREFIX}.create.title`)} onClick={() => history.push(`${url}/new`)} />
                </header>
                <div className="scd-technical-supplier__table">
                    <table className="page-container--table">
                        <thead>
                            <ScdTechnicalSupplierHeader
                                technicalSuppliers={items}
                                handleSort={handleSort}
                                sortedProperty={sortedProperty}
                                sortOrder={sortOrder}
                            />
                        </thead>
                        <tbody>
                            <TechnicalSuppliersList
                                technicalSuppliers={items}
                                isLoading={isLoading}
                                selectedTechnicalSupplier={selectedTechnicalSupplier}
                                setModalOptionType={setModalOptionType}
                                setSelectedTechnicalSupplier={setSelectedTechnicalSupplier}
                            />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
            </section>
            {modalOptionType && (
                <ModalMessageLegacy
                    title={t(`${I18N_PREFIX}.modal.more-options.${modalOptionType}.title`)}
                    message={t(`${I18N_PREFIX}.modal.more-options.${modalOptionType}.message`, {
                        name: selectedTechnicalSupplier?.name,
                    })}
                    onAction={handleMoreOptionsModalAction[modalOptionType]}
                    onClose={() => {
                        setModalOptionType(undefined);
                        setSelectedTechnicalSupplier(undefined);
                    }}
                />
            )}
        </main>
    );
};

export default ScdTechnicalSupplierPage;
