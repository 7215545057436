import { BankingCorrespondentToGetOriginator } from 'model/banking-correspondent';
import { ScdCompany } from 'model/company';
import { FinancialInstitutionToGetOriginator } from 'model/financial-institution';
import { ScdOrganizationSimple } from 'model/organization';
import { OriginatorPaymentInstitution, OriginatorPaymentInstitutionDetail } from './originator-payment-institution';

export interface ScdOriginator {
    id?: string;
    name?: string;
    identification?: string;
    company?: ScdCompany;
    organization?: ScdOrganizationSimple;
    paymentInstitutions: ReadonlyArray<OriginatorPaymentInstitution>;
    financialInstitutionId?: number;
    financialInstitutionName?: string;
    bankingCorrespondentId?: number;
    bankingCorrespondentName?: string;
}

export const defaultScdOriginator: ScdOriginator = {
    id: undefined,
    name: undefined,
    identification: undefined,
    company: undefined,
    organization: undefined,
    paymentInstitutions: [],
    financialInstitutionId: undefined,
    financialInstitutionName: undefined,
    bankingCorrespondentId: undefined,
    bankingCorrespondentName: undefined
};

export interface ScdOriginatorFilterRequest {
    name?: string;
    identification?: string;
    companyId?: number;
    companyName?: string;
    paymentInstitutionId?: number;
    paymentInstitutionName?: string;
}

export const defaultOriginatorFilterRequest: ScdOriginatorFilterRequest = {
    name: undefined,
    identification: undefined,
    companyId: undefined,
    paymentInstitutionId: undefined
};

export interface ScdOriginatorList {
    id?: string;
    name?: string;
    identification?: string;
    company?: ScdCompany;
    organization?: ScdOrganizationSimple;
    paymentInstitution?: OriginatorPaymentInstitution;
}

export interface ScdOriginatorDetail {
    id?: string;
    name?: string;
    identification?: string;
    bankingCorrespondent?: BankingCorrespondentToGetOriginator;
    company?: ScdCompany;
    financialInstitution?: FinancialInstitutionToGetOriginator;
    organization?: ScdOrganizationSimple;
    paymentInstitutions: ReadonlyArray<OriginatorPaymentInstitutionDetail>;
}

export interface ScdOriginatorSimple {
    id: string;
    name: string;
    identification: string;
}

export interface ScdOriginatorSearch {
    id?: string;
    name?: string;
    organization?: string;
}

export interface ScdOriginatorCompany {
    id: string;
    name: string;
    identification: string;
    company: ScdCompany;
}

export interface OriginatorToGetTransaction {
    id: number;
    name: string;
}
