import RefreshIcon from '@material-ui/icons/Refresh';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import './refresh-button.scss';

export interface RefreshButtonProps {
    onClick: () => void;
}

export const RefreshButton = (props: RefreshButtonProps): JSX.Element => {
    const { onClick } = props;
    const [isSpinning, setIsSpinning] = useState<boolean>(false);

    const runClickLogic = useCallback(() => {
        if (!isSpinning) {
            setIsSpinning(true);
            onClick();
            setTimeout(() => setIsSpinning(false), 1000);
        }
    }, [onClick, isSpinning]);

    const handleDebounce = useMemo(() => debounce(runClickLogic, 500), [runClickLogic]);

    useEffect(() => {
        return () => {
            handleDebounce.cancel();
        };
    }, [handleDebounce]);

    const activeSpin = isSpinning ? '--spin' : '';

    return (
        <button className="refresh-button" onClick={handleDebounce}>
            <RefreshIcon className={`refresh-button--refresh-icon${activeSpin}`} />
        </button>
    );
};

export default RefreshButton;
