import { FC } from 'react';
import clsx from 'clsx';
import { CessionOperationBco } from 'model/bco/batch-output-cession-integration-webcred';
import { useTranslation } from 'react-i18next';
import { CessionOutputResumeBco } from 'model/bco/batch-output';
import BalanceLoading from 'shared/external/payment-accounts-strategy/balance/components/balance-loading/balance-loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-webcred.table';

export type CessionOperationSortableProperties = keyof Pick<CessionOperationBco, 'id' | 'ccbNumber'>;

interface CessionClosingFixedLineProps {
    cessionOutputResume: CessionOutputResumeBco;
    cessionOutputResumeStatus: HttpRequestStatus;
}

/**
 * Defines the height of the fixed line in the webcred output.
 *
 * Since the fixed list is infinite and does not support dynamic heights,
 * this value is used to compute the correct height for the fixed row,
 * preventing layout breakage by ensuring that the fixed row remains within the table.
 *
 * @see CessionIntegrationWebcredList
 */
export const FIXED_CLOSING_HEIGHT = 50;

const CessionClosingFixedLine: FC<CessionClosingFixedLineProps> = ({ cessionOutputResume, cessionOutputResumeStatus }) => {
    const { t } = useTranslation();

    const isLoading = cessionOutputResumeStatus === HttpRequestStatus.ON_GOING;

    return (
        <div className="cession-integration-webcred__closing-box">
            <div className="cession-integration-webcred__closing" style={{ height: `${FIXED_CLOSING_HEIGHT}px` }}>
                <div className="cession-integration-webcred__closing-cell">{t(`${I18N_PREFIX}.row.closing.title`)}</div>
                <div className={clsx('cession-integration-webcred__closing-cell')}>
                    {isLoading ? (
                        <BalanceLoading />
                    ) : (
                        <span className={clsx('cession-integration-webcred__closing-cell-status', cessionOutputResume.status)}>
                            {t(`${I18N_PREFIX}.row.closing.status.${cessionOutputResume.status}`)}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CessionClosingFixedLine;
