import LinkButton from 'components/buttons/link-button/link-button';
import ModalMessage from 'components/modals/modal-message/modal-message';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PaymentTransactionStatus } from 'model/enums/payment-transaction-status';
import { PaymentTransactionType } from 'model/enums/payment-transaction-type';
import { PaymentTransactionDetail } from 'model/payment-transaction';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useConfirmExternalTransactionState, useRootDispatch } from 'reducer/hooks';
import {
    confirmExternalTransactionRequest,
    confirmExternalTransactionResetState,
} from 'reducer/payment-transaction/confirm-external-transaction/actions';
import { useToast } from 'shared/hooks/use-toast';

const I18N_PREFIX = 'pages.scd.payment-transaction.detail';

const ExternalTransactionConfirmation: React.FC<{ paymentTransaction: PaymentTransactionDetail }> = ({ paymentTransaction }) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const history = useHistory();

    const { status: confirmTransactionStatus } = useConfirmExternalTransactionState();

    const { toastSuccess, toastError } = useToast();

    const [showConfirmModal, setShowConfirmModal] = React.useState(false);

    useEffect(() => {
        if (confirmTransactionStatus === HttpRequestStatus.SUCCESS) {
            toastSuccess(t(`${I18N_PREFIX}.toast.success-external-transaction`));
            history.push('/payment-transactions');
        }
        if (confirmTransactionStatus === HttpRequestStatus.ERROR) {
            toastError(t(`${I18N_PREFIX}.toast.error-external-transaction`));
        }
    }, [confirmTransactionStatus, toastSuccess, toastError, history, t]);

    useEffect(() => {
        return () => {
            dispatch(confirmExternalTransactionResetState());
        };
    }, [dispatch]);

    const isSentStatus = paymentTransaction?.status === PaymentTransactionStatus.WAITING;
    const isExternalType = paymentTransaction?.type === PaymentTransactionType.EXTERNAL_DISBURSEMENT;

    const showConfirmPaymentButton = isSentStatus && isExternalType;

    return (
        <>
            {showConfirmPaymentButton && (
                <div className="scd-payment-transaction__detail--button-confirm">
                    <LinkButton label={t(`${I18N_PREFIX}.button.confirm-payment`)} onClick={() => setShowConfirmModal(true)} />
                </div>
            )}
            {showConfirmModal && (
                <ModalMessage
                    title={t(`${I18N_PREFIX}.modal.confirm`)}
                    onCancel={() => setShowConfirmModal(false)}
                    onAction={() => {
                        if (paymentTransaction.id) {
                            setShowConfirmModal(false);
                            dispatch(confirmExternalTransactionRequest(paymentTransaction.id));
                        }
                    }}
                    onClose={() => setShowConfirmModal(false)}
                >
                    {t(`${I18N_PREFIX}.modal.description`)}
                </ModalMessage>
            )}
        </>
    );
};

export default ExternalTransactionConfirmation;
