import { AxiosResponse } from 'axios';
import {
    FinancialInstitution,
    FinancialInstitutionPageable,
    FinancialInstitutionSearch,
    FinancialInstitutionToCreateUpdateRequest,
    FinancialInstitutionToSimple
} from 'model/financial-institution';
import { SearchRequest } from 'model/reducers';
import api from 'services/api/api';
import { BASE_URL_ADMIN, Page } from 'services/page';

const financialInstitution = () => {
    const listFinancialInstitutions = (request?: FinancialInstitutionPageable): Promise<AxiosResponse<Page<FinancialInstitution>>> => {
        return api.get<Page<FinancialInstitution>>(`${BASE_URL_ADMIN}/financial-institutions?`, {
            params: request?.pageable,
        });
    };

    const detailsFinancialInstitution = (financialInstitutionId?: string): Promise<AxiosResponse<Page<FinancialInstitution>>> => {
        return api.get<Page<FinancialInstitution>>(`${BASE_URL_ADMIN}/financial-institutions/${financialInstitutionId}`);
    };

    const createFinancialInstitution = (
        request?: FinancialInstitutionToCreateUpdateRequest
    ): Promise<AxiosResponse<FinancialInstitutionToSimple>> => {
        return api.post<FinancialInstitutionToSimple>(`${BASE_URL_ADMIN}/financial-institutions`, request);
    };

    const updateFinancialInstitution = (
        request?: FinancialInstitutionToCreateUpdateRequest
    ): Promise<AxiosResponse<FinancialInstitutionToSimple>> => {
        return api.put<FinancialInstitutionToSimple>(`${BASE_URL_ADMIN}/financial-institutions/${request?.id}`, request);
    };

    const searchFinancialInstitutions = (request: SearchRequest): Promise<AxiosResponse<Page<FinancialInstitutionSearch>>> => {
        return api.get<Page<FinancialInstitutionSearch>>(`${BASE_URL_ADMIN}/financial-institutions/search?search=${encodeURIComponent(request.search)}`, {
            params: { ...request.pageable },
        });
    };

    return {
        listFinancialInstitutions,
        detailsFinancialInstitution,
        createFinancialInstitution,
        updateFinancialInstitution,
        searchFinancialInstitutions
    };
};

export default financialInstitution();
