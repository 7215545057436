import { Grid } from '@material-ui/core';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter from 'components/formatter/date/date-formatter';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import BatchClosingOperationsMoreOptionsMenuItems from 'entities/batch/batch-closing/components/batch-closing-menu-items-more-options/batch-closing-operations-more-options-menu-items/batch-closing-operations-more-options-menu-items';
import OperationReadOnlyTextField from 'entities/ccb-operation/ccb-operation-detail/components/operation-read-only-text-field/operation-read-only-text-field';
import { ScdBatchClosingToGetOperation } from 'model/batch-closing';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'pages.scd.batch.close-batch';

interface BatchOperationsCardRowProps {
    operations: ScdBatchClosingToGetOperation[];
    operation: ScdBatchClosingToGetOperation;
    index: number;
}

const BatchOperationsCardRow: FC<BatchOperationsCardRowProps> = ({ operations, operation, index }) => {
    const { t } = useTranslation();
    const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);

    return (
        <Grid item xs={12}>
            <div className="operation-details-horizontal">
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.ccb-number`)} value={operation.ccbNumber} />
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.loan-date`)} value={<DateFormatter date={operation.loanDate} />} />
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.loan-amount`)} value={<CurrencyFormatter value={operation.loanAmount} prefix />} />
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.disbursement-amount`)} value={<CurrencyFormatter value={operation.disbursementAmount} prefix />} />
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.debtor-identification`)}
                    value={<IdentificationFormatter value={operation.debtorIdentification} type={operation.debtorType} />}
                />
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.first-installment-due-date`)} value={<DateFormatter date={operation.firstInstallmentDueDate} />} />
                <MoreOptionsMenu anchorEl={anchorMenuEl} setAnchorEl={setAnchorMenuEl}>
                    <BatchClosingOperationsMoreOptionsMenuItems operationId={operation.id} />
                </MoreOptionsMenu>
            </div>
        </Grid>
    );
};
export default BatchOperationsCardRow;
