import WarningIcon from '@material-ui/icons/Warning';
import StandardButton from 'components/buttons/standard-button/standard-button';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { OperationCollateralPolicy } from 'model/enums/operation-collateral-policy';
import { ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { reprocessBatchRequest } from 'reducer/batch/reprocess-batch/actions';
import {
    useBatchClosingResumeOperationsState,
    useBatchClosingResumePaymentsState,
    useBatchDetailWithProgramState,
    useResumeCollateralsBatchState,
} from 'reducer/hooks';
import BalanceLoading from 'shared/external/payment-accounts-strategy/balance/components/balance-loading/balance-loading';
import { isDueDateExpired, isDueDateToday } from 'shared/util/date-utils';
import './use-footer-components.scss';

const I18N_PREFIX = 'pages.scd.batch.close-batch';

enum BatchClosingSteps {
    UNDEFINED = 'UNDEFINED',
    LOADING_DETAILS = 'LOADING_DETAILS',
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    CONCLUDED = 'CONCLUDED',
    PROCESSING = 'PROCESSING',
    ERROR = 'ERROR',
}

export const useFooterComponents = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { batchWithProgram } = useBatchDetailWithProgramState();
    const { resumeCollaterals, status: resumeCollateralsStatus } = useResumeCollateralsBatchState();
    const { resumePayments, status: resumePaymentsStatus } = useBatchClosingResumePaymentsState();
    const { resumeOperations } = useBatchClosingResumeOperationsState();

    const [isCloseModalOpen, setIsCloseModalOpen] = useState<boolean>(false);
    const [footerType, setFooterType] = useState<BatchClosingSteps>(BatchClosingSteps.UNDEFINED);

    const statusDetail = (resumePaymentsStatus || resumeCollateralsStatus) === HttpRequestStatus.ON_GOING;
    const sucessfulCollaterals = resumeCollateralsStatus === HttpRequestStatus.SUCCESS;

    const batchStatus = batchWithProgram?.status;
    const isOpenBatch = batchStatus === 'OPEN';
    const isErrorBatch = batchStatus === 'ERROR';
    const isClosedBatch = batchStatus === 'CLOSED';
    const isConcludedBatch = batchStatus === 'CONCLUDED';
    const isProcessingBatch = batchStatus === 'PROCESSING';

    const programPolicy = batchWithProgram?.program?.policy;
    const closeModalOpen = isCloseModalOpen && (isOpenBatch || isErrorBatch);
    const numberOfCollaterals = resumeCollaterals?.numberOfCollaterals ?? 0;
    const numberOfOperations = resumeCollaterals?.numberOfOperations ?? 0;
    const numberOfOperationsWithPaymentErrors = (resumePayments && resumePayments?.numberOfOperationsWithPaymentError) ?? 0;
    const collateralOperationsMismatch = numberOfCollaterals !== resumeCollaterals?.numberOfOperations;
    const numberOfPendingCollateral = numberOfOperations - numberOfCollaterals;

    const isActiveCollateralValidationEnabled = programPolicy?.operationCollateralPolicy == OperationCollateralPolicy.ACTIVE_VALIDATION;

    const isOpenOrErrorBatch = isOpenBatch || isErrorBatch;

    const hasInconsistencyCollateral = isActiveCollateralValidationEnabled && collateralOperationsMismatch;
    const hasInconsistencyTransfers = isOpenOrErrorBatch && numberOfOperationsWithPaymentErrors > 0;

    const currentDate = resumeOperations?.currentDate;
    const firstDueDate = resumeOperations?.firstDueDate;
    const hasDateExpired = isDueDateExpired(currentDate, firstDueDate);
    const hasDueDateToday = isDueDateToday(currentDate, firstDueDate);

    const hasDateInconsistency = isOpenOrErrorBatch && hasDateExpired;
    const hasInconsistency = (sucessfulCollaterals && hasInconsistencyCollateral) || hasInconsistencyTransfers || hasDateInconsistency;

    const renderInconsistencyAlert = (): ReactNode[] => {
        const showAlerts: ReactNode[] = [];

        if (hasInconsistencyCollateral) {
            showAlerts.push(
                <div key="collateral" className="batch-closing-footer--render-quantities">
                    <div className="batch-closing-footer--render-quantities--icon" />
                    <div className="batch-closing-footer--render-quantities--text">
                        <Trans i18nKey={`${I18N_PREFIX}.collaterals-pending.total`} count={numberOfPendingCollateral} />
                    </div>
                </div>
            );
        }

        if (hasInconsistencyTransfers) {
            showAlerts.push(
                <div key="transfers" className="batch-closing-footer--render-quantities">
                    <div className="batch-closing-footer--render-quantities--icon" />
                    <div className="batch-closing-footer--render-quantities--text">
                        <Trans i18nKey={`${I18N_PREFIX}.payments-error.total`} count={numberOfOperationsWithPaymentErrors} />
                    </div>
                </div>
            );
        }

        if (hasDateInconsistency) {
            showAlerts.push(
                <div key="errorAlert" className="batch-closing-footer--render-quantities">
                    <div className="batch-closing-footer--render-quantities--icon" />
                    <div className="batch-closing-footer--render-quantities--text">
                        <Trans i18nKey={`${I18N_PREFIX}.errors.contains`} />
                    </div>
                </div>
            );
        }

        if (hasDueDateToday) {
            showAlerts.push(
                <div key="dueToday" className="batch-closing-footer--render-quantities">
                    <div className="batch-closing-footer--render-quantities--icon-warning">
                        <WarningIcon />
                    </div>
                    <div className="batch-closing-footer--render-quantities--text-warning">
                        <Trans i18nKey={`${I18N_PREFIX}.due-date.today`} />
                    </div>
                </div>
            );
        }

        return showAlerts;
    };

    const determineFooterType = () => {
        if (statusDetail) return BatchClosingSteps.LOADING_DETAILS;
        if (isOpenBatch) return BatchClosingSteps.OPEN;
        if (isErrorBatch) return BatchClosingSteps.ERROR;
        if (isClosedBatch) return BatchClosingSteps.CLOSED;
        if (isConcludedBatch) return BatchClosingSteps.CONCLUDED;
        if (isProcessingBatch) return BatchClosingSteps.PROCESSING;
        return BatchClosingSteps.UNDEFINED;
    };

    useEffect(() => {
        setFooterType(determineFooterType());
    }, [statusDetail, isOpenBatch, isErrorBatch, isClosedBatch, isConcludedBatch, isProcessingBatch]);

    const generalFooterContent = (children: ReactNode): ReactNode => (
        <div className="batch-closing-footer">
            <div className="batch-closing-footer__content">
                {(hasInconsistency || hasDueDateToday) && (
                    <div className="batch-closing-footer__alert-box">{renderInconsistencyAlert()}</div>
                )}
                <div className="batch-closing-footer__close-button">{children}</div>
            </div>
        </div>
    );

    const handleReprocessBatch = () => {
        if (batchWithProgram?.id) {
            dispatch(reprocessBatchRequest(batchWithProgram.id));
        }
    };

    const BatchClosingContent: ReactNode = generalFooterContent(
        <div className={hasInconsistency ? 'batch-closing-footer__button-container--secondary' : ''}>
            <StandardButton
                label={t(`${I18N_PREFIX}.footer.batch-closing.closing`)}
                onClick={() => setIsCloseModalOpen(true)}
                disabled={!isOpenBatch}
            />
        </div>
    );

    const ReprocessBatchContent: ReactNode = generalFooterContent(
        <StandardButton
            label={t(`${I18N_PREFIX}.footer.batch-reprocessing.reprocess`)}
            onClick={handleReprocessBatch}
            disabled={!isErrorBatch}
        />
    );

    const LoadingDetailsContent: ReactNode = generalFooterContent(
        <div className="batch-closing-footer">
            <div className="batch-closing-footer__content">
                <BalanceLoading />
            </div>
        </div>
    );

    const EmptyFooter: ReactNode = <></>;

    const BaseFooter = (message: ReactNode) => (
        <div className="batch-closing-footer">
            <div className="batch-closing-footer__content">
                {hasInconsistency && <div className="batch-closing-footer__alert-box">{renderInconsistencyAlert()}</div>}
                <div className="batch-closing-footer__message-box">{message}</div>
            </div>
        </div>
    );

    const ClosedContent: ReactNode = BaseFooter(<div>{t(`${I18N_PREFIX}.footer.closed-batch`)}</div>);

    const ConcludedContent: ReactNode = BaseFooter(<div>{t(`${I18N_PREFIX}.footer.closed-batch`)}</div>);

    const ProcessingContent: ReactNode = BaseFooter(<div>{t(`${I18N_PREFIX}.footer.processing-batch`)}</div>);

    const renderFooterContent = {
        UNDEFINED: EmptyFooter,
        OPEN: BatchClosingContent,
        CLOSED: ClosedContent,
        CONCLUDED: ConcludedContent,
        PROCESSING: ProcessingContent,
        ERROR: ReprocessBatchContent,
        LOADING_DETAILS: LoadingDetailsContent,
    } as Record<BatchClosingSteps | 'ERROR', ReactNode>;

    const currentFooter = renderFooterContent[footerType];

    return { currentFooter, setIsCloseModalOpen, closeModalOpen, hasInconsistency };
};
