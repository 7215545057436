import { PaymentTransactionToSimple } from 'model/payment-transaction';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ConfirmExternalTransactionTypes {
    CONFIRM_EXTERNAL_TRANSACTION_REQUEST = '@@confirm-external-transaction/CONFIRM_EXTERNAL_TRANSACTION_REQUEST',
    CONFIRM_EXTERNAL_TRANSACTION_SUCCESS = '@@confirm-external-transaction/CONFIRM_EXTERNAL_TRANSACTION_SUCCESS',
    CONFIRM_EXTERNAL_TRANSACTION_ERROR = '@@confirm-external-transaction/CONFIRM_EXTERNAL_TRANSACTION_ERROR',
    CONFIRM_EXTERNAL_TRANSACTION_RESET = '@@confirm-external-transaction/CONFIRM_EXTERNAL_TRANSACTION_RESET',
}

export interface ConfirmExternalTransactionState extends BaseStoreState {
    confirmExternalTransaction?: PaymentTransactionToSimple
}
