import { ExportRequestType } from 'model/bco/enums/batch-output-cession-integration-gtsec';
import { useParams } from 'react-router-dom';
import { cessionOperationStatusBcoRequest } from 'reducer-bco/batch-output/cession-operation-status/action';
import { exportRequestSummaryResultsBcoRequest } from 'reducer-bco/batch-output/export-request-summary-results/action';
import { useCessionOperationStatusBco } from 'reducer-bco/hooks-bco';
import { useRootDispatch } from 'reducer/hooks';
import { cessionOutputResumeBcoRequest } from 'reducer-bco/batch-output/cession-output-resume/action';

export const useRefreshCessionOperationStatus = () => {
    const { batchId, outputId } = useParams<{ batchId: string; outputId: string }>();
    const { cessionOperationStatus } = useCessionOperationStatusBco();
    const dispatch = useRootDispatch();

    return () => {
        const _request = {
            batchId: Number(batchId),
            outputId: Number(outputId),
        };

        dispatch(
            exportRequestSummaryResultsBcoRequest({
                ..._request,
                type: ExportRequestType.CESSION_OPERATION,
            })
        );

        dispatch(cessionOutputResumeBcoRequest(_request));

        if (!cessionOperationStatus) return;
        Object.keys(cessionOperationStatus ?? {}).forEach(key => dispatch(cessionOperationStatusBcoRequest({ filter: { batchId, outputId, cessionOperationId: key } })));
    };
};
