import clsx from 'clsx';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import BatchDetailHeaderContent from 'entities/batch/components/batch-detail-header-content/batch-detail-header-content';
import BatchDetailHeader from 'entities/batch/components/batch-detail-header/batch-detail-header';
import { BatchDetailWithProgram } from 'model/batch';
import { BatchOutputDetailsBco } from 'model/bco/batch-output';
import { useTranslation } from 'react-i18next';

import { FC } from 'react';

interface ApexHeaderProps {
    batchOutputDetails: BatchOutputDetailsBco;
    batchWithProgram: BatchDetailWithProgram;
    handleRefresh: () => void;
}

const I18N_PREFIX = 'pages.scd.batch-outputs.general-header';

const DisbursementConfirmationIntegrationApexHeader: FC<ApexHeaderProps> = ({ batchOutputDetails, batchWithProgram, handleRefresh }) => {
    const { t } = useTranslation();

    return (
        <>
            <h1 className="batch-outputs-detail__container-title">
                {batchOutputDetails.policy.name}
                <RefreshButton onClick={handleRefresh} />
            </h1>
            <BatchDetailHeader>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.batch`)} isTooltip>
                    {batchWithProgram?.code ?? '-'}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.originator`)} isTooltip>
                    {batchWithProgram?.originator?.name ?? '-'}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.output-method.options.${batchOutputDetails.outputMethod ?? '-'}`)} isTooltip>
                    {batchOutputDetails?.platform?.name ?? '-'}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.model`)} isTooltip>
                    {t(`${I18N_PREFIX}.output-model.options.${batchOutputDetails.outputModel ?? '-'}`)}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.financialInstitution`)} isTooltip>
                    {batchOutputDetails?.financialInstitution?.name ?? '-'}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.status`)} isTooltip>
                    <span className={clsx('batch-detail__container-status', batchWithProgram?.status)}>{t(`${I18N_PREFIX}.status.options.${batchWithProgram?.status}`).toUpperCase()}</span>
                </BatchDetailHeaderContent>
            </BatchDetailHeader>
        </>
    );
};

export default DisbursementConfirmationIntegrationApexHeader;
