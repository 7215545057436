import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import BatchDetailHeaderContent from 'entities/batch/components/batch-detail-header-content/batch-detail-header-content';
import BatchDetailHeader from 'entities/batch/components/batch-detail-header/batch-detail-header';
import { BatchDetailWithProgram } from 'model/batch';
import { BatchOutputDetailsBco } from 'model/bco/batch-output';
import { useTranslation } from 'react-i18next';

import { CessionToResumeBco } from 'model/bco/batch-output-cession-integration-gtsec';
import { FC } from 'react';
import BalanceLoading from 'shared/external/payment-accounts-strategy/balance/components/balance-loading/balance-loading';
import './cession-integration-matera-header.scss';

interface MateraHeaderProps {
    batchOutputDetails: BatchOutputDetailsBco;
    batchWithProgram: BatchDetailWithProgram;
    cessionToResume?: CessionToResumeBco;
    isLoadingCessionToResume: boolean;
    handleRefresh: () => void;
}

const I18N_PREFIX = 'pages.scd.batch-outputs.general-header';

const CessionIntegrationMateraHeader: FC<MateraHeaderProps> = ({
    batchOutputDetails,
    batchWithProgram,
    handleRefresh,
    cessionToResume,
    isLoadingCessionToResume,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <h1 className="batch-outputs-detail__container-title">
                {batchOutputDetails.policy.name}
                <RefreshButton onClick={handleRefresh} />
            </h1>
            <BatchDetailHeader>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.batch`)} isTooltip>
                    {batchWithProgram?.code ?? '-'}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.originator`)} isTooltip>
                    {batchWithProgram?.originator?.name ?? '-'}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.operations`)}>
                    {isLoadingCessionToResume ? <BalanceLoading /> : cessionToResume?.numberOfOperations ?? '-'}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.installments`)}>
                    {isLoadingCessionToResume ? <BalanceLoading /> : cessionToResume?.numberOfInstallments ?? '-'}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent
                    title={t(`${I18N_PREFIX}.output-method.options.${batchOutputDetails.outputMethod ?? '-'}`)}
                    isTooltip
                >
                    {batchOutputDetails?.platform?.name ?? '-'}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.model`)} isTooltip>
                    {t(`${I18N_PREFIX}.output-model.options.${batchOutputDetails.outputModel ?? '-'}`)}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.financialInstitution`)} isTooltip>
                    {batchOutputDetails?.financialInstitution?.name ?? '-'}
                </BatchDetailHeaderContent>
            </BatchDetailHeader>
        </>
    );
};

export default CessionIntegrationMateraHeader;
