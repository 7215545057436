import { DisbursementConfirmationToResumeBco } from 'model/bco/batch-output-disbursement-confirmation';
import { BaseStoreState } from 'reducer/base-store-state';

export enum DisbursementConfirmationToResumeBcoTypes {
    DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_REQUEST = '@@disbursement-confirmation-to-resume-bco/DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_REQUEST',
    DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_SUCCESS = '@@disbursement-confirmation-to-resume-bco/DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_SUCCESS',
    DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_ERROR = '@@disbursement-confirmation-to-resume-bco/DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_ERROR',
    DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_RESET = '@@disbursement-confirmation-to-resume-bco/DISBURSEMENT_CONFIRMATION_TO_RESUME_BCO_RESET',
}

export interface DisbursementConfirmationToResumeBcoState extends BaseStoreState {
    readonly confirmationToResume?: DisbursementConfirmationToResumeBco;
}
